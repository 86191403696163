@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-transition: none !important;
  transition: none !important;
}

body {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 1em;
  -webkit-transition: background 1s cubic-bezier(0.075, 0.82, 0.165, 1) !important;
  transition: background 1s cubic-bezier(0.075, 0.82, 0.165, 1) !important;
}

table {
  width: 100% !important;
}

a {
  text-decoration: none !important;
}

/*----Name space prefix------------------*/
/*----Variable------------------*/
/*----Function------------------*/
/*----width------------------*/
.w-1 {
  width: 1%;
}

.w-2 {
  width: 2%;
}

.w-3 {
  width: 3%;
}

.w-4 {
  width: 4%;
}

.w-5 {
  width: 5%;
}

.w-6 {
  width: 6%;
}

.w-7 {
  width: 7%;
}

.w-8 {
  width: 8%;
}

.w-9 {
  width: 9%;
}

.w-10 {
  width: 10%;
}

.w-11 {
  width: 11%;
}

.w-12 {
  width: 12%;
}

.w-13 {
  width: 13%;
}

.w-14 {
  width: 14%;
}

.w-15 {
  width: 15%;
}

.w-16 {
  width: 16%;
}

.w-17 {
  width: 17%;
}

.w-18 {
  width: 18%;
}

.w-19 {
  width: 19%;
}

.w-20 {
  width: 20%;
}

.w-21 {
  width: 21%;
}

.w-22 {
  width: 22%;
}

.w-23 {
  width: 23%;
}

.w-24 {
  width: 24%;
}

.w-25 {
  width: 25%;
}

.w-26 {
  width: 26%;
}

.w-27 {
  width: 27%;
}

.w-28 {
  width: 28%;
}

.w-29 {
  width: 29%;
}

.w-30 {
  width: 30%;
}

.w-31 {
  width: 31%;
}

.w-32 {
  width: 32%;
}

.w-33 {
  width: 33%;
}

.w-34 {
  width: 34%;
}

.w-35 {
  width: 35%;
}

.w-36 {
  width: 36%;
}

.w-37 {
  width: 37%;
}

.w-38 {
  width: 38%;
}

.w-39 {
  width: 39%;
}

.w-40 {
  width: 40%;
}

.w-41 {
  width: 41%;
}

.w-42 {
  width: 42%;
}

.w-43 {
  width: 43%;
}

.w-44 {
  width: 44%;
}

.w-45 {
  width: 45%;
}

.w-46 {
  width: 46%;
}

.w-47 {
  width: 47%;
}

.w-48 {
  width: 48%;
}

.w-49 {
  width: 49%;
}

.w-50 {
  width: 50%;
}

.w-51 {
  width: 51%;
}

.w-52 {
  width: 52%;
}

.w-53 {
  width: 53%;
}

.w-54 {
  width: 54%;
}

.w-55 {
  width: 55%;
}

.w-56 {
  width: 56%;
}

.w-57 {
  width: 57%;
}

.w-58 {
  width: 58%;
}

.w-59 {
  width: 59%;
}

.w-60 {
  width: 60%;
}

.w-61 {
  width: 61%;
}

.w-62 {
  width: 62%;
}

.w-63 {
  width: 63%;
}

.w-64 {
  width: 64%;
}

.w-65 {
  width: 65%;
}

.w-66 {
  width: 66%;
}

.w-67 {
  width: 67%;
}

.w-68 {
  width: 68%;
}

.w-69 {
  width: 69%;
}

.w-70 {
  width: 70%;
}

.w-71 {
  width: 71%;
}

.w-72 {
  width: 72%;
}

.w-73 {
  width: 73%;
}

.w-74 {
  width: 74%;
}

.w-75 {
  width: 75%;
}

.w-76 {
  width: 76%;
}

.w-77 {
  width: 77%;
}

.w-78 {
  width: 78%;
}

.w-79 {
  width: 79%;
}

.w-80 {
  width: 80%;
}

.w-81 {
  width: 81%;
}

.w-82 {
  width: 82%;
}

.w-83 {
  width: 83%;
}

.w-84 {
  width: 84%;
}

.w-85 {
  width: 85%;
}

.w-86 {
  width: 86%;
}

.w-87 {
  width: 87%;
}

.w-88 {
  width: 88%;
}

.w-89 {
  width: 89%;
}

.w-90 {
  width: 90%;
}

.w-91 {
  width: 91%;
}

.w-92 {
  width: 92%;
}

.w-93 {
  width: 93%;
}

.w-94 {
  width: 94%;
}

.w-95 {
  width: 95%;
}

.w-96 {
  width: 96%;
}

.w-97 {
  width: 97%;
}

.w-98 {
  width: 98%;
}

.w-99 {
  width: 99%;
}

.w-100 {
  width: 100%;
}

/*----Font-weight------------------*/
.fw-10 {
  font-weight: 10;
}

.fw-11 {
  font-weight: 11;
}

.fw-12 {
  font-weight: 12;
}

.fw-13 {
  font-weight: 13;
}

.fw-14 {
  font-weight: 14;
}

.fw-15 {
  font-weight: 15;
}

.fw-16 {
  font-weight: 16;
}

.fw-17 {
  font-weight: 17;
}

.fw-18 {
  font-weight: 18;
}

.fw-19 {
  font-weight: 19;
}

.fw-20 {
  font-weight: 20;
}

.fw-21 {
  font-weight: 21;
}

.fw-22 {
  font-weight: 22;
}

.fw-23 {
  font-weight: 23;
}

.fw-24 {
  font-weight: 24;
}

.fw-25 {
  font-weight: 25;
}

.fw-26 {
  font-weight: 26;
}

.fw-27 {
  font-weight: 27;
}

.fw-28 {
  font-weight: 28;
}

.fw-29 {
  font-weight: 29;
}

.fw-30 {
  font-weight: 30;
}

.fw-31 {
  font-weight: 31;
}

.fw-32 {
  font-weight: 32;
}

.fw-33 {
  font-weight: 33;
}

.fw-34 {
  font-weight: 34;
}

.fw-35 {
  font-weight: 35;
}

.fw-36 {
  font-weight: 36;
}

.fw-37 {
  font-weight: 37;
}

.fw-38 {
  font-weight: 38;
}

.fw-39 {
  font-weight: 39;
}

.fw-40 {
  font-weight: 40;
}

.fw-41 {
  font-weight: 41;
}

.fw-42 {
  font-weight: 42;
}

.fw-43 {
  font-weight: 43;
}

.fw-44 {
  font-weight: 44;
}

.fw-45 {
  font-weight: 45;
}

.fw-46 {
  font-weight: 46;
}

.fw-47 {
  font-weight: 47;
}

.fw-48 {
  font-weight: 48;
}

.fw-49 {
  font-weight: 49;
}

.fw-50 {
  font-weight: 50;
}

.fw-51 {
  font-weight: 51;
}

.fw-52 {
  font-weight: 52;
}

.fw-53 {
  font-weight: 53;
}

.fw-54 {
  font-weight: 54;
}

.fw-55 {
  font-weight: 55;
}

.fw-56 {
  font-weight: 56;
}

.fw-57 {
  font-weight: 57;
}

.fw-58 {
  font-weight: 58;
}

.fw-59 {
  font-weight: 59;
}

.fw-60 {
  font-weight: 60;
}

.fw-61 {
  font-weight: 61;
}

.fw-62 {
  font-weight: 62;
}

.fw-63 {
  font-weight: 63;
}

.fw-64 {
  font-weight: 64;
}

.fw-65 {
  font-weight: 65;
}

.fw-66 {
  font-weight: 66;
}

.fw-67 {
  font-weight: 67;
}

.fw-68 {
  font-weight: 68;
}

.fw-69 {
  font-weight: 69;
}

.fw-70 {
  font-weight: 70;
}

.fw-71 {
  font-weight: 71;
}

.fw-72 {
  font-weight: 72;
}

.fw-73 {
  font-weight: 73;
}

.fw-74 {
  font-weight: 74;
}

.fw-75 {
  font-weight: 75;
}

.fw-76 {
  font-weight: 76;
}

.fw-77 {
  font-weight: 77;
}

.fw-78 {
  font-weight: 78;
}

.fw-79 {
  font-weight: 79;
}

.fw-80 {
  font-weight: 80;
}

.fw-81 {
  font-weight: 81;
}

.fw-82 {
  font-weight: 82;
}

.fw-83 {
  font-weight: 83;
}

.fw-84 {
  font-weight: 84;
}

.fw-85 {
  font-weight: 85;
}

.fw-86 {
  font-weight: 86;
}

.fw-87 {
  font-weight: 87;
}

.fw-88 {
  font-weight: 88;
}

.fw-89 {
  font-weight: 89;
}

.fw-90 {
  font-weight: 90;
}

.fw-91 {
  font-weight: 91;
}

.fw-92 {
  font-weight: 92;
}

.fw-93 {
  font-weight: 93;
}

.fw-94 {
  font-weight: 94;
}

.fw-95 {
  font-weight: 95;
}

.fw-96 {
  font-weight: 96;
}

.fw-97 {
  font-weight: 97;
}

.fw-98 {
  font-weight: 98;
}

.fw-99 {
  font-weight: 99;
}

.fw-100 {
  font-weight: 100;
}

.fw-101 {
  font-weight: 101;
}

.fw-102 {
  font-weight: 102;
}

.fw-103 {
  font-weight: 103;
}

.fw-104 {
  font-weight: 104;
}

.fw-105 {
  font-weight: 105;
}

.fw-106 {
  font-weight: 106;
}

.fw-107 {
  font-weight: 107;
}

.fw-108 {
  font-weight: 108;
}

.fw-109 {
  font-weight: 109;
}

.fw-110 {
  font-weight: 110;
}

.fw-111 {
  font-weight: 111;
}

.fw-112 {
  font-weight: 112;
}

.fw-113 {
  font-weight: 113;
}

.fw-114 {
  font-weight: 114;
}

.fw-115 {
  font-weight: 115;
}

.fw-116 {
  font-weight: 116;
}

.fw-117 {
  font-weight: 117;
}

.fw-118 {
  font-weight: 118;
}

.fw-119 {
  font-weight: 119;
}

.fw-120 {
  font-weight: 120;
}

.fw-121 {
  font-weight: 121;
}

.fw-122 {
  font-weight: 122;
}

.fw-123 {
  font-weight: 123;
}

.fw-124 {
  font-weight: 124;
}

.fw-125 {
  font-weight: 125;
}

.fw-126 {
  font-weight: 126;
}

.fw-127 {
  font-weight: 127;
}

.fw-128 {
  font-weight: 128;
}

.fw-129 {
  font-weight: 129;
}

.fw-130 {
  font-weight: 130;
}

.fw-131 {
  font-weight: 131;
}

.fw-132 {
  font-weight: 132;
}

.fw-133 {
  font-weight: 133;
}

.fw-134 {
  font-weight: 134;
}

.fw-135 {
  font-weight: 135;
}

.fw-136 {
  font-weight: 136;
}

.fw-137 {
  font-weight: 137;
}

.fw-138 {
  font-weight: 138;
}

.fw-139 {
  font-weight: 139;
}

.fw-140 {
  font-weight: 140;
}

.fw-141 {
  font-weight: 141;
}

.fw-142 {
  font-weight: 142;
}

.fw-143 {
  font-weight: 143;
}

.fw-144 {
  font-weight: 144;
}

.fw-145 {
  font-weight: 145;
}

.fw-146 {
  font-weight: 146;
}

.fw-147 {
  font-weight: 147;
}

.fw-148 {
  font-weight: 148;
}

.fw-149 {
  font-weight: 149;
}

.fw-150 {
  font-weight: 150;
}

.fw-151 {
  font-weight: 151;
}

.fw-152 {
  font-weight: 152;
}

.fw-153 {
  font-weight: 153;
}

.fw-154 {
  font-weight: 154;
}

.fw-155 {
  font-weight: 155;
}

.fw-156 {
  font-weight: 156;
}

.fw-157 {
  font-weight: 157;
}

.fw-158 {
  font-weight: 158;
}

.fw-159 {
  font-weight: 159;
}

.fw-160 {
  font-weight: 160;
}

.fw-161 {
  font-weight: 161;
}

.fw-162 {
  font-weight: 162;
}

.fw-163 {
  font-weight: 163;
}

.fw-164 {
  font-weight: 164;
}

.fw-165 {
  font-weight: 165;
}

.fw-166 {
  font-weight: 166;
}

.fw-167 {
  font-weight: 167;
}

.fw-168 {
  font-weight: 168;
}

.fw-169 {
  font-weight: 169;
}

.fw-170 {
  font-weight: 170;
}

.fw-171 {
  font-weight: 171;
}

.fw-172 {
  font-weight: 172;
}

.fw-173 {
  font-weight: 173;
}

.fw-174 {
  font-weight: 174;
}

.fw-175 {
  font-weight: 175;
}

.fw-176 {
  font-weight: 176;
}

.fw-177 {
  font-weight: 177;
}

.fw-178 {
  font-weight: 178;
}

.fw-179 {
  font-weight: 179;
}

.fw-180 {
  font-weight: 180;
}

.fw-181 {
  font-weight: 181;
}

.fw-182 {
  font-weight: 182;
}

.fw-183 {
  font-weight: 183;
}

.fw-184 {
  font-weight: 184;
}

.fw-185 {
  font-weight: 185;
}

.fw-186 {
  font-weight: 186;
}

.fw-187 {
  font-weight: 187;
}

.fw-188 {
  font-weight: 188;
}

.fw-189 {
  font-weight: 189;
}

.fw-190 {
  font-weight: 190;
}

.fw-191 {
  font-weight: 191;
}

.fw-192 {
  font-weight: 192;
}

.fw-193 {
  font-weight: 193;
}

.fw-194 {
  font-weight: 194;
}

.fw-195 {
  font-weight: 195;
}

.fw-196 {
  font-weight: 196;
}

.fw-197 {
  font-weight: 197;
}

.fw-198 {
  font-weight: 198;
}

.fw-199 {
  font-weight: 199;
}

.fw-200 {
  font-weight: 200;
}

.fw-201 {
  font-weight: 201;
}

.fw-202 {
  font-weight: 202;
}

.fw-203 {
  font-weight: 203;
}

.fw-204 {
  font-weight: 204;
}

.fw-205 {
  font-weight: 205;
}

.fw-206 {
  font-weight: 206;
}

.fw-207 {
  font-weight: 207;
}

.fw-208 {
  font-weight: 208;
}

.fw-209 {
  font-weight: 209;
}

.fw-210 {
  font-weight: 210;
}

.fw-211 {
  font-weight: 211;
}

.fw-212 {
  font-weight: 212;
}

.fw-213 {
  font-weight: 213;
}

.fw-214 {
  font-weight: 214;
}

.fw-215 {
  font-weight: 215;
}

.fw-216 {
  font-weight: 216;
}

.fw-217 {
  font-weight: 217;
}

.fw-218 {
  font-weight: 218;
}

.fw-219 {
  font-weight: 219;
}

.fw-220 {
  font-weight: 220;
}

.fw-221 {
  font-weight: 221;
}

.fw-222 {
  font-weight: 222;
}

.fw-223 {
  font-weight: 223;
}

.fw-224 {
  font-weight: 224;
}

.fw-225 {
  font-weight: 225;
}

.fw-226 {
  font-weight: 226;
}

.fw-227 {
  font-weight: 227;
}

.fw-228 {
  font-weight: 228;
}

.fw-229 {
  font-weight: 229;
}

.fw-230 {
  font-weight: 230;
}

.fw-231 {
  font-weight: 231;
}

.fw-232 {
  font-weight: 232;
}

.fw-233 {
  font-weight: 233;
}

.fw-234 {
  font-weight: 234;
}

.fw-235 {
  font-weight: 235;
}

.fw-236 {
  font-weight: 236;
}

.fw-237 {
  font-weight: 237;
}

.fw-238 {
  font-weight: 238;
}

.fw-239 {
  font-weight: 239;
}

.fw-240 {
  font-weight: 240;
}

.fw-241 {
  font-weight: 241;
}

.fw-242 {
  font-weight: 242;
}

.fw-243 {
  font-weight: 243;
}

.fw-244 {
  font-weight: 244;
}

.fw-245 {
  font-weight: 245;
}

.fw-246 {
  font-weight: 246;
}

.fw-247 {
  font-weight: 247;
}

.fw-248 {
  font-weight: 248;
}

.fw-249 {
  font-weight: 249;
}

.fw-250 {
  font-weight: 250;
}

.fw-251 {
  font-weight: 251;
}

.fw-252 {
  font-weight: 252;
}

.fw-253 {
  font-weight: 253;
}

.fw-254 {
  font-weight: 254;
}

.fw-255 {
  font-weight: 255;
}

.fw-256 {
  font-weight: 256;
}

.fw-257 {
  font-weight: 257;
}

.fw-258 {
  font-weight: 258;
}

.fw-259 {
  font-weight: 259;
}

.fw-260 {
  font-weight: 260;
}

.fw-261 {
  font-weight: 261;
}

.fw-262 {
  font-weight: 262;
}

.fw-263 {
  font-weight: 263;
}

.fw-264 {
  font-weight: 264;
}

.fw-265 {
  font-weight: 265;
}

.fw-266 {
  font-weight: 266;
}

.fw-267 {
  font-weight: 267;
}

.fw-268 {
  font-weight: 268;
}

.fw-269 {
  font-weight: 269;
}

.fw-270 {
  font-weight: 270;
}

.fw-271 {
  font-weight: 271;
}

.fw-272 {
  font-weight: 272;
}

.fw-273 {
  font-weight: 273;
}

.fw-274 {
  font-weight: 274;
}

.fw-275 {
  font-weight: 275;
}

.fw-276 {
  font-weight: 276;
}

.fw-277 {
  font-weight: 277;
}

.fw-278 {
  font-weight: 278;
}

.fw-279 {
  font-weight: 279;
}

.fw-280 {
  font-weight: 280;
}

.fw-281 {
  font-weight: 281;
}

.fw-282 {
  font-weight: 282;
}

.fw-283 {
  font-weight: 283;
}

.fw-284 {
  font-weight: 284;
}

.fw-285 {
  font-weight: 285;
}

.fw-286 {
  font-weight: 286;
}

.fw-287 {
  font-weight: 287;
}

.fw-288 {
  font-weight: 288;
}

.fw-289 {
  font-weight: 289;
}

.fw-290 {
  font-weight: 290;
}

.fw-291 {
  font-weight: 291;
}

.fw-292 {
  font-weight: 292;
}

.fw-293 {
  font-weight: 293;
}

.fw-294 {
  font-weight: 294;
}

.fw-295 {
  font-weight: 295;
}

.fw-296 {
  font-weight: 296;
}

.fw-297 {
  font-weight: 297;
}

.fw-298 {
  font-weight: 298;
}

.fw-299 {
  font-weight: 299;
}

.fw-300 {
  font-weight: 300;
}

.fw-301 {
  font-weight: 301;
}

.fw-302 {
  font-weight: 302;
}

.fw-303 {
  font-weight: 303;
}

.fw-304 {
  font-weight: 304;
}

.fw-305 {
  font-weight: 305;
}

.fw-306 {
  font-weight: 306;
}

.fw-307 {
  font-weight: 307;
}

.fw-308 {
  font-weight: 308;
}

.fw-309 {
  font-weight: 309;
}

.fw-310 {
  font-weight: 310;
}

.fw-311 {
  font-weight: 311;
}

.fw-312 {
  font-weight: 312;
}

.fw-313 {
  font-weight: 313;
}

.fw-314 {
  font-weight: 314;
}

.fw-315 {
  font-weight: 315;
}

.fw-316 {
  font-weight: 316;
}

.fw-317 {
  font-weight: 317;
}

.fw-318 {
  font-weight: 318;
}

.fw-319 {
  font-weight: 319;
}

.fw-320 {
  font-weight: 320;
}

.fw-321 {
  font-weight: 321;
}

.fw-322 {
  font-weight: 322;
}

.fw-323 {
  font-weight: 323;
}

.fw-324 {
  font-weight: 324;
}

.fw-325 {
  font-weight: 325;
}

.fw-326 {
  font-weight: 326;
}

.fw-327 {
  font-weight: 327;
}

.fw-328 {
  font-weight: 328;
}

.fw-329 {
  font-weight: 329;
}

.fw-330 {
  font-weight: 330;
}

.fw-331 {
  font-weight: 331;
}

.fw-332 {
  font-weight: 332;
}

.fw-333 {
  font-weight: 333;
}

.fw-334 {
  font-weight: 334;
}

.fw-335 {
  font-weight: 335;
}

.fw-336 {
  font-weight: 336;
}

.fw-337 {
  font-weight: 337;
}

.fw-338 {
  font-weight: 338;
}

.fw-339 {
  font-weight: 339;
}

.fw-340 {
  font-weight: 340;
}

.fw-341 {
  font-weight: 341;
}

.fw-342 {
  font-weight: 342;
}

.fw-343 {
  font-weight: 343;
}

.fw-344 {
  font-weight: 344;
}

.fw-345 {
  font-weight: 345;
}

.fw-346 {
  font-weight: 346;
}

.fw-347 {
  font-weight: 347;
}

.fw-348 {
  font-weight: 348;
}

.fw-349 {
  font-weight: 349;
}

.fw-350 {
  font-weight: 350;
}

.fw-351 {
  font-weight: 351;
}

.fw-352 {
  font-weight: 352;
}

.fw-353 {
  font-weight: 353;
}

.fw-354 {
  font-weight: 354;
}

.fw-355 {
  font-weight: 355;
}

.fw-356 {
  font-weight: 356;
}

.fw-357 {
  font-weight: 357;
}

.fw-358 {
  font-weight: 358;
}

.fw-359 {
  font-weight: 359;
}

.fw-360 {
  font-weight: 360;
}

.fw-361 {
  font-weight: 361;
}

.fw-362 {
  font-weight: 362;
}

.fw-363 {
  font-weight: 363;
}

.fw-364 {
  font-weight: 364;
}

.fw-365 {
  font-weight: 365;
}

.fw-366 {
  font-weight: 366;
}

.fw-367 {
  font-weight: 367;
}

.fw-368 {
  font-weight: 368;
}

.fw-369 {
  font-weight: 369;
}

.fw-370 {
  font-weight: 370;
}

.fw-371 {
  font-weight: 371;
}

.fw-372 {
  font-weight: 372;
}

.fw-373 {
  font-weight: 373;
}

.fw-374 {
  font-weight: 374;
}

.fw-375 {
  font-weight: 375;
}

.fw-376 {
  font-weight: 376;
}

.fw-377 {
  font-weight: 377;
}

.fw-378 {
  font-weight: 378;
}

.fw-379 {
  font-weight: 379;
}

.fw-380 {
  font-weight: 380;
}

.fw-381 {
  font-weight: 381;
}

.fw-382 {
  font-weight: 382;
}

.fw-383 {
  font-weight: 383;
}

.fw-384 {
  font-weight: 384;
}

.fw-385 {
  font-weight: 385;
}

.fw-386 {
  font-weight: 386;
}

.fw-387 {
  font-weight: 387;
}

.fw-388 {
  font-weight: 388;
}

.fw-389 {
  font-weight: 389;
}

.fw-390 {
  font-weight: 390;
}

.fw-391 {
  font-weight: 391;
}

.fw-392 {
  font-weight: 392;
}

.fw-393 {
  font-weight: 393;
}

.fw-394 {
  font-weight: 394;
}

.fw-395 {
  font-weight: 395;
}

.fw-396 {
  font-weight: 396;
}

.fw-397 {
  font-weight: 397;
}

.fw-398 {
  font-weight: 398;
}

.fw-399 {
  font-weight: 399;
}

.fw-400 {
  font-weight: 400;
}

.fw-401 {
  font-weight: 401;
}

.fw-402 {
  font-weight: 402;
}

.fw-403 {
  font-weight: 403;
}

.fw-404 {
  font-weight: 404;
}

.fw-405 {
  font-weight: 405;
}

.fw-406 {
  font-weight: 406;
}

.fw-407 {
  font-weight: 407;
}

.fw-408 {
  font-weight: 408;
}

.fw-409 {
  font-weight: 409;
}

.fw-410 {
  font-weight: 410;
}

.fw-411 {
  font-weight: 411;
}

.fw-412 {
  font-weight: 412;
}

.fw-413 {
  font-weight: 413;
}

.fw-414 {
  font-weight: 414;
}

.fw-415 {
  font-weight: 415;
}

.fw-416 {
  font-weight: 416;
}

.fw-417 {
  font-weight: 417;
}

.fw-418 {
  font-weight: 418;
}

.fw-419 {
  font-weight: 419;
}

.fw-420 {
  font-weight: 420;
}

.fw-421 {
  font-weight: 421;
}

.fw-422 {
  font-weight: 422;
}

.fw-423 {
  font-weight: 423;
}

.fw-424 {
  font-weight: 424;
}

.fw-425 {
  font-weight: 425;
}

.fw-426 {
  font-weight: 426;
}

.fw-427 {
  font-weight: 427;
}

.fw-428 {
  font-weight: 428;
}

.fw-429 {
  font-weight: 429;
}

.fw-430 {
  font-weight: 430;
}

.fw-431 {
  font-weight: 431;
}

.fw-432 {
  font-weight: 432;
}

.fw-433 {
  font-weight: 433;
}

.fw-434 {
  font-weight: 434;
}

.fw-435 {
  font-weight: 435;
}

.fw-436 {
  font-weight: 436;
}

.fw-437 {
  font-weight: 437;
}

.fw-438 {
  font-weight: 438;
}

.fw-439 {
  font-weight: 439;
}

.fw-440 {
  font-weight: 440;
}

.fw-441 {
  font-weight: 441;
}

.fw-442 {
  font-weight: 442;
}

.fw-443 {
  font-weight: 443;
}

.fw-444 {
  font-weight: 444;
}

.fw-445 {
  font-weight: 445;
}

.fw-446 {
  font-weight: 446;
}

.fw-447 {
  font-weight: 447;
}

.fw-448 {
  font-weight: 448;
}

.fw-449 {
  font-weight: 449;
}

.fw-450 {
  font-weight: 450;
}

.fw-451 {
  font-weight: 451;
}

.fw-452 {
  font-weight: 452;
}

.fw-453 {
  font-weight: 453;
}

.fw-454 {
  font-weight: 454;
}

.fw-455 {
  font-weight: 455;
}

.fw-456 {
  font-weight: 456;
}

.fw-457 {
  font-weight: 457;
}

.fw-458 {
  font-weight: 458;
}

.fw-459 {
  font-weight: 459;
}

.fw-460 {
  font-weight: 460;
}

.fw-461 {
  font-weight: 461;
}

.fw-462 {
  font-weight: 462;
}

.fw-463 {
  font-weight: 463;
}

.fw-464 {
  font-weight: 464;
}

.fw-465 {
  font-weight: 465;
}

.fw-466 {
  font-weight: 466;
}

.fw-467 {
  font-weight: 467;
}

.fw-468 {
  font-weight: 468;
}

.fw-469 {
  font-weight: 469;
}

.fw-470 {
  font-weight: 470;
}

.fw-471 {
  font-weight: 471;
}

.fw-472 {
  font-weight: 472;
}

.fw-473 {
  font-weight: 473;
}

.fw-474 {
  font-weight: 474;
}

.fw-475 {
  font-weight: 475;
}

.fw-476 {
  font-weight: 476;
}

.fw-477 {
  font-weight: 477;
}

.fw-478 {
  font-weight: 478;
}

.fw-479 {
  font-weight: 479;
}

.fw-480 {
  font-weight: 480;
}

.fw-481 {
  font-weight: 481;
}

.fw-482 {
  font-weight: 482;
}

.fw-483 {
  font-weight: 483;
}

.fw-484 {
  font-weight: 484;
}

.fw-485 {
  font-weight: 485;
}

.fw-486 {
  font-weight: 486;
}

.fw-487 {
  font-weight: 487;
}

.fw-488 {
  font-weight: 488;
}

.fw-489 {
  font-weight: 489;
}

.fw-490 {
  font-weight: 490;
}

.fw-491 {
  font-weight: 491;
}

.fw-492 {
  font-weight: 492;
}

.fw-493 {
  font-weight: 493;
}

.fw-494 {
  font-weight: 494;
}

.fw-495 {
  font-weight: 495;
}

.fw-496 {
  font-weight: 496;
}

.fw-497 {
  font-weight: 497;
}

.fw-498 {
  font-weight: 498;
}

.fw-499 {
  font-weight: 499;
}

.fw-500 {
  font-weight: 500;
}

.fw-501 {
  font-weight: 501;
}

.fw-502 {
  font-weight: 502;
}

.fw-503 {
  font-weight: 503;
}

.fw-504 {
  font-weight: 504;
}

.fw-505 {
  font-weight: 505;
}

.fw-506 {
  font-weight: 506;
}

.fw-507 {
  font-weight: 507;
}

.fw-508 {
  font-weight: 508;
}

.fw-509 {
  font-weight: 509;
}

.fw-510 {
  font-weight: 510;
}

.fw-511 {
  font-weight: 511;
}

.fw-512 {
  font-weight: 512;
}

.fw-513 {
  font-weight: 513;
}

.fw-514 {
  font-weight: 514;
}

.fw-515 {
  font-weight: 515;
}

.fw-516 {
  font-weight: 516;
}

.fw-517 {
  font-weight: 517;
}

.fw-518 {
  font-weight: 518;
}

.fw-519 {
  font-weight: 519;
}

.fw-520 {
  font-weight: 520;
}

.fw-521 {
  font-weight: 521;
}

.fw-522 {
  font-weight: 522;
}

.fw-523 {
  font-weight: 523;
}

.fw-524 {
  font-weight: 524;
}

.fw-525 {
  font-weight: 525;
}

.fw-526 {
  font-weight: 526;
}

.fw-527 {
  font-weight: 527;
}

.fw-528 {
  font-weight: 528;
}

.fw-529 {
  font-weight: 529;
}

.fw-530 {
  font-weight: 530;
}

.fw-531 {
  font-weight: 531;
}

.fw-532 {
  font-weight: 532;
}

.fw-533 {
  font-weight: 533;
}

.fw-534 {
  font-weight: 534;
}

.fw-535 {
  font-weight: 535;
}

.fw-536 {
  font-weight: 536;
}

.fw-537 {
  font-weight: 537;
}

.fw-538 {
  font-weight: 538;
}

.fw-539 {
  font-weight: 539;
}

.fw-540 {
  font-weight: 540;
}

.fw-541 {
  font-weight: 541;
}

.fw-542 {
  font-weight: 542;
}

.fw-543 {
  font-weight: 543;
}

.fw-544 {
  font-weight: 544;
}

.fw-545 {
  font-weight: 545;
}

.fw-546 {
  font-weight: 546;
}

.fw-547 {
  font-weight: 547;
}

.fw-548 {
  font-weight: 548;
}

.fw-549 {
  font-weight: 549;
}

.fw-550 {
  font-weight: 550;
}

.fw-551 {
  font-weight: 551;
}

.fw-552 {
  font-weight: 552;
}

.fw-553 {
  font-weight: 553;
}

.fw-554 {
  font-weight: 554;
}

.fw-555 {
  font-weight: 555;
}

.fw-556 {
  font-weight: 556;
}

.fw-557 {
  font-weight: 557;
}

.fw-558 {
  font-weight: 558;
}

.fw-559 {
  font-weight: 559;
}

.fw-560 {
  font-weight: 560;
}

.fw-561 {
  font-weight: 561;
}

.fw-562 {
  font-weight: 562;
}

.fw-563 {
  font-weight: 563;
}

.fw-564 {
  font-weight: 564;
}

.fw-565 {
  font-weight: 565;
}

.fw-566 {
  font-weight: 566;
}

.fw-567 {
  font-weight: 567;
}

.fw-568 {
  font-weight: 568;
}

.fw-569 {
  font-weight: 569;
}

.fw-570 {
  font-weight: 570;
}

.fw-571 {
  font-weight: 571;
}

.fw-572 {
  font-weight: 572;
}

.fw-573 {
  font-weight: 573;
}

.fw-574 {
  font-weight: 574;
}

.fw-575 {
  font-weight: 575;
}

.fw-576 {
  font-weight: 576;
}

.fw-577 {
  font-weight: 577;
}

.fw-578 {
  font-weight: 578;
}

.fw-579 {
  font-weight: 579;
}

.fw-580 {
  font-weight: 580;
}

.fw-581 {
  font-weight: 581;
}

.fw-582 {
  font-weight: 582;
}

.fw-583 {
  font-weight: 583;
}

.fw-584 {
  font-weight: 584;
}

.fw-585 {
  font-weight: 585;
}

.fw-586 {
  font-weight: 586;
}

.fw-587 {
  font-weight: 587;
}

.fw-588 {
  font-weight: 588;
}

.fw-589 {
  font-weight: 589;
}

.fw-590 {
  font-weight: 590;
}

.fw-591 {
  font-weight: 591;
}

.fw-592 {
  font-weight: 592;
}

.fw-593 {
  font-weight: 593;
}

.fw-594 {
  font-weight: 594;
}

.fw-595 {
  font-weight: 595;
}

.fw-596 {
  font-weight: 596;
}

.fw-597 {
  font-weight: 597;
}

.fw-598 {
  font-weight: 598;
}

.fw-599 {
  font-weight: 599;
}

.fw-600 {
  font-weight: 600;
}

.fw-601 {
  font-weight: 601;
}

.fw-602 {
  font-weight: 602;
}

.fw-603 {
  font-weight: 603;
}

.fw-604 {
  font-weight: 604;
}

.fw-605 {
  font-weight: 605;
}

.fw-606 {
  font-weight: 606;
}

.fw-607 {
  font-weight: 607;
}

.fw-608 {
  font-weight: 608;
}

.fw-609 {
  font-weight: 609;
}

.fw-610 {
  font-weight: 610;
}

.fw-611 {
  font-weight: 611;
}

.fw-612 {
  font-weight: 612;
}

.fw-613 {
  font-weight: 613;
}

.fw-614 {
  font-weight: 614;
}

.fw-615 {
  font-weight: 615;
}

.fw-616 {
  font-weight: 616;
}

.fw-617 {
  font-weight: 617;
}

.fw-618 {
  font-weight: 618;
}

.fw-619 {
  font-weight: 619;
}

.fw-620 {
  font-weight: 620;
}

.fw-621 {
  font-weight: 621;
}

.fw-622 {
  font-weight: 622;
}

.fw-623 {
  font-weight: 623;
}

.fw-624 {
  font-weight: 624;
}

.fw-625 {
  font-weight: 625;
}

.fw-626 {
  font-weight: 626;
}

.fw-627 {
  font-weight: 627;
}

.fw-628 {
  font-weight: 628;
}

.fw-629 {
  font-weight: 629;
}

.fw-630 {
  font-weight: 630;
}

.fw-631 {
  font-weight: 631;
}

.fw-632 {
  font-weight: 632;
}

.fw-633 {
  font-weight: 633;
}

.fw-634 {
  font-weight: 634;
}

.fw-635 {
  font-weight: 635;
}

.fw-636 {
  font-weight: 636;
}

.fw-637 {
  font-weight: 637;
}

.fw-638 {
  font-weight: 638;
}

.fw-639 {
  font-weight: 639;
}

.fw-640 {
  font-weight: 640;
}

.fw-641 {
  font-weight: 641;
}

.fw-642 {
  font-weight: 642;
}

.fw-643 {
  font-weight: 643;
}

.fw-644 {
  font-weight: 644;
}

.fw-645 {
  font-weight: 645;
}

.fw-646 {
  font-weight: 646;
}

.fw-647 {
  font-weight: 647;
}

.fw-648 {
  font-weight: 648;
}

.fw-649 {
  font-weight: 649;
}

.fw-650 {
  font-weight: 650;
}

.fw-651 {
  font-weight: 651;
}

.fw-652 {
  font-weight: 652;
}

.fw-653 {
  font-weight: 653;
}

.fw-654 {
  font-weight: 654;
}

.fw-655 {
  font-weight: 655;
}

.fw-656 {
  font-weight: 656;
}

.fw-657 {
  font-weight: 657;
}

.fw-658 {
  font-weight: 658;
}

.fw-659 {
  font-weight: 659;
}

.fw-660 {
  font-weight: 660;
}

.fw-661 {
  font-weight: 661;
}

.fw-662 {
  font-weight: 662;
}

.fw-663 {
  font-weight: 663;
}

.fw-664 {
  font-weight: 664;
}

.fw-665 {
  font-weight: 665;
}

.fw-666 {
  font-weight: 666;
}

.fw-667 {
  font-weight: 667;
}

.fw-668 {
  font-weight: 668;
}

.fw-669 {
  font-weight: 669;
}

.fw-670 {
  font-weight: 670;
}

.fw-671 {
  font-weight: 671;
}

.fw-672 {
  font-weight: 672;
}

.fw-673 {
  font-weight: 673;
}

.fw-674 {
  font-weight: 674;
}

.fw-675 {
  font-weight: 675;
}

.fw-676 {
  font-weight: 676;
}

.fw-677 {
  font-weight: 677;
}

.fw-678 {
  font-weight: 678;
}

.fw-679 {
  font-weight: 679;
}

.fw-680 {
  font-weight: 680;
}

.fw-681 {
  font-weight: 681;
}

.fw-682 {
  font-weight: 682;
}

.fw-683 {
  font-weight: 683;
}

.fw-684 {
  font-weight: 684;
}

.fw-685 {
  font-weight: 685;
}

.fw-686 {
  font-weight: 686;
}

.fw-687 {
  font-weight: 687;
}

.fw-688 {
  font-weight: 688;
}

.fw-689 {
  font-weight: 689;
}

.fw-690 {
  font-weight: 690;
}

.fw-691 {
  font-weight: 691;
}

.fw-692 {
  font-weight: 692;
}

.fw-693 {
  font-weight: 693;
}

.fw-694 {
  font-weight: 694;
}

.fw-695 {
  font-weight: 695;
}

.fw-696 {
  font-weight: 696;
}

.fw-697 {
  font-weight: 697;
}

.fw-698 {
  font-weight: 698;
}

.fw-699 {
  font-weight: 699;
}

.fw-700 {
  font-weight: 700;
}

.fw-701 {
  font-weight: 701;
}

.fw-702 {
  font-weight: 702;
}

.fw-703 {
  font-weight: 703;
}

.fw-704 {
  font-weight: 704;
}

.fw-705 {
  font-weight: 705;
}

.fw-706 {
  font-weight: 706;
}

.fw-707 {
  font-weight: 707;
}

.fw-708 {
  font-weight: 708;
}

.fw-709 {
  font-weight: 709;
}

.fw-710 {
  font-weight: 710;
}

.fw-711 {
  font-weight: 711;
}

.fw-712 {
  font-weight: 712;
}

.fw-713 {
  font-weight: 713;
}

.fw-714 {
  font-weight: 714;
}

.fw-715 {
  font-weight: 715;
}

.fw-716 {
  font-weight: 716;
}

.fw-717 {
  font-weight: 717;
}

.fw-718 {
  font-weight: 718;
}

.fw-719 {
  font-weight: 719;
}

.fw-720 {
  font-weight: 720;
}

.fw-721 {
  font-weight: 721;
}

.fw-722 {
  font-weight: 722;
}

.fw-723 {
  font-weight: 723;
}

.fw-724 {
  font-weight: 724;
}

.fw-725 {
  font-weight: 725;
}

.fw-726 {
  font-weight: 726;
}

.fw-727 {
  font-weight: 727;
}

.fw-728 {
  font-weight: 728;
}

.fw-729 {
  font-weight: 729;
}

.fw-730 {
  font-weight: 730;
}

.fw-731 {
  font-weight: 731;
}

.fw-732 {
  font-weight: 732;
}

.fw-733 {
  font-weight: 733;
}

.fw-734 {
  font-weight: 734;
}

.fw-735 {
  font-weight: 735;
}

.fw-736 {
  font-weight: 736;
}

.fw-737 {
  font-weight: 737;
}

.fw-738 {
  font-weight: 738;
}

.fw-739 {
  font-weight: 739;
}

.fw-740 {
  font-weight: 740;
}

.fw-741 {
  font-weight: 741;
}

.fw-742 {
  font-weight: 742;
}

.fw-743 {
  font-weight: 743;
}

.fw-744 {
  font-weight: 744;
}

.fw-745 {
  font-weight: 745;
}

.fw-746 {
  font-weight: 746;
}

.fw-747 {
  font-weight: 747;
}

.fw-748 {
  font-weight: 748;
}

.fw-749 {
  font-weight: 749;
}

.fw-750 {
  font-weight: 750;
}

.fw-751 {
  font-weight: 751;
}

.fw-752 {
  font-weight: 752;
}

.fw-753 {
  font-weight: 753;
}

.fw-754 {
  font-weight: 754;
}

.fw-755 {
  font-weight: 755;
}

.fw-756 {
  font-weight: 756;
}

.fw-757 {
  font-weight: 757;
}

.fw-758 {
  font-weight: 758;
}

.fw-759 {
  font-weight: 759;
}

.fw-760 {
  font-weight: 760;
}

.fw-761 {
  font-weight: 761;
}

.fw-762 {
  font-weight: 762;
}

.fw-763 {
  font-weight: 763;
}

.fw-764 {
  font-weight: 764;
}

.fw-765 {
  font-weight: 765;
}

.fw-766 {
  font-weight: 766;
}

.fw-767 {
  font-weight: 767;
}

.fw-768 {
  font-weight: 768;
}

.fw-769 {
  font-weight: 769;
}

.fw-770 {
  font-weight: 770;
}

.fw-771 {
  font-weight: 771;
}

.fw-772 {
  font-weight: 772;
}

.fw-773 {
  font-weight: 773;
}

.fw-774 {
  font-weight: 774;
}

.fw-775 {
  font-weight: 775;
}

.fw-776 {
  font-weight: 776;
}

.fw-777 {
  font-weight: 777;
}

.fw-778 {
  font-weight: 778;
}

.fw-779 {
  font-weight: 779;
}

.fw-780 {
  font-weight: 780;
}

.fw-781 {
  font-weight: 781;
}

.fw-782 {
  font-weight: 782;
}

.fw-783 {
  font-weight: 783;
}

.fw-784 {
  font-weight: 784;
}

.fw-785 {
  font-weight: 785;
}

.fw-786 {
  font-weight: 786;
}

.fw-787 {
  font-weight: 787;
}

.fw-788 {
  font-weight: 788;
}

.fw-789 {
  font-weight: 789;
}

.fw-790 {
  font-weight: 790;
}

.fw-791 {
  font-weight: 791;
}

.fw-792 {
  font-weight: 792;
}

.fw-793 {
  font-weight: 793;
}

.fw-794 {
  font-weight: 794;
}

.fw-795 {
  font-weight: 795;
}

.fw-796 {
  font-weight: 796;
}

.fw-797 {
  font-weight: 797;
}

.fw-798 {
  font-weight: 798;
}

.fw-799 {
  font-weight: 799;
}

.fw-800 {
  font-weight: 800;
}

.fw-801 {
  font-weight: 801;
}

.fw-802 {
  font-weight: 802;
}

.fw-803 {
  font-weight: 803;
}

.fw-804 {
  font-weight: 804;
}

.fw-805 {
  font-weight: 805;
}

.fw-806 {
  font-weight: 806;
}

.fw-807 {
  font-weight: 807;
}

.fw-808 {
  font-weight: 808;
}

.fw-809 {
  font-weight: 809;
}

.fw-810 {
  font-weight: 810;
}

.fw-811 {
  font-weight: 811;
}

.fw-812 {
  font-weight: 812;
}

.fw-813 {
  font-weight: 813;
}

.fw-814 {
  font-weight: 814;
}

.fw-815 {
  font-weight: 815;
}

.fw-816 {
  font-weight: 816;
}

.fw-817 {
  font-weight: 817;
}

.fw-818 {
  font-weight: 818;
}

.fw-819 {
  font-weight: 819;
}

.fw-820 {
  font-weight: 820;
}

.fw-821 {
  font-weight: 821;
}

.fw-822 {
  font-weight: 822;
}

.fw-823 {
  font-weight: 823;
}

.fw-824 {
  font-weight: 824;
}

.fw-825 {
  font-weight: 825;
}

.fw-826 {
  font-weight: 826;
}

.fw-827 {
  font-weight: 827;
}

.fw-828 {
  font-weight: 828;
}

.fw-829 {
  font-weight: 829;
}

.fw-830 {
  font-weight: 830;
}

.fw-831 {
  font-weight: 831;
}

.fw-832 {
  font-weight: 832;
}

.fw-833 {
  font-weight: 833;
}

.fw-834 {
  font-weight: 834;
}

.fw-835 {
  font-weight: 835;
}

.fw-836 {
  font-weight: 836;
}

.fw-837 {
  font-weight: 837;
}

.fw-838 {
  font-weight: 838;
}

.fw-839 {
  font-weight: 839;
}

.fw-840 {
  font-weight: 840;
}

.fw-841 {
  font-weight: 841;
}

.fw-842 {
  font-weight: 842;
}

.fw-843 {
  font-weight: 843;
}

.fw-844 {
  font-weight: 844;
}

.fw-845 {
  font-weight: 845;
}

.fw-846 {
  font-weight: 846;
}

.fw-847 {
  font-weight: 847;
}

.fw-848 {
  font-weight: 848;
}

.fw-849 {
  font-weight: 849;
}

.fw-850 {
  font-weight: 850;
}

.fw-851 {
  font-weight: 851;
}

.fw-852 {
  font-weight: 852;
}

.fw-853 {
  font-weight: 853;
}

.fw-854 {
  font-weight: 854;
}

.fw-855 {
  font-weight: 855;
}

.fw-856 {
  font-weight: 856;
}

.fw-857 {
  font-weight: 857;
}

.fw-858 {
  font-weight: 858;
}

.fw-859 {
  font-weight: 859;
}

.fw-860 {
  font-weight: 860;
}

.fw-861 {
  font-weight: 861;
}

.fw-862 {
  font-weight: 862;
}

.fw-863 {
  font-weight: 863;
}

.fw-864 {
  font-weight: 864;
}

.fw-865 {
  font-weight: 865;
}

.fw-866 {
  font-weight: 866;
}

.fw-867 {
  font-weight: 867;
}

.fw-868 {
  font-weight: 868;
}

.fw-869 {
  font-weight: 869;
}

.fw-870 {
  font-weight: 870;
}

.fw-871 {
  font-weight: 871;
}

.fw-872 {
  font-weight: 872;
}

.fw-873 {
  font-weight: 873;
}

.fw-874 {
  font-weight: 874;
}

.fw-875 {
  font-weight: 875;
}

.fw-876 {
  font-weight: 876;
}

.fw-877 {
  font-weight: 877;
}

.fw-878 {
  font-weight: 878;
}

.fw-879 {
  font-weight: 879;
}

.fw-880 {
  font-weight: 880;
}

.fw-881 {
  font-weight: 881;
}

.fw-882 {
  font-weight: 882;
}

.fw-883 {
  font-weight: 883;
}

.fw-884 {
  font-weight: 884;
}

.fw-885 {
  font-weight: 885;
}

.fw-886 {
  font-weight: 886;
}

.fw-887 {
  font-weight: 887;
}

.fw-888 {
  font-weight: 888;
}

.fw-889 {
  font-weight: 889;
}

.fw-890 {
  font-weight: 890;
}

.fw-891 {
  font-weight: 891;
}

.fw-892 {
  font-weight: 892;
}

.fw-893 {
  font-weight: 893;
}

.fw-894 {
  font-weight: 894;
}

.fw-895 {
  font-weight: 895;
}

.fw-896 {
  font-weight: 896;
}

.fw-897 {
  font-weight: 897;
}

.fw-898 {
  font-weight: 898;
}

.fw-899 {
  font-weight: 899;
}

.fw-900 {
  font-weight: 900;
}

.fw-901 {
  font-weight: 901;
}

.fw-902 {
  font-weight: 902;
}

.fw-903 {
  font-weight: 903;
}

.fw-904 {
  font-weight: 904;
}

.fw-905 {
  font-weight: 905;
}

.fw-906 {
  font-weight: 906;
}

.fw-907 {
  font-weight: 907;
}

.fw-908 {
  font-weight: 908;
}

.fw-909 {
  font-weight: 909;
}

.fw-910 {
  font-weight: 910;
}

.fw-911 {
  font-weight: 911;
}

.fw-912 {
  font-weight: 912;
}

.fw-913 {
  font-weight: 913;
}

.fw-914 {
  font-weight: 914;
}

.fw-915 {
  font-weight: 915;
}

.fw-916 {
  font-weight: 916;
}

.fw-917 {
  font-weight: 917;
}

.fw-918 {
  font-weight: 918;
}

.fw-919 {
  font-weight: 919;
}

.fw-920 {
  font-weight: 920;
}

.fw-921 {
  font-weight: 921;
}

.fw-922 {
  font-weight: 922;
}

.fw-923 {
  font-weight: 923;
}

.fw-924 {
  font-weight: 924;
}

.fw-925 {
  font-weight: 925;
}

.fw-926 {
  font-weight: 926;
}

.fw-927 {
  font-weight: 927;
}

.fw-928 {
  font-weight: 928;
}

.fw-929 {
  font-weight: 929;
}

.fw-930 {
  font-weight: 930;
}

.fw-931 {
  font-weight: 931;
}

.fw-932 {
  font-weight: 932;
}

.fw-933 {
  font-weight: 933;
}

.fw-934 {
  font-weight: 934;
}

.fw-935 {
  font-weight: 935;
}

.fw-936 {
  font-weight: 936;
}

.fw-937 {
  font-weight: 937;
}

.fw-938 {
  font-weight: 938;
}

.fw-939 {
  font-weight: 939;
}

.fw-940 {
  font-weight: 940;
}

.fw-941 {
  font-weight: 941;
}

.fw-942 {
  font-weight: 942;
}

.fw-943 {
  font-weight: 943;
}

.fw-944 {
  font-weight: 944;
}

.fw-945 {
  font-weight: 945;
}

.fw-946 {
  font-weight: 946;
}

.fw-947 {
  font-weight: 947;
}

.fw-948 {
  font-weight: 948;
}

.fw-949 {
  font-weight: 949;
}

.fw-950 {
  font-weight: 950;
}

.fw-951 {
  font-weight: 951;
}

.fw-952 {
  font-weight: 952;
}

.fw-953 {
  font-weight: 953;
}

.fw-954 {
  font-weight: 954;
}

.fw-955 {
  font-weight: 955;
}

.fw-956 {
  font-weight: 956;
}

.fw-957 {
  font-weight: 957;
}

.fw-958 {
  font-weight: 958;
}

.fw-959 {
  font-weight: 959;
}

.fw-960 {
  font-weight: 960;
}

.fw-961 {
  font-weight: 961;
}

.fw-962 {
  font-weight: 962;
}

.fw-963 {
  font-weight: 963;
}

.fw-964 {
  font-weight: 964;
}

.fw-965 {
  font-weight: 965;
}

.fw-966 {
  font-weight: 966;
}

.fw-967 {
  font-weight: 967;
}

.fw-968 {
  font-weight: 968;
}

.fw-969 {
  font-weight: 969;
}

.fw-970 {
  font-weight: 970;
}

.fw-971 {
  font-weight: 971;
}

.fw-972 {
  font-weight: 972;
}

.fw-973 {
  font-weight: 973;
}

.fw-974 {
  font-weight: 974;
}

.fw-975 {
  font-weight: 975;
}

.fw-976 {
  font-weight: 976;
}

.fw-977 {
  font-weight: 977;
}

.fw-978 {
  font-weight: 978;
}

.fw-979 {
  font-weight: 979;
}

.fw-980 {
  font-weight: 980;
}

.fw-981 {
  font-weight: 981;
}

.fw-982 {
  font-weight: 982;
}

.fw-983 {
  font-weight: 983;
}

.fw-984 {
  font-weight: 984;
}

.fw-985 {
  font-weight: 985;
}

.fw-986 {
  font-weight: 986;
}

.fw-987 {
  font-weight: 987;
}

.fw-988 {
  font-weight: 988;
}

.fw-989 {
  font-weight: 989;
}

.fw-990 {
  font-weight: 990;
}

.fw-991 {
  font-weight: 991;
}

.fw-992 {
  font-weight: 992;
}

.fw-993 {
  font-weight: 993;
}

.fw-994 {
  font-weight: 994;
}

.fw-995 {
  font-weight: 995;
}

.fw-996 {
  font-weight: 996;
}

.fw-997 {
  font-weight: 997;
}

.fw-998 {
  font-weight: 998;
}

.fw-999 {
  font-weight: 999;
}

.fw-1000 {
  font-weight: 1000;
}

/*----margin Top------------------*/
.m-t-0 {
  margin-top: 0px;
}

.m-t-1 {
  margin-top: 1px;
}

.m-t-2 {
  margin-top: 2px;
}

.m-t-3 {
  margin-top: 3px;
}

.m-t-4 {
  margin-top: 4px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-6 {
  margin-top: 6px;
}

.m-t-7 {
  margin-top: 7px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-9 {
  margin-top: 9px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-11 {
  margin-top: 11px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-t-13 {
  margin-top: 13px;
}

.m-t-14 {
  margin-top: 14px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-17 {
  margin-top: 17px;
}

.m-t-18 {
  margin-top: 18px;
}

.m-t-19 {
  margin-top: 19px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-21 {
  margin-top: 21px;
}

.m-t-22 {
  margin-top: 22px;
}

.m-t-23 {
  margin-top: 23px;
}

.m-t-24 {
  margin-top: 24px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-26 {
  margin-top: 26px;
}

.m-t-27 {
  margin-top: 27px;
}

.m-t-28 {
  margin-top: 28px;
}

.m-t-29 {
  margin-top: 29px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-31 {
  margin-top: 31px;
}

.m-t-32 {
  margin-top: 32px;
}

.m-t-33 {
  margin-top: 33px;
}

.m-t-34 {
  margin-top: 34px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-t-36 {
  margin-top: 36px;
}

.m-t-37 {
  margin-top: 37px;
}

.m-t-38 {
  margin-top: 38px;
}

.m-t-39 {
  margin-top: 39px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-41 {
  margin-top: 41px;
}

.m-t-42 {
  margin-top: 42px;
}

.m-t-43 {
  margin-top: 43px;
}

.m-t-44 {
  margin-top: 44px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-t-46 {
  margin-top: 46px;
}

.m-t-47 {
  margin-top: 47px;
}

.m-t-48 {
  margin-top: 48px;
}

.m-t-49 {
  margin-top: 49px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-t-51 {
  margin-top: 51px;
}

.m-t-52 {
  margin-top: 52px;
}

.m-t-53 {
  margin-top: 53px;
}

.m-t-54 {
  margin-top: 54px;
}

.m-t-55 {
  margin-top: 55px;
}

.m-t-56 {
  margin-top: 56px;
}

.m-t-57 {
  margin-top: 57px;
}

.m-t-58 {
  margin-top: 58px;
}

.m-t-59 {
  margin-top: 59px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-t-61 {
  margin-top: 61px;
}

.m-t-62 {
  margin-top: 62px;
}

.m-t-63 {
  margin-top: 63px;
}

.m-t-64 {
  margin-top: 64px;
}

.m-t-65 {
  margin-top: 65px;
}

.m-t-66 {
  margin-top: 66px;
}

.m-t-67 {
  margin-top: 67px;
}

.m-t-68 {
  margin-top: 68px;
}

.m-t-69 {
  margin-top: 69px;
}

.m-t-70 {
  margin-top: 70px;
}

.m-t-71 {
  margin-top: 71px;
}

.m-t-72 {
  margin-top: 72px;
}

.m-t-73 {
  margin-top: 73px;
}

.m-t-74 {
  margin-top: 74px;
}

.m-t-75 {
  margin-top: 75px;
}

.m-t-76 {
  margin-top: 76px;
}

.m-t-77 {
  margin-top: 77px;
}

.m-t-78 {
  margin-top: 78px;
}

.m-t-79 {
  margin-top: 79px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-t-81 {
  margin-top: 81px;
}

.m-t-82 {
  margin-top: 82px;
}

.m-t-83 {
  margin-top: 83px;
}

.m-t-84 {
  margin-top: 84px;
}

.m-t-85 {
  margin-top: 85px;
}

.m-t-86 {
  margin-top: 86px;
}

.m-t-87 {
  margin-top: 87px;
}

.m-t-88 {
  margin-top: 88px;
}

.m-t-89 {
  margin-top: 89px;
}

.m-t-90 {
  margin-top: 90px;
}

.m-t-91 {
  margin-top: 91px;
}

.m-t-92 {
  margin-top: 92px;
}

.m-t-93 {
  margin-top: 93px;
}

.m-t-94 {
  margin-top: 94px;
}

.m-t-95 {
  margin-top: 95px;
}

.m-t-96 {
  margin-top: 96px;
}

.m-t-97 {
  margin-top: 97px;
}

.m-t-98 {
  margin-top: 98px;
}

.m-t-99 {
  margin-top: 99px;
}

.m-t-100 {
  margin-top: 100px;
}

/*----margin Bottom------------------*/
.m-b-0 {
  margin-bottom: 0px;
}

.m-b-1 {
  margin-bottom: 1px;
}

.m-b-2 {
  margin-bottom: 2px;
}

.m-b-3 {
  margin-bottom: 3px;
}

.m-b-4 {
  margin-bottom: 4px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-6 {
  margin-bottom: 6px;
}

.m-b-7 {
  margin-bottom: 7px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-9 {
  margin-bottom: 9px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-11 {
  margin-bottom: 11px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-13 {
  margin-bottom: 13px;
}

.m-b-14 {
  margin-bottom: 14px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-b-17 {
  margin-bottom: 17px;
}

.m-b-18 {
  margin-bottom: 18px;
}

.m-b-19 {
  margin-bottom: 19px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-21 {
  margin-bottom: 21px;
}

.m-b-22 {
  margin-bottom: 22px;
}

.m-b-23 {
  margin-bottom: 23px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-26 {
  margin-bottom: 26px;
}

.m-b-27 {
  margin-bottom: 27px;
}

.m-b-28 {
  margin-bottom: 28px;
}

.m-b-29 {
  margin-bottom: 29px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-31 {
  margin-bottom: 31px;
}

.m-b-32 {
  margin-bottom: 32px;
}

.m-b-33 {
  margin-bottom: 33px;
}

.m-b-34 {
  margin-bottom: 34px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-b-36 {
  margin-bottom: 36px;
}

.m-b-37 {
  margin-bottom: 37px;
}

.m-b-38 {
  margin-bottom: 38px;
}

.m-b-39 {
  margin-bottom: 39px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-41 {
  margin-bottom: 41px;
}

.m-b-42 {
  margin-bottom: 42px;
}

.m-b-43 {
  margin-bottom: 43px;
}

.m-b-44 {
  margin-bottom: 44px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-b-46 {
  margin-bottom: 46px;
}

.m-b-47 {
  margin-bottom: 47px;
}

.m-b-48 {
  margin-bottom: 48px;
}

.m-b-49 {
  margin-bottom: 49px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-b-51 {
  margin-bottom: 51px;
}

.m-b-52 {
  margin-bottom: 52px;
}

.m-b-53 {
  margin-bottom: 53px;
}

.m-b-54 {
  margin-bottom: 54px;
}

.m-b-55 {
  margin-bottom: 55px;
}

.m-b-56 {
  margin-bottom: 56px;
}

.m-b-57 {
  margin-bottom: 57px;
}

.m-b-58 {
  margin-bottom: 58px;
}

.m-b-59 {
  margin-bottom: 59px;
}

.m-b-60 {
  margin-bottom: 60px;
}

.m-b-61 {
  margin-bottom: 61px;
}

.m-b-62 {
  margin-bottom: 62px;
}

.m-b-63 {
  margin-bottom: 63px;
}

.m-b-64 {
  margin-bottom: 64px;
}

.m-b-65 {
  margin-bottom: 65px;
}

.m-b-66 {
  margin-bottom: 66px;
}

.m-b-67 {
  margin-bottom: 67px;
}

.m-b-68 {
  margin-bottom: 68px;
}

.m-b-69 {
  margin-bottom: 69px;
}

.m-b-70 {
  margin-bottom: 70px;
}

.m-b-71 {
  margin-bottom: 71px;
}

.m-b-72 {
  margin-bottom: 72px;
}

.m-b-73 {
  margin-bottom: 73px;
}

.m-b-74 {
  margin-bottom: 74px;
}

.m-b-75 {
  margin-bottom: 75px;
}

.m-b-76 {
  margin-bottom: 76px;
}

.m-b-77 {
  margin-bottom: 77px;
}

.m-b-78 {
  margin-bottom: 78px;
}

.m-b-79 {
  margin-bottom: 79px;
}

.m-b-80 {
  margin-bottom: 80px;
}

.m-b-81 {
  margin-bottom: 81px;
}

.m-b-82 {
  margin-bottom: 82px;
}

.m-b-83 {
  margin-bottom: 83px;
}

.m-b-84 {
  margin-bottom: 84px;
}

.m-b-85 {
  margin-bottom: 85px;
}

.m-b-86 {
  margin-bottom: 86px;
}

.m-b-87 {
  margin-bottom: 87px;
}

.m-b-88 {
  margin-bottom: 88px;
}

.m-b-89 {
  margin-bottom: 89px;
}

.m-b-90 {
  margin-bottom: 90px;
}

.m-b-91 {
  margin-bottom: 91px;
}

.m-b-92 {
  margin-bottom: 92px;
}

.m-b-93 {
  margin-bottom: 93px;
}

.m-b-94 {
  margin-bottom: 94px;
}

.m-b-95 {
  margin-bottom: 95px;
}

.m-b-96 {
  margin-bottom: 96px;
}

.m-b-97 {
  margin-bottom: 97px;
}

.m-b-98 {
  margin-bottom: 98px;
}

.m-b-99 {
  margin-bottom: 99px;
}

.m-b-100 {
  margin-bottom: 100px;
}

/*----margin left------------------*/
.m-l-0 {
  margin-left: 0px;
}

.m-l-1 {
  margin-left: 1px;
}

.m-l-2 {
  margin-left: 2px;
}

.m-l-3 {
  margin-left: 3px;
}

.m-l-4 {
  margin-left: 4px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-6 {
  margin-left: 6px;
}

.m-l-7 {
  margin-left: 7px;
}

.m-l-8 {
  margin-left: 8px;
}

.m-l-9 {
  margin-left: 9px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-11 {
  margin-left: 11px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-13 {
  margin-left: 13px;
}

.m-l-14 {
  margin-left: 14px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-l-16 {
  margin-left: 16px;
}

.m-l-17 {
  margin-left: 17px;
}

.m-l-18 {
  margin-left: 18px;
}

.m-l-19 {
  margin-left: 19px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-21 {
  margin-left: 21px;
}

.m-l-22 {
  margin-left: 22px;
}

.m-l-23 {
  margin-left: 23px;
}

.m-l-24 {
  margin-left: 24px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-l-26 {
  margin-left: 26px;
}

.m-l-27 {
  margin-left: 27px;
}

.m-l-28 {
  margin-left: 28px;
}

.m-l-29 {
  margin-left: 29px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-l-31 {
  margin-left: 31px;
}

.m-l-32 {
  margin-left: 32px;
}

.m-l-33 {
  margin-left: 33px;
}

.m-l-34 {
  margin-left: 34px;
}

.m-l-35 {
  margin-left: 35px;
}

.m-l-36 {
  margin-left: 36px;
}

.m-l-37 {
  margin-left: 37px;
}

.m-l-38 {
  margin-left: 38px;
}

.m-l-39 {
  margin-left: 39px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-l-41 {
  margin-left: 41px;
}

.m-l-42 {
  margin-left: 42px;
}

.m-l-43 {
  margin-left: 43px;
}

.m-l-44 {
  margin-left: 44px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-l-46 {
  margin-left: 46px;
}

.m-l-47 {
  margin-left: 47px;
}

.m-l-48 {
  margin-left: 48px;
}

.m-l-49 {
  margin-left: 49px;
}

.m-l-50 {
  margin-left: 50px;
}

.m-l-51 {
  margin-left: 51px;
}

.m-l-52 {
  margin-left: 52px;
}

.m-l-53 {
  margin-left: 53px;
}

.m-l-54 {
  margin-left: 54px;
}

.m-l-55 {
  margin-left: 55px;
}

.m-l-56 {
  margin-left: 56px;
}

.m-l-57 {
  margin-left: 57px;
}

.m-l-58 {
  margin-left: 58px;
}

.m-l-59 {
  margin-left: 59px;
}

.m-l-60 {
  margin-left: 60px;
}

.m-l-61 {
  margin-left: 61px;
}

.m-l-62 {
  margin-left: 62px;
}

.m-l-63 {
  margin-left: 63px;
}

.m-l-64 {
  margin-left: 64px;
}

.m-l-65 {
  margin-left: 65px;
}

.m-l-66 {
  margin-left: 66px;
}

.m-l-67 {
  margin-left: 67px;
}

.m-l-68 {
  margin-left: 68px;
}

.m-l-69 {
  margin-left: 69px;
}

.m-l-70 {
  margin-left: 70px;
}

.m-l-71 {
  margin-left: 71px;
}

.m-l-72 {
  margin-left: 72px;
}

.m-l-73 {
  margin-left: 73px;
}

.m-l-74 {
  margin-left: 74px;
}

.m-l-75 {
  margin-left: 75px;
}

.m-l-76 {
  margin-left: 76px;
}

.m-l-77 {
  margin-left: 77px;
}

.m-l-78 {
  margin-left: 78px;
}

.m-l-79 {
  margin-left: 79px;
}

.m-l-80 {
  margin-left: 80px;
}

.m-l-81 {
  margin-left: 81px;
}

.m-l-82 {
  margin-left: 82px;
}

.m-l-83 {
  margin-left: 83px;
}

.m-l-84 {
  margin-left: 84px;
}

.m-l-85 {
  margin-left: 85px;
}

.m-l-86 {
  margin-left: 86px;
}

.m-l-87 {
  margin-left: 87px;
}

.m-l-88 {
  margin-left: 88px;
}

.m-l-89 {
  margin-left: 89px;
}

.m-l-90 {
  margin-left: 90px;
}

.m-l-91 {
  margin-left: 91px;
}

.m-l-92 {
  margin-left: 92px;
}

.m-l-93 {
  margin-left: 93px;
}

.m-l-94 {
  margin-left: 94px;
}

.m-l-95 {
  margin-left: 95px;
}

.m-l-96 {
  margin-left: 96px;
}

.m-l-97 {
  margin-left: 97px;
}

.m-l-98 {
  margin-left: 98px;
}

.m-l-99 {
  margin-left: 99px;
}

.m-l-100 {
  margin-left: 100px;
}

/*----margin Right------------------*/
.m-r-0 {
  margin-right: 0px;
}

.m-r-1 {
  margin-right: 1px;
}

.m-r-2 {
  margin-right: 2px;
}

.m-r-3 {
  margin-right: 3px;
}

.m-r-4 {
  margin-right: 4px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-6 {
  margin-right: 6px;
}

.m-r-7 {
  margin-right: 7px;
}

.m-r-8 {
  margin-right: 8px;
}

.m-r-9 {
  margin-right: 9px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-11 {
  margin-right: 11px;
}

.m-r-12 {
  margin-right: 12px;
}

.m-r-13 {
  margin-right: 13px;
}

.m-r-14 {
  margin-right: 14px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-16 {
  margin-right: 16px;
}

.m-r-17 {
  margin-right: 17px;
}

.m-r-18 {
  margin-right: 18px;
}

.m-r-19 {
  margin-right: 19px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-21 {
  margin-right: 21px;
}

.m-r-22 {
  margin-right: 22px;
}

.m-r-23 {
  margin-right: 23px;
}

.m-r-24 {
  margin-right: 24px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-26 {
  margin-right: 26px;
}

.m-r-27 {
  margin-right: 27px;
}

.m-r-28 {
  margin-right: 28px;
}

.m-r-29 {
  margin-right: 29px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-31 {
  margin-right: 31px;
}

.m-r-32 {
  margin-right: 32px;
}

.m-r-33 {
  margin-right: 33px;
}

.m-r-34 {
  margin-right: 34px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-36 {
  margin-right: 36px;
}

.m-r-37 {
  margin-right: 37px;
}

.m-r-38 {
  margin-right: 38px;
}

.m-r-39 {
  margin-right: 39px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-41 {
  margin-right: 41px;
}

.m-r-42 {
  margin-right: 42px;
}

.m-r-43 {
  margin-right: 43px;
}

.m-r-44 {
  margin-right: 44px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-r-46 {
  margin-right: 46px;
}

.m-r-47 {
  margin-right: 47px;
}

.m-r-48 {
  margin-right: 48px;
}

.m-r-49 {
  margin-right: 49px;
}

.m-r-50 {
  margin-right: 50px;
}

.m-r-51 {
  margin-right: 51px;
}

.m-r-52 {
  margin-right: 52px;
}

.m-r-53 {
  margin-right: 53px;
}

.m-r-54 {
  margin-right: 54px;
}

.m-r-55 {
  margin-right: 55px;
}

.m-r-56 {
  margin-right: 56px;
}

.m-r-57 {
  margin-right: 57px;
}

.m-r-58 {
  margin-right: 58px;
}

.m-r-59 {
  margin-right: 59px;
}

.m-r-60 {
  margin-right: 60px;
}

.m-r-61 {
  margin-right: 61px;
}

.m-r-62 {
  margin-right: 62px;
}

.m-r-63 {
  margin-right: 63px;
}

.m-r-64 {
  margin-right: 64px;
}

.m-r-65 {
  margin-right: 65px;
}

.m-r-66 {
  margin-right: 66px;
}

.m-r-67 {
  margin-right: 67px;
}

.m-r-68 {
  margin-right: 68px;
}

.m-r-69 {
  margin-right: 69px;
}

.m-r-70 {
  margin-right: 70px;
}

.m-r-71 {
  margin-right: 71px;
}

.m-r-72 {
  margin-right: 72px;
}

.m-r-73 {
  margin-right: 73px;
}

.m-r-74 {
  margin-right: 74px;
}

.m-r-75 {
  margin-right: 75px;
}

.m-r-76 {
  margin-right: 76px;
}

.m-r-77 {
  margin-right: 77px;
}

.m-r-78 {
  margin-right: 78px;
}

.m-r-79 {
  margin-right: 79px;
}

.m-r-80 {
  margin-right: 80px;
}

.m-r-81 {
  margin-right: 81px;
}

.m-r-82 {
  margin-right: 82px;
}

.m-r-83 {
  margin-right: 83px;
}

.m-r-84 {
  margin-right: 84px;
}

.m-r-85 {
  margin-right: 85px;
}

.m-r-86 {
  margin-right: 86px;
}

.m-r-87 {
  margin-right: 87px;
}

.m-r-88 {
  margin-right: 88px;
}

.m-r-89 {
  margin-right: 89px;
}

.m-r-90 {
  margin-right: 90px;
}

.m-r-91 {
  margin-right: 91px;
}

.m-r-92 {
  margin-right: 92px;
}

.m-r-93 {
  margin-right: 93px;
}

.m-r-94 {
  margin-right: 94px;
}

.m-r-95 {
  margin-right: 95px;
}

.m-r-96 {
  margin-right: 96px;
}

.m-r-97 {
  margin-right: 97px;
}

.m-r-98 {
  margin-right: 98px;
}

.m-r-99 {
  margin-right: 99px;
}

.m-r-100 {
  margin-right: 100px;
}

/*----Padding Top------------------*/
.p-t-0 {
  padding-top: 0px;
}

.p-t-1 {
  padding-top: 1px;
}

.p-t-2 {
  padding-top: 2px;
}

.p-t-3 {
  padding-top: 3px;
}

.p-t-4 {
  padding-top: 4px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-6 {
  padding-top: 6px;
}

.p-t-7 {
  padding-top: 7px;
}

.p-t-8 {
  padding-top: 8px;
}

.p-t-9 {
  padding-top: 9px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-11 {
  padding-top: 11px;
}

.p-t-12 {
  padding-top: 12px;
}

.p-t-13 {
  padding-top: 13px;
}

.p-t-14 {
  padding-top: 14px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-16 {
  padding-top: 16px;
}

.p-t-17 {
  padding-top: 17px;
}

.p-t-18 {
  padding-top: 18px;
}

.p-t-19 {
  padding-top: 19px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-21 {
  padding-top: 21px;
}

.p-t-22 {
  padding-top: 22px;
}

.p-t-23 {
  padding-top: 23px;
}

.p-t-24 {
  padding-top: 24px;
}

.p-t-25 {
  padding-top: 25px;
}

.p-t-26 {
  padding-top: 26px;
}

.p-t-27 {
  padding-top: 27px;
}

.p-t-28 {
  padding-top: 28px;
}

.p-t-29 {
  padding-top: 29px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-t-31 {
  padding-top: 31px;
}

.p-t-32 {
  padding-top: 32px;
}

.p-t-33 {
  padding-top: 33px;
}

.p-t-34 {
  padding-top: 34px;
}

.p-t-35 {
  padding-top: 35px;
}

.p-t-36 {
  padding-top: 36px;
}

.p-t-37 {
  padding-top: 37px;
}

.p-t-38 {
  padding-top: 38px;
}

.p-t-39 {
  padding-top: 39px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-t-41 {
  padding-top: 41px;
}

.p-t-42 {
  padding-top: 42px;
}

.p-t-43 {
  padding-top: 43px;
}

.p-t-44 {
  padding-top: 44px;
}

.p-t-45 {
  padding-top: 45px;
}

.p-t-46 {
  padding-top: 46px;
}

.p-t-47 {
  padding-top: 47px;
}

.p-t-48 {
  padding-top: 48px;
}

.p-t-49 {
  padding-top: 49px;
}

.p-t-50 {
  padding-top: 50px;
}

.p-t-51 {
  padding-top: 51px;
}

.p-t-52 {
  padding-top: 52px;
}

.p-t-53 {
  padding-top: 53px;
}

.p-t-54 {
  padding-top: 54px;
}

.p-t-55 {
  padding-top: 55px;
}

.p-t-56 {
  padding-top: 56px;
}

.p-t-57 {
  padding-top: 57px;
}

.p-t-58 {
  padding-top: 58px;
}

.p-t-59 {
  padding-top: 59px;
}

.p-t-60 {
  padding-top: 60px;
}

.p-t-61 {
  padding-top: 61px;
}

.p-t-62 {
  padding-top: 62px;
}

.p-t-63 {
  padding-top: 63px;
}

.p-t-64 {
  padding-top: 64px;
}

.p-t-65 {
  padding-top: 65px;
}

.p-t-66 {
  padding-top: 66px;
}

.p-t-67 {
  padding-top: 67px;
}

.p-t-68 {
  padding-top: 68px;
}

.p-t-69 {
  padding-top: 69px;
}

.p-t-70 {
  padding-top: 70px;
}

.p-t-71 {
  padding-top: 71px;
}

.p-t-72 {
  padding-top: 72px;
}

.p-t-73 {
  padding-top: 73px;
}

.p-t-74 {
  padding-top: 74px;
}

.p-t-75 {
  padding-top: 75px;
}

.p-t-76 {
  padding-top: 76px;
}

.p-t-77 {
  padding-top: 77px;
}

.p-t-78 {
  padding-top: 78px;
}

.p-t-79 {
  padding-top: 79px;
}

.p-t-80 {
  padding-top: 80px;
}

.p-t-81 {
  padding-top: 81px;
}

.p-t-82 {
  padding-top: 82px;
}

.p-t-83 {
  padding-top: 83px;
}

.p-t-84 {
  padding-top: 84px;
}

.p-t-85 {
  padding-top: 85px;
}

.p-t-86 {
  padding-top: 86px;
}

.p-t-87 {
  padding-top: 87px;
}

.p-t-88 {
  padding-top: 88px;
}

.p-t-89 {
  padding-top: 89px;
}

.p-t-90 {
  padding-top: 90px;
}

.p-t-91 {
  padding-top: 91px;
}

.p-t-92 {
  padding-top: 92px;
}

.p-t-93 {
  padding-top: 93px;
}

.p-t-94 {
  padding-top: 94px;
}

.p-t-95 {
  padding-top: 95px;
}

.p-t-96 {
  padding-top: 96px;
}

.p-t-97 {
  padding-top: 97px;
}

.p-t-98 {
  padding-top: 98px;
}

.p-t-99 {
  padding-top: 99px;
}

.p-t-100 {
  padding-top: 100px;
}

/*----Padding Bottom------------------*/
.p-b-1 {
  padding-bottom: 1px;
}

.p-b-2 {
  padding-bottom: 2px;
}

.p-b-3 {
  padding-bottom: 3px;
}

.p-b-4 {
  padding-bottom: 4px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-b-6 {
  padding-bottom: 6px;
}

.p-b-7 {
  padding-bottom: 7px;
}

.p-b-8 {
  padding-bottom: 8px;
}

.p-b-9 {
  padding-bottom: 9px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-b-11 {
  padding-bottom: 11px;
}

.p-b-12 {
  padding-bottom: 12px;
}

.p-b-13 {
  padding-bottom: 13px;
}

.p-b-14 {
  padding-bottom: 14px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-b-16 {
  padding-bottom: 16px;
}

.p-b-17 {
  padding-bottom: 17px;
}

.p-b-18 {
  padding-bottom: 18px;
}

.p-b-19 {
  padding-bottom: 19px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-21 {
  padding-bottom: 21px;
}

.p-b-22 {
  padding-bottom: 22px;
}

.p-b-23 {
  padding-bottom: 23px;
}

.p-b-24 {
  padding-bottom: 24px;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-b-26 {
  padding-bottom: 26px;
}

.p-b-27 {
  padding-bottom: 27px;
}

.p-b-28 {
  padding-bottom: 28px;
}

.p-b-29 {
  padding-bottom: 29px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-b-31 {
  padding-bottom: 31px;
}

.p-b-32 {
  padding-bottom: 32px;
}

.p-b-33 {
  padding-bottom: 33px;
}

.p-b-34 {
  padding-bottom: 34px;
}

.p-b-35 {
  padding-bottom: 35px;
}

.p-b-36 {
  padding-bottom: 36px;
}

.p-b-37 {
  padding-bottom: 37px;
}

.p-b-38 {
  padding-bottom: 38px;
}

.p-b-39 {
  padding-bottom: 39px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-b-41 {
  padding-bottom: 41px;
}

.p-b-42 {
  padding-bottom: 42px;
}

.p-b-43 {
  padding-bottom: 43px;
}

.p-b-44 {
  padding-bottom: 44px;
}

.p-b-45 {
  padding-bottom: 45px;
}

.p-b-46 {
  padding-bottom: 46px;
}

.p-b-47 {
  padding-bottom: 47px;
}

.p-b-48 {
  padding-bottom: 48px;
}

.p-b-49 {
  padding-bottom: 49px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.p-b-51 {
  padding-bottom: 51px;
}

.p-b-52 {
  padding-bottom: 52px;
}

.p-b-53 {
  padding-bottom: 53px;
}

.p-b-54 {
  padding-bottom: 54px;
}

.p-b-55 {
  padding-bottom: 55px;
}

.p-b-56 {
  padding-bottom: 56px;
}

.p-b-57 {
  padding-bottom: 57px;
}

.p-b-58 {
  padding-bottom: 58px;
}

.p-b-59 {
  padding-bottom: 59px;
}

.p-b-60 {
  padding-bottom: 60px;
}

.p-b-61 {
  padding-bottom: 61px;
}

.p-b-62 {
  padding-bottom: 62px;
}

.p-b-63 {
  padding-bottom: 63px;
}

.p-b-64 {
  padding-bottom: 64px;
}

.p-b-65 {
  padding-bottom: 65px;
}

.p-b-66 {
  padding-bottom: 66px;
}

.p-b-67 {
  padding-bottom: 67px;
}

.p-b-68 {
  padding-bottom: 68px;
}

.p-b-69 {
  padding-bottom: 69px;
}

.p-b-70 {
  padding-bottom: 70px;
}

.p-b-71 {
  padding-bottom: 71px;
}

.p-b-72 {
  padding-bottom: 72px;
}

.p-b-73 {
  padding-bottom: 73px;
}

.p-b-74 {
  padding-bottom: 74px;
}

.p-b-75 {
  padding-bottom: 75px;
}

.p-b-76 {
  padding-bottom: 76px;
}

.p-b-77 {
  padding-bottom: 77px;
}

.p-b-78 {
  padding-bottom: 78px;
}

.p-b-79 {
  padding-bottom: 79px;
}

.p-b-80 {
  padding-bottom: 80px;
}

.p-b-81 {
  padding-bottom: 81px;
}

.p-b-82 {
  padding-bottom: 82px;
}

.p-b-83 {
  padding-bottom: 83px;
}

.p-b-84 {
  padding-bottom: 84px;
}

.p-b-85 {
  padding-bottom: 85px;
}

.p-b-86 {
  padding-bottom: 86px;
}

.p-b-87 {
  padding-bottom: 87px;
}

.p-b-88 {
  padding-bottom: 88px;
}

.p-b-89 {
  padding-bottom: 89px;
}

.p-b-90 {
  padding-bottom: 90px;
}

.p-b-91 {
  padding-bottom: 91px;
}

.p-b-92 {
  padding-bottom: 92px;
}

.p-b-93 {
  padding-bottom: 93px;
}

.p-b-94 {
  padding-bottom: 94px;
}

.p-b-95 {
  padding-bottom: 95px;
}

.p-b-96 {
  padding-bottom: 96px;
}

.p-b-97 {
  padding-bottom: 97px;
}

.p-b-98 {
  padding-bottom: 98px;
}

.p-b-99 {
  padding-bottom: 99px;
}

.p-b-100 {
  padding-bottom: 100px;
}

/*----padding left------------------*/
.p-l-1 {
  padding-left: 1px;
}

.p-l-2 {
  padding-left: 2px;
}

.p-l-3 {
  padding-left: 3px;
}

.p-l-4 {
  padding-left: 4px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-6 {
  padding-left: 6px;
}

.p-l-7 {
  padding-left: 7px;
}

.p-l-8 {
  padding-left: 8px;
}

.p-l-9 {
  padding-left: 9px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-11 {
  padding-left: 11px;
}

.p-l-12 {
  padding-left: 12px;
}

.p-l-13 {
  padding-left: 13px;
}

.p-l-14 {
  padding-left: 14px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-16 {
  padding-left: 16px;
}

.p-l-17 {
  padding-left: 17px;
}

.p-l-18 {
  padding-left: 18px;
}

.p-l-19 {
  padding-left: 19px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-21 {
  padding-left: 21px;
}

.p-l-22 {
  padding-left: 22px;
}

.p-l-23 {
  padding-left: 23px;
}

.p-l-24 {
  padding-left: 24px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-26 {
  padding-left: 26px;
}

.p-l-27 {
  padding-left: 27px;
}

.p-l-28 {
  padding-left: 28px;
}

.p-l-29 {
  padding-left: 29px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-31 {
  padding-left: 31px;
}

.p-l-32 {
  padding-left: 32px;
}

.p-l-33 {
  padding-left: 33px;
}

.p-l-34 {
  padding-left: 34px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-l-36 {
  padding-left: 36px;
}

.p-l-37 {
  padding-left: 37px;
}

.p-l-38 {
  padding-left: 38px;
}

.p-l-39 {
  padding-left: 39px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-41 {
  padding-left: 41px;
}

.p-l-42 {
  padding-left: 42px;
}

.p-l-43 {
  padding-left: 43px;
}

.p-l-44 {
  padding-left: 44px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-46 {
  padding-left: 46px;
}

.p-l-47 {
  padding-left: 47px;
}

.p-l-48 {
  padding-left: 48px;
}

.p-l-49 {
  padding-left: 49px;
}

.p-l-50 {
  padding-left: 50px;
}

.p-l-51 {
  padding-left: 51px;
}

.p-l-52 {
  padding-left: 52px;
}

.p-l-53 {
  padding-left: 53px;
}

.p-l-54 {
  padding-left: 54px;
}

.p-l-55 {
  padding-left: 55px;
}

.p-l-56 {
  padding-left: 56px;
}

.p-l-57 {
  padding-left: 57px;
}

.p-l-58 {
  padding-left: 58px;
}

.p-l-59 {
  padding-left: 59px;
}

.p-l-60 {
  padding-left: 60px;
}

.p-l-61 {
  padding-left: 61px;
}

.p-l-62 {
  padding-left: 62px;
}

.p-l-63 {
  padding-left: 63px;
}

.p-l-64 {
  padding-left: 64px;
}

.p-l-65 {
  padding-left: 65px;
}

.p-l-66 {
  padding-left: 66px;
}

.p-l-67 {
  padding-left: 67px;
}

.p-l-68 {
  padding-left: 68px;
}

.p-l-69 {
  padding-left: 69px;
}

.p-l-70 {
  padding-left: 70px;
}

.p-l-71 {
  padding-left: 71px;
}

.p-l-72 {
  padding-left: 72px;
}

.p-l-73 {
  padding-left: 73px;
}

.p-l-74 {
  padding-left: 74px;
}

.p-l-75 {
  padding-left: 75px;
}

.p-l-76 {
  padding-left: 76px;
}

.p-l-77 {
  padding-left: 77px;
}

.p-l-78 {
  padding-left: 78px;
}

.p-l-79 {
  padding-left: 79px;
}

.p-l-80 {
  padding-left: 80px;
}

.p-l-81 {
  padding-left: 81px;
}

.p-l-82 {
  padding-left: 82px;
}

.p-l-83 {
  padding-left: 83px;
}

.p-l-84 {
  padding-left: 84px;
}

.p-l-85 {
  padding-left: 85px;
}

.p-l-86 {
  padding-left: 86px;
}

.p-l-87 {
  padding-left: 87px;
}

.p-l-88 {
  padding-left: 88px;
}

.p-l-89 {
  padding-left: 89px;
}

.p-l-90 {
  padding-left: 90px;
}

.p-l-91 {
  padding-left: 91px;
}

.p-l-92 {
  padding-left: 92px;
}

.p-l-93 {
  padding-left: 93px;
}

.p-l-94 {
  padding-left: 94px;
}

.p-l-95 {
  padding-left: 95px;
}

.p-l-96 {
  padding-left: 96px;
}

.p-l-97 {
  padding-left: 97px;
}

.p-l-98 {
  padding-left: 98px;
}

.p-l-99 {
  padding-left: 99px;
}

.p-l-100 {
  padding-left: 100px;
}

/*----paddingn Right------------------*/
.p-r-1 {
  padding-right: 1px;
}

.p-r-2 {
  padding-right: 2px;
}

.p-r-3 {
  padding-right: 3px;
}

.p-r-4 {
  padding-right: 4px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-6 {
  padding-right: 6px;
}

.p-r-7 {
  padding-right: 7px;
}

.p-r-8 {
  padding-right: 8px;
}

.p-r-9 {
  padding-right: 9px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-11 {
  padding-right: 11px;
}

.p-r-12 {
  padding-right: 12px;
}

.p-r-13 {
  padding-right: 13px;
}

.p-r-14 {
  padding-right: 14px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-16 {
  padding-right: 16px;
}

.p-r-17 {
  padding-right: 17px;
}

.p-r-18 {
  padding-right: 18px;
}

.p-r-19 {
  padding-right: 19px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-21 {
  padding-right: 21px;
}

.p-r-22 {
  padding-right: 22px;
}

.p-r-23 {
  padding-right: 23px;
}

.p-r-24 {
  padding-right: 24px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-26 {
  padding-right: 26px;
}

.p-r-27 {
  padding-right: 27px;
}

.p-r-28 {
  padding-right: 28px;
}

.p-r-29 {
  padding-right: 29px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-31 {
  padding-right: 31px;
}

.p-r-32 {
  padding-right: 32px;
}

.p-r-33 {
  padding-right: 33px;
}

.p-r-34 {
  padding-right: 34px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-r-36 {
  padding-right: 36px;
}

.p-r-37 {
  padding-right: 37px;
}

.p-r-38 {
  padding-right: 38px;
}

.p-r-39 {
  padding-right: 39px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-41 {
  padding-right: 41px;
}

.p-r-42 {
  padding-right: 42px;
}

.p-r-43 {
  padding-right: 43px;
}

.p-r-44 {
  padding-right: 44px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-r-46 {
  padding-right: 46px;
}

.p-r-47 {
  padding-right: 47px;
}

.p-r-48 {
  padding-right: 48px;
}

.p-r-49 {
  padding-right: 49px;
}

.p-r-50 {
  padding-right: 50px;
}

.p-r-51 {
  padding-right: 51px;
}

.p-r-52 {
  padding-right: 52px;
}

.p-r-53 {
  padding-right: 53px;
}

.p-r-54 {
  padding-right: 54px;
}

.p-r-55 {
  padding-right: 55px;
}

.p-r-56 {
  padding-right: 56px;
}

.p-r-57 {
  padding-right: 57px;
}

.p-r-58 {
  padding-right: 58px;
}

.p-r-59 {
  padding-right: 59px;
}

.p-r-60 {
  padding-right: 60px;
}

.p-r-61 {
  padding-right: 61px;
}

.p-r-62 {
  padding-right: 62px;
}

.p-r-63 {
  padding-right: 63px;
}

.p-r-64 {
  padding-right: 64px;
}

.p-r-65 {
  padding-right: 65px;
}

.p-r-66 {
  padding-right: 66px;
}

.p-r-67 {
  padding-right: 67px;
}

.p-r-68 {
  padding-right: 68px;
}

.p-r-69 {
  padding-right: 69px;
}

.p-r-70 {
  padding-right: 70px;
}

.p-r-71 {
  padding-right: 71px;
}

.p-r-72 {
  padding-right: 72px;
}

.p-r-73 {
  padding-right: 73px;
}

.p-r-74 {
  padding-right: 74px;
}

.p-r-75 {
  padding-right: 75px;
}

.p-r-76 {
  padding-right: 76px;
}

.p-r-77 {
  padding-right: 77px;
}

.p-r-78 {
  padding-right: 78px;
}

.p-r-79 {
  padding-right: 79px;
}

.p-r-80 {
  padding-right: 80px;
}

.p-r-81 {
  padding-right: 81px;
}

.p-r-82 {
  padding-right: 82px;
}

.p-r-83 {
  padding-right: 83px;
}

.p-r-84 {
  padding-right: 84px;
}

.p-r-85 {
  padding-right: 85px;
}

.p-r-86 {
  padding-right: 86px;
}

.p-r-87 {
  padding-right: 87px;
}

.p-r-88 {
  padding-right: 88px;
}

.p-r-89 {
  padding-right: 89px;
}

.p-r-90 {
  padding-right: 90px;
}

.p-r-91 {
  padding-right: 91px;
}

.p-r-92 {
  padding-right: 92px;
}

.p-r-93 {
  padding-right: 93px;
}

.p-r-94 {
  padding-right: 94px;
}

.p-r-95 {
  padding-right: 95px;
}

.p-r-96 {
  padding-right: 96px;
}

.p-r-97 {
  padding-right: 97px;
}

.p-r-98 {
  padding-right: 98px;
}

.p-r-99 {
  padding-right: 99px;
}

.p-r-100 {
  padding-right: 100px;
}

/*----min Height------------------*/
.m-h-1 {
  min-height: 1px;
}

.m-h-2 {
  min-height: 2px;
}

.m-h-3 {
  min-height: 3px;
}

.m-h-4 {
  min-height: 4px;
}

.m-h-5 {
  min-height: 5px;
}

.m-h-6 {
  min-height: 6px;
}

.m-h-7 {
  min-height: 7px;
}

.m-h-8 {
  min-height: 8px;
}

.m-h-9 {
  min-height: 9px;
}

.m-h-10 {
  min-height: 10px;
}

.m-h-11 {
  min-height: 11px;
}

.m-h-12 {
  min-height: 12px;
}

.m-h-13 {
  min-height: 13px;
}

.m-h-14 {
  min-height: 14px;
}

.m-h-15 {
  min-height: 15px;
}

.m-h-16 {
  min-height: 16px;
}

.m-h-17 {
  min-height: 17px;
}

.m-h-18 {
  min-height: 18px;
}

.m-h-19 {
  min-height: 19px;
}

.m-h-20 {
  min-height: 20px;
}

.m-h-21 {
  min-height: 21px;
}

.m-h-22 {
  min-height: 22px;
}

.m-h-23 {
  min-height: 23px;
}

.m-h-24 {
  min-height: 24px;
}

.m-h-25 {
  min-height: 25px;
}

.m-h-26 {
  min-height: 26px;
}

.m-h-27 {
  min-height: 27px;
}

.m-h-28 {
  min-height: 28px;
}

.m-h-29 {
  min-height: 29px;
}

.m-h-30 {
  min-height: 30px;
}

.m-h-31 {
  min-height: 31px;
}

.m-h-32 {
  min-height: 32px;
}

.m-h-33 {
  min-height: 33px;
}

.m-h-34 {
  min-height: 34px;
}

.m-h-35 {
  min-height: 35px;
}

.m-h-36 {
  min-height: 36px;
}

.m-h-37 {
  min-height: 37px;
}

.m-h-38 {
  min-height: 38px;
}

.m-h-39 {
  min-height: 39px;
}

.m-h-40 {
  min-height: 40px;
}

.m-h-41 {
  min-height: 41px;
}

.m-h-42 {
  min-height: 42px;
}

.m-h-43 {
  min-height: 43px;
}

.m-h-44 {
  min-height: 44px;
}

.m-h-45 {
  min-height: 45px;
}

.m-h-46 {
  min-height: 46px;
}

.m-h-47 {
  min-height: 47px;
}

.m-h-48 {
  min-height: 48px;
}

.m-h-49 {
  min-height: 49px;
}

.m-h-50 {
  min-height: 50px;
}

.m-h-51 {
  min-height: 51px;
}

.m-h-52 {
  min-height: 52px;
}

.m-h-53 {
  min-height: 53px;
}

.m-h-54 {
  min-height: 54px;
}

.m-h-55 {
  min-height: 55px;
}

.m-h-56 {
  min-height: 56px;
}

.m-h-57 {
  min-height: 57px;
}

.m-h-58 {
  min-height: 58px;
}

.m-h-59 {
  min-height: 59px;
}

.m-h-60 {
  min-height: 60px;
}

.m-h-61 {
  min-height: 61px;
}

.m-h-62 {
  min-height: 62px;
}

.m-h-63 {
  min-height: 63px;
}

.m-h-64 {
  min-height: 64px;
}

.m-h-65 {
  min-height: 65px;
}

.m-h-66 {
  min-height: 66px;
}

.m-h-67 {
  min-height: 67px;
}

.m-h-68 {
  min-height: 68px;
}

.m-h-69 {
  min-height: 69px;
}

.m-h-70 {
  min-height: 70px;
}

.m-h-71 {
  min-height: 71px;
}

.m-h-72 {
  min-height: 72px;
}

.m-h-73 {
  min-height: 73px;
}

.m-h-74 {
  min-height: 74px;
}

.m-h-75 {
  min-height: 75px;
}

.m-h-76 {
  min-height: 76px;
}

.m-h-77 {
  min-height: 77px;
}

.m-h-78 {
  min-height: 78px;
}

.m-h-79 {
  min-height: 79px;
}

.m-h-80 {
  min-height: 80px;
}

.m-h-81 {
  min-height: 81px;
}

.m-h-82 {
  min-height: 82px;
}

.m-h-83 {
  min-height: 83px;
}

.m-h-84 {
  min-height: 84px;
}

.m-h-85 {
  min-height: 85px;
}

.m-h-86 {
  min-height: 86px;
}

.m-h-87 {
  min-height: 87px;
}

.m-h-88 {
  min-height: 88px;
}

.m-h-89 {
  min-height: 89px;
}

.m-h-90 {
  min-height: 90px;
}

.m-h-91 {
  min-height: 91px;
}

.m-h-92 {
  min-height: 92px;
}

.m-h-93 {
  min-height: 93px;
}

.m-h-94 {
  min-height: 94px;
}

.m-h-95 {
  min-height: 95px;
}

.m-h-96 {
  min-height: 96px;
}

.m-h-97 {
  min-height: 97px;
}

.m-h-98 {
  min-height: 98px;
}

.m-h-99 {
  min-height: 99px;
}

.m-h-100 {
  min-height: 100px;
}

.m-h-101 {
  min-height: 101px;
}

.m-h-102 {
  min-height: 102px;
}

.m-h-103 {
  min-height: 103px;
}

.m-h-104 {
  min-height: 104px;
}

.m-h-105 {
  min-height: 105px;
}

.m-h-106 {
  min-height: 106px;
}

.m-h-107 {
  min-height: 107px;
}

.m-h-108 {
  min-height: 108px;
}

.m-h-109 {
  min-height: 109px;
}

.m-h-110 {
  min-height: 110px;
}

.m-h-111 {
  min-height: 111px;
}

.m-h-112 {
  min-height: 112px;
}

.m-h-113 {
  min-height: 113px;
}

.m-h-114 {
  min-height: 114px;
}

.m-h-115 {
  min-height: 115px;
}

.m-h-116 {
  min-height: 116px;
}

.m-h-117 {
  min-height: 117px;
}

.m-h-118 {
  min-height: 118px;
}

.m-h-119 {
  min-height: 119px;
}

.m-h-120 {
  min-height: 120px;
}

.m-h-121 {
  min-height: 121px;
}

.m-h-122 {
  min-height: 122px;
}

.m-h-123 {
  min-height: 123px;
}

.m-h-124 {
  min-height: 124px;
}

.m-h-125 {
  min-height: 125px;
}

.m-h-126 {
  min-height: 126px;
}

.m-h-127 {
  min-height: 127px;
}

.m-h-128 {
  min-height: 128px;
}

.m-h-129 {
  min-height: 129px;
}

.m-h-130 {
  min-height: 130px;
}

.m-h-131 {
  min-height: 131px;
}

.m-h-132 {
  min-height: 132px;
}

.m-h-133 {
  min-height: 133px;
}

.m-h-134 {
  min-height: 134px;
}

.m-h-135 {
  min-height: 135px;
}

.m-h-136 {
  min-height: 136px;
}

.m-h-137 {
  min-height: 137px;
}

.m-h-138 {
  min-height: 138px;
}

.m-h-139 {
  min-height: 139px;
}

.m-h-140 {
  min-height: 140px;
}

.m-h-141 {
  min-height: 141px;
}

.m-h-142 {
  min-height: 142px;
}

.m-h-143 {
  min-height: 143px;
}

.m-h-144 {
  min-height: 144px;
}

.m-h-145 {
  min-height: 145px;
}

.m-h-146 {
  min-height: 146px;
}

.m-h-147 {
  min-height: 147px;
}

.m-h-148 {
  min-height: 148px;
}

.m-h-149 {
  min-height: 149px;
}

.m-h-150 {
  min-height: 150px;
}

.m-h-151 {
  min-height: 151px;
}

.m-h-152 {
  min-height: 152px;
}

.m-h-153 {
  min-height: 153px;
}

.m-h-154 {
  min-height: 154px;
}

.m-h-155 {
  min-height: 155px;
}

.m-h-156 {
  min-height: 156px;
}

.m-h-157 {
  min-height: 157px;
}

.m-h-158 {
  min-height: 158px;
}

.m-h-159 {
  min-height: 159px;
}

.m-h-160 {
  min-height: 160px;
}

.m-h-161 {
  min-height: 161px;
}

.m-h-162 {
  min-height: 162px;
}

.m-h-163 {
  min-height: 163px;
}

.m-h-164 {
  min-height: 164px;
}

.m-h-165 {
  min-height: 165px;
}

.m-h-166 {
  min-height: 166px;
}

.m-h-167 {
  min-height: 167px;
}

.m-h-168 {
  min-height: 168px;
}

.m-h-169 {
  min-height: 169px;
}

.m-h-170 {
  min-height: 170px;
}

.m-h-171 {
  min-height: 171px;
}

.m-h-172 {
  min-height: 172px;
}

.m-h-173 {
  min-height: 173px;
}

.m-h-174 {
  min-height: 174px;
}

.m-h-175 {
  min-height: 175px;
}

.m-h-176 {
  min-height: 176px;
}

.m-h-177 {
  min-height: 177px;
}

.m-h-178 {
  min-height: 178px;
}

.m-h-179 {
  min-height: 179px;
}

.m-h-180 {
  min-height: 180px;
}

.m-h-181 {
  min-height: 181px;
}

.m-h-182 {
  min-height: 182px;
}

.m-h-183 {
  min-height: 183px;
}

.m-h-184 {
  min-height: 184px;
}

.m-h-185 {
  min-height: 185px;
}

.m-h-186 {
  min-height: 186px;
}

.m-h-187 {
  min-height: 187px;
}

.m-h-188 {
  min-height: 188px;
}

.m-h-189 {
  min-height: 189px;
}

.m-h-190 {
  min-height: 190px;
}

.m-h-191 {
  min-height: 191px;
}

.m-h-192 {
  min-height: 192px;
}

.m-h-193 {
  min-height: 193px;
}

.m-h-194 {
  min-height: 194px;
}

.m-h-195 {
  min-height: 195px;
}

.m-h-196 {
  min-height: 196px;
}

.m-h-197 {
  min-height: 197px;
}

.m-h-198 {
  min-height: 198px;
}

.m-h-199 {
  min-height: 199px;
}

.m-h-200 {
  min-height: 200px;
}

.m-h-201 {
  min-height: 201px;
}

.m-h-202 {
  min-height: 202px;
}

.m-h-203 {
  min-height: 203px;
}

.m-h-204 {
  min-height: 204px;
}

.m-h-205 {
  min-height: 205px;
}

.m-h-206 {
  min-height: 206px;
}

.m-h-207 {
  min-height: 207px;
}

.m-h-208 {
  min-height: 208px;
}

.m-h-209 {
  min-height: 209px;
}

.m-h-210 {
  min-height: 210px;
}

.m-h-211 {
  min-height: 211px;
}

.m-h-212 {
  min-height: 212px;
}

.m-h-213 {
  min-height: 213px;
}

.m-h-214 {
  min-height: 214px;
}

.m-h-215 {
  min-height: 215px;
}

.m-h-216 {
  min-height: 216px;
}

.m-h-217 {
  min-height: 217px;
}

.m-h-218 {
  min-height: 218px;
}

.m-h-219 {
  min-height: 219px;
}

.m-h-220 {
  min-height: 220px;
}

.m-h-221 {
  min-height: 221px;
}

.m-h-222 {
  min-height: 222px;
}

.m-h-223 {
  min-height: 223px;
}

.m-h-224 {
  min-height: 224px;
}

.m-h-225 {
  min-height: 225px;
}

.m-h-226 {
  min-height: 226px;
}

.m-h-227 {
  min-height: 227px;
}

.m-h-228 {
  min-height: 228px;
}

.m-h-229 {
  min-height: 229px;
}

.m-h-230 {
  min-height: 230px;
}

.m-h-231 {
  min-height: 231px;
}

.m-h-232 {
  min-height: 232px;
}

.m-h-233 {
  min-height: 233px;
}

.m-h-234 {
  min-height: 234px;
}

.m-h-235 {
  min-height: 235px;
}

.m-h-236 {
  min-height: 236px;
}

.m-h-237 {
  min-height: 237px;
}

.m-h-238 {
  min-height: 238px;
}

.m-h-239 {
  min-height: 239px;
}

.m-h-240 {
  min-height: 240px;
}

.m-h-241 {
  min-height: 241px;
}

.m-h-242 {
  min-height: 242px;
}

.m-h-243 {
  min-height: 243px;
}

.m-h-244 {
  min-height: 244px;
}

.m-h-245 {
  min-height: 245px;
}

.m-h-246 {
  min-height: 246px;
}

.m-h-247 {
  min-height: 247px;
}

.m-h-248 {
  min-height: 248px;
}

.m-h-249 {
  min-height: 249px;
}

.m-h-250 {
  min-height: 250px;
}

.m-h-251 {
  min-height: 251px;
}

.m-h-252 {
  min-height: 252px;
}

.m-h-253 {
  min-height: 253px;
}

.m-h-254 {
  min-height: 254px;
}

.m-h-255 {
  min-height: 255px;
}

.m-h-256 {
  min-height: 256px;
}

.m-h-257 {
  min-height: 257px;
}

.m-h-258 {
  min-height: 258px;
}

.m-h-259 {
  min-height: 259px;
}

.m-h-260 {
  min-height: 260px;
}

.m-h-261 {
  min-height: 261px;
}

.m-h-262 {
  min-height: 262px;
}

.m-h-263 {
  min-height: 263px;
}

.m-h-264 {
  min-height: 264px;
}

.m-h-265 {
  min-height: 265px;
}

.m-h-266 {
  min-height: 266px;
}

.m-h-267 {
  min-height: 267px;
}

.m-h-268 {
  min-height: 268px;
}

.m-h-269 {
  min-height: 269px;
}

.m-h-270 {
  min-height: 270px;
}

.m-h-271 {
  min-height: 271px;
}

.m-h-272 {
  min-height: 272px;
}

.m-h-273 {
  min-height: 273px;
}

.m-h-274 {
  min-height: 274px;
}

.m-h-275 {
  min-height: 275px;
}

.m-h-276 {
  min-height: 276px;
}

.m-h-277 {
  min-height: 277px;
}

.m-h-278 {
  min-height: 278px;
}

.m-h-279 {
  min-height: 279px;
}

.m-h-280 {
  min-height: 280px;
}

.m-h-281 {
  min-height: 281px;
}

.m-h-282 {
  min-height: 282px;
}

.m-h-283 {
  min-height: 283px;
}

.m-h-284 {
  min-height: 284px;
}

.m-h-285 {
  min-height: 285px;
}

.m-h-286 {
  min-height: 286px;
}

.m-h-287 {
  min-height: 287px;
}

.m-h-288 {
  min-height: 288px;
}

.m-h-289 {
  min-height: 289px;
}

.m-h-290 {
  min-height: 290px;
}

.m-h-291 {
  min-height: 291px;
}

.m-h-292 {
  min-height: 292px;
}

.m-h-293 {
  min-height: 293px;
}

.m-h-294 {
  min-height: 294px;
}

.m-h-295 {
  min-height: 295px;
}

.m-h-296 {
  min-height: 296px;
}

.m-h-297 {
  min-height: 297px;
}

.m-h-298 {
  min-height: 298px;
}

.m-h-299 {
  min-height: 299px;
}

.m-h-300 {
  min-height: 300px;
}

.m-h-301 {
  min-height: 301px;
}

.m-h-302 {
  min-height: 302px;
}

.m-h-303 {
  min-height: 303px;
}

.m-h-304 {
  min-height: 304px;
}

.m-h-305 {
  min-height: 305px;
}

.m-h-306 {
  min-height: 306px;
}

.m-h-307 {
  min-height: 307px;
}

.m-h-308 {
  min-height: 308px;
}

.m-h-309 {
  min-height: 309px;
}

.m-h-310 {
  min-height: 310px;
}

.m-h-311 {
  min-height: 311px;
}

.m-h-312 {
  min-height: 312px;
}

.m-h-313 {
  min-height: 313px;
}

.m-h-314 {
  min-height: 314px;
}

.m-h-315 {
  min-height: 315px;
}

.m-h-316 {
  min-height: 316px;
}

.m-h-317 {
  min-height: 317px;
}

.m-h-318 {
  min-height: 318px;
}

.m-h-319 {
  min-height: 319px;
}

.m-h-320 {
  min-height: 320px;
}

.m-h-321 {
  min-height: 321px;
}

.m-h-322 {
  min-height: 322px;
}

.m-h-323 {
  min-height: 323px;
}

.m-h-324 {
  min-height: 324px;
}

.m-h-325 {
  min-height: 325px;
}

.m-h-326 {
  min-height: 326px;
}

.m-h-327 {
  min-height: 327px;
}

.m-h-328 {
  min-height: 328px;
}

.m-h-329 {
  min-height: 329px;
}

.m-h-330 {
  min-height: 330px;
}

.m-h-331 {
  min-height: 331px;
}

.m-h-332 {
  min-height: 332px;
}

.m-h-333 {
  min-height: 333px;
}

.m-h-334 {
  min-height: 334px;
}

.m-h-335 {
  min-height: 335px;
}

.m-h-336 {
  min-height: 336px;
}

.m-h-337 {
  min-height: 337px;
}

.m-h-338 {
  min-height: 338px;
}

.m-h-339 {
  min-height: 339px;
}

.m-h-340 {
  min-height: 340px;
}

.m-h-341 {
  min-height: 341px;
}

.m-h-342 {
  min-height: 342px;
}

.m-h-343 {
  min-height: 343px;
}

.m-h-344 {
  min-height: 344px;
}

.m-h-345 {
  min-height: 345px;
}

.m-h-346 {
  min-height: 346px;
}

.m-h-347 {
  min-height: 347px;
}

.m-h-348 {
  min-height: 348px;
}

.m-h-349 {
  min-height: 349px;
}

.m-h-350 {
  min-height: 350px;
}

.m-h-351 {
  min-height: 351px;
}

.m-h-352 {
  min-height: 352px;
}

.m-h-353 {
  min-height: 353px;
}

.m-h-354 {
  min-height: 354px;
}

.m-h-355 {
  min-height: 355px;
}

.m-h-356 {
  min-height: 356px;
}

.m-h-357 {
  min-height: 357px;
}

.m-h-358 {
  min-height: 358px;
}

.m-h-359 {
  min-height: 359px;
}

.m-h-360 {
  min-height: 360px;
}

.m-h-361 {
  min-height: 361px;
}

.m-h-362 {
  min-height: 362px;
}

.m-h-363 {
  min-height: 363px;
}

.m-h-364 {
  min-height: 364px;
}

.m-h-365 {
  min-height: 365px;
}

.m-h-366 {
  min-height: 366px;
}

.m-h-367 {
  min-height: 367px;
}

.m-h-368 {
  min-height: 368px;
}

.m-h-369 {
  min-height: 369px;
}

.m-h-370 {
  min-height: 370px;
}

.m-h-371 {
  min-height: 371px;
}

.m-h-372 {
  min-height: 372px;
}

.m-h-373 {
  min-height: 373px;
}

.m-h-374 {
  min-height: 374px;
}

.m-h-375 {
  min-height: 375px;
}

.m-h-376 {
  min-height: 376px;
}

.m-h-377 {
  min-height: 377px;
}

.m-h-378 {
  min-height: 378px;
}

.m-h-379 {
  min-height: 379px;
}

.m-h-380 {
  min-height: 380px;
}

.m-h-381 {
  min-height: 381px;
}

.m-h-382 {
  min-height: 382px;
}

.m-h-383 {
  min-height: 383px;
}

.m-h-384 {
  min-height: 384px;
}

.m-h-385 {
  min-height: 385px;
}

.m-h-386 {
  min-height: 386px;
}

.m-h-387 {
  min-height: 387px;
}

.m-h-388 {
  min-height: 388px;
}

.m-h-389 {
  min-height: 389px;
}

.m-h-390 {
  min-height: 390px;
}

.m-h-391 {
  min-height: 391px;
}

.m-h-392 {
  min-height: 392px;
}

.m-h-393 {
  min-height: 393px;
}

.m-h-394 {
  min-height: 394px;
}

.m-h-395 {
  min-height: 395px;
}

.m-h-396 {
  min-height: 396px;
}

.m-h-397 {
  min-height: 397px;
}

.m-h-398 {
  min-height: 398px;
}

.m-h-399 {
  min-height: 399px;
}

.m-h-400 {
  min-height: 400px;
}

.m-h-401 {
  min-height: 401px;
}

.m-h-402 {
  min-height: 402px;
}

.m-h-403 {
  min-height: 403px;
}

.m-h-404 {
  min-height: 404px;
}

.m-h-405 {
  min-height: 405px;
}

.m-h-406 {
  min-height: 406px;
}

.m-h-407 {
  min-height: 407px;
}

.m-h-408 {
  min-height: 408px;
}

.m-h-409 {
  min-height: 409px;
}

.m-h-410 {
  min-height: 410px;
}

.m-h-411 {
  min-height: 411px;
}

.m-h-412 {
  min-height: 412px;
}

.m-h-413 {
  min-height: 413px;
}

.m-h-414 {
  min-height: 414px;
}

.m-h-415 {
  min-height: 415px;
}

.m-h-416 {
  min-height: 416px;
}

.m-h-417 {
  min-height: 417px;
}

.m-h-418 {
  min-height: 418px;
}

.m-h-419 {
  min-height: 419px;
}

.m-h-420 {
  min-height: 420px;
}

.m-h-421 {
  min-height: 421px;
}

.m-h-422 {
  min-height: 422px;
}

.m-h-423 {
  min-height: 423px;
}

.m-h-424 {
  min-height: 424px;
}

.m-h-425 {
  min-height: 425px;
}

.m-h-426 {
  min-height: 426px;
}

.m-h-427 {
  min-height: 427px;
}

.m-h-428 {
  min-height: 428px;
}

.m-h-429 {
  min-height: 429px;
}

.m-h-430 {
  min-height: 430px;
}

.m-h-431 {
  min-height: 431px;
}

.m-h-432 {
  min-height: 432px;
}

.m-h-433 {
  min-height: 433px;
}

.m-h-434 {
  min-height: 434px;
}

.m-h-435 {
  min-height: 435px;
}

.m-h-436 {
  min-height: 436px;
}

.m-h-437 {
  min-height: 437px;
}

.m-h-438 {
  min-height: 438px;
}

.m-h-439 {
  min-height: 439px;
}

.m-h-440 {
  min-height: 440px;
}

.m-h-441 {
  min-height: 441px;
}

.m-h-442 {
  min-height: 442px;
}

.m-h-443 {
  min-height: 443px;
}

.m-h-444 {
  min-height: 444px;
}

.m-h-445 {
  min-height: 445px;
}

.m-h-446 {
  min-height: 446px;
}

.m-h-447 {
  min-height: 447px;
}

.m-h-448 {
  min-height: 448px;
}

.m-h-449 {
  min-height: 449px;
}

.m-h-450 {
  min-height: 450px;
}

.m-h-451 {
  min-height: 451px;
}

.m-h-452 {
  min-height: 452px;
}

.m-h-453 {
  min-height: 453px;
}

.m-h-454 {
  min-height: 454px;
}

.m-h-455 {
  min-height: 455px;
}

.m-h-456 {
  min-height: 456px;
}

.m-h-457 {
  min-height: 457px;
}

.m-h-458 {
  min-height: 458px;
}

.m-h-459 {
  min-height: 459px;
}

.m-h-460 {
  min-height: 460px;
}

.m-h-461 {
  min-height: 461px;
}

.m-h-462 {
  min-height: 462px;
}

.m-h-463 {
  min-height: 463px;
}

.m-h-464 {
  min-height: 464px;
}

.m-h-465 {
  min-height: 465px;
}

.m-h-466 {
  min-height: 466px;
}

.m-h-467 {
  min-height: 467px;
}

.m-h-468 {
  min-height: 468px;
}

.m-h-469 {
  min-height: 469px;
}

.m-h-470 {
  min-height: 470px;
}

.m-h-471 {
  min-height: 471px;
}

.m-h-472 {
  min-height: 472px;
}

.m-h-473 {
  min-height: 473px;
}

.m-h-474 {
  min-height: 474px;
}

.m-h-475 {
  min-height: 475px;
}

.m-h-476 {
  min-height: 476px;
}

.m-h-477 {
  min-height: 477px;
}

.m-h-478 {
  min-height: 478px;
}

.m-h-479 {
  min-height: 479px;
}

.m-h-480 {
  min-height: 480px;
}

.m-h-481 {
  min-height: 481px;
}

.m-h-482 {
  min-height: 482px;
}

.m-h-483 {
  min-height: 483px;
}

.m-h-484 {
  min-height: 484px;
}

.m-h-485 {
  min-height: 485px;
}

.m-h-486 {
  min-height: 486px;
}

.m-h-487 {
  min-height: 487px;
}

.m-h-488 {
  min-height: 488px;
}

.m-h-489 {
  min-height: 489px;
}

.m-h-490 {
  min-height: 490px;
}

.m-h-491 {
  min-height: 491px;
}

.m-h-492 {
  min-height: 492px;
}

.m-h-493 {
  min-height: 493px;
}

.m-h-494 {
  min-height: 494px;
}

.m-h-495 {
  min-height: 495px;
}

.m-h-496 {
  min-height: 496px;
}

.m-h-497 {
  min-height: 497px;
}

.m-h-498 {
  min-height: 498px;
}

.m-h-499 {
  min-height: 499px;
}

.m-h-500 {
  min-height: 500px;
}

.m-h-501 {
  min-height: 501px;
}

.m-h-502 {
  min-height: 502px;
}

.m-h-503 {
  min-height: 503px;
}

.m-h-504 {
  min-height: 504px;
}

.m-h-505 {
  min-height: 505px;
}

.m-h-506 {
  min-height: 506px;
}

.m-h-507 {
  min-height: 507px;
}

.m-h-508 {
  min-height: 508px;
}

.m-h-509 {
  min-height: 509px;
}

.m-h-510 {
  min-height: 510px;
}

.m-h-511 {
  min-height: 511px;
}

.m-h-512 {
  min-height: 512px;
}

.m-h-513 {
  min-height: 513px;
}

.m-h-514 {
  min-height: 514px;
}

.m-h-515 {
  min-height: 515px;
}

.m-h-516 {
  min-height: 516px;
}

.m-h-517 {
  min-height: 517px;
}

.m-h-518 {
  min-height: 518px;
}

.m-h-519 {
  min-height: 519px;
}

.m-h-520 {
  min-height: 520px;
}

.m-h-521 {
  min-height: 521px;
}

.m-h-522 {
  min-height: 522px;
}

.m-h-523 {
  min-height: 523px;
}

.m-h-524 {
  min-height: 524px;
}

.m-h-525 {
  min-height: 525px;
}

.m-h-526 {
  min-height: 526px;
}

.m-h-527 {
  min-height: 527px;
}

.m-h-528 {
  min-height: 528px;
}

.m-h-529 {
  min-height: 529px;
}

.m-h-530 {
  min-height: 530px;
}

.m-h-531 {
  min-height: 531px;
}

.m-h-532 {
  min-height: 532px;
}

.m-h-533 {
  min-height: 533px;
}

.m-h-534 {
  min-height: 534px;
}

.m-h-535 {
  min-height: 535px;
}

.m-h-536 {
  min-height: 536px;
}

.m-h-537 {
  min-height: 537px;
}

.m-h-538 {
  min-height: 538px;
}

.m-h-539 {
  min-height: 539px;
}

.m-h-540 {
  min-height: 540px;
}

.m-h-541 {
  min-height: 541px;
}

.m-h-542 {
  min-height: 542px;
}

.m-h-543 {
  min-height: 543px;
}

.m-h-544 {
  min-height: 544px;
}

.m-h-545 {
  min-height: 545px;
}

.m-h-546 {
  min-height: 546px;
}

.m-h-547 {
  min-height: 547px;
}

.m-h-548 {
  min-height: 548px;
}

.m-h-549 {
  min-height: 549px;
}

.m-h-550 {
  min-height: 550px;
}

.m-h-551 {
  min-height: 551px;
}

.m-h-552 {
  min-height: 552px;
}

.m-h-553 {
  min-height: 553px;
}

.m-h-554 {
  min-height: 554px;
}

.m-h-555 {
  min-height: 555px;
}

.m-h-556 {
  min-height: 556px;
}

.m-h-557 {
  min-height: 557px;
}

.m-h-558 {
  min-height: 558px;
}

.m-h-559 {
  min-height: 559px;
}

.m-h-560 {
  min-height: 560px;
}

.m-h-561 {
  min-height: 561px;
}

.m-h-562 {
  min-height: 562px;
}

.m-h-563 {
  min-height: 563px;
}

.m-h-564 {
  min-height: 564px;
}

.m-h-565 {
  min-height: 565px;
}

.m-h-566 {
  min-height: 566px;
}

.m-h-567 {
  min-height: 567px;
}

.m-h-568 {
  min-height: 568px;
}

.m-h-569 {
  min-height: 569px;
}

.m-h-570 {
  min-height: 570px;
}

.m-h-571 {
  min-height: 571px;
}

.m-h-572 {
  min-height: 572px;
}

.m-h-573 {
  min-height: 573px;
}

.m-h-574 {
  min-height: 574px;
}

.m-h-575 {
  min-height: 575px;
}

.m-h-576 {
  min-height: 576px;
}

.m-h-577 {
  min-height: 577px;
}

.m-h-578 {
  min-height: 578px;
}

.m-h-579 {
  min-height: 579px;
}

.m-h-580 {
  min-height: 580px;
}

.m-h-581 {
  min-height: 581px;
}

.m-h-582 {
  min-height: 582px;
}

.m-h-583 {
  min-height: 583px;
}

.m-h-584 {
  min-height: 584px;
}

.m-h-585 {
  min-height: 585px;
}

.m-h-586 {
  min-height: 586px;
}

.m-h-587 {
  min-height: 587px;
}

.m-h-588 {
  min-height: 588px;
}

.m-h-589 {
  min-height: 589px;
}

.m-h-590 {
  min-height: 590px;
}

.m-h-591 {
  min-height: 591px;
}

.m-h-592 {
  min-height: 592px;
}

.m-h-593 {
  min-height: 593px;
}

.m-h-594 {
  min-height: 594px;
}

.m-h-595 {
  min-height: 595px;
}

.m-h-596 {
  min-height: 596px;
}

.m-h-597 {
  min-height: 597px;
}

.m-h-598 {
  min-height: 598px;
}

.m-h-599 {
  min-height: 599px;
}

.m-h-600 {
  min-height: 600px;
}

.m-h-601 {
  min-height: 601px;
}

.m-h-602 {
  min-height: 602px;
}

.m-h-603 {
  min-height: 603px;
}

.m-h-604 {
  min-height: 604px;
}

.m-h-605 {
  min-height: 605px;
}

.m-h-606 {
  min-height: 606px;
}

.m-h-607 {
  min-height: 607px;
}

.m-h-608 {
  min-height: 608px;
}

.m-h-609 {
  min-height: 609px;
}

.m-h-610 {
  min-height: 610px;
}

.m-h-611 {
  min-height: 611px;
}

.m-h-612 {
  min-height: 612px;
}

.m-h-613 {
  min-height: 613px;
}

.m-h-614 {
  min-height: 614px;
}

.m-h-615 {
  min-height: 615px;
}

.m-h-616 {
  min-height: 616px;
}

.m-h-617 {
  min-height: 617px;
}

.m-h-618 {
  min-height: 618px;
}

.m-h-619 {
  min-height: 619px;
}

.m-h-620 {
  min-height: 620px;
}

.m-h-621 {
  min-height: 621px;
}

.m-h-622 {
  min-height: 622px;
}

.m-h-623 {
  min-height: 623px;
}

.m-h-624 {
  min-height: 624px;
}

.m-h-625 {
  min-height: 625px;
}

.m-h-626 {
  min-height: 626px;
}

.m-h-627 {
  min-height: 627px;
}

.m-h-628 {
  min-height: 628px;
}

.m-h-629 {
  min-height: 629px;
}

.m-h-630 {
  min-height: 630px;
}

.m-h-631 {
  min-height: 631px;
}

.m-h-632 {
  min-height: 632px;
}

.m-h-633 {
  min-height: 633px;
}

.m-h-634 {
  min-height: 634px;
}

.m-h-635 {
  min-height: 635px;
}

.m-h-636 {
  min-height: 636px;
}

.m-h-637 {
  min-height: 637px;
}

.m-h-638 {
  min-height: 638px;
}

.m-h-639 {
  min-height: 639px;
}

.m-h-640 {
  min-height: 640px;
}

.m-h-641 {
  min-height: 641px;
}

.m-h-642 {
  min-height: 642px;
}

.m-h-643 {
  min-height: 643px;
}

.m-h-644 {
  min-height: 644px;
}

.m-h-645 {
  min-height: 645px;
}

.m-h-646 {
  min-height: 646px;
}

.m-h-647 {
  min-height: 647px;
}

.m-h-648 {
  min-height: 648px;
}

.m-h-649 {
  min-height: 649px;
}

.m-h-650 {
  min-height: 650px;
}

.m-h-651 {
  min-height: 651px;
}

.m-h-652 {
  min-height: 652px;
}

.m-h-653 {
  min-height: 653px;
}

.m-h-654 {
  min-height: 654px;
}

.m-h-655 {
  min-height: 655px;
}

.m-h-656 {
  min-height: 656px;
}

.m-h-657 {
  min-height: 657px;
}

.m-h-658 {
  min-height: 658px;
}

.m-h-659 {
  min-height: 659px;
}

.m-h-660 {
  min-height: 660px;
}

.m-h-661 {
  min-height: 661px;
}

.m-h-662 {
  min-height: 662px;
}

.m-h-663 {
  min-height: 663px;
}

.m-h-664 {
  min-height: 664px;
}

.m-h-665 {
  min-height: 665px;
}

.m-h-666 {
  min-height: 666px;
}

.m-h-667 {
  min-height: 667px;
}

.m-h-668 {
  min-height: 668px;
}

.m-h-669 {
  min-height: 669px;
}

.m-h-670 {
  min-height: 670px;
}

.m-h-671 {
  min-height: 671px;
}

.m-h-672 {
  min-height: 672px;
}

.m-h-673 {
  min-height: 673px;
}

.m-h-674 {
  min-height: 674px;
}

.m-h-675 {
  min-height: 675px;
}

.m-h-676 {
  min-height: 676px;
}

.m-h-677 {
  min-height: 677px;
}

.m-h-678 {
  min-height: 678px;
}

.m-h-679 {
  min-height: 679px;
}

.m-h-680 {
  min-height: 680px;
}

.m-h-681 {
  min-height: 681px;
}

.m-h-682 {
  min-height: 682px;
}

.m-h-683 {
  min-height: 683px;
}

.m-h-684 {
  min-height: 684px;
}

.m-h-685 {
  min-height: 685px;
}

.m-h-686 {
  min-height: 686px;
}

.m-h-687 {
  min-height: 687px;
}

.m-h-688 {
  min-height: 688px;
}

.m-h-689 {
  min-height: 689px;
}

.m-h-690 {
  min-height: 690px;
}

.m-h-691 {
  min-height: 691px;
}

.m-h-692 {
  min-height: 692px;
}

.m-h-693 {
  min-height: 693px;
}

.m-h-694 {
  min-height: 694px;
}

.m-h-695 {
  min-height: 695px;
}

.m-h-696 {
  min-height: 696px;
}

.m-h-697 {
  min-height: 697px;
}

.m-h-698 {
  min-height: 698px;
}

.m-h-699 {
  min-height: 699px;
}

.m-h-700 {
  min-height: 700px;
}

.m-h-701 {
  min-height: 701px;
}

.m-h-702 {
  min-height: 702px;
}

.m-h-703 {
  min-height: 703px;
}

.m-h-704 {
  min-height: 704px;
}

.m-h-705 {
  min-height: 705px;
}

.m-h-706 {
  min-height: 706px;
}

.m-h-707 {
  min-height: 707px;
}

.m-h-708 {
  min-height: 708px;
}

.m-h-709 {
  min-height: 709px;
}

.m-h-710 {
  min-height: 710px;
}

.m-h-711 {
  min-height: 711px;
}

.m-h-712 {
  min-height: 712px;
}

.m-h-713 {
  min-height: 713px;
}

.m-h-714 {
  min-height: 714px;
}

.m-h-715 {
  min-height: 715px;
}

.m-h-716 {
  min-height: 716px;
}

.m-h-717 {
  min-height: 717px;
}

.m-h-718 {
  min-height: 718px;
}

.m-h-719 {
  min-height: 719px;
}

.m-h-720 {
  min-height: 720px;
}

.m-h-721 {
  min-height: 721px;
}

.m-h-722 {
  min-height: 722px;
}

.m-h-723 {
  min-height: 723px;
}

.m-h-724 {
  min-height: 724px;
}

.m-h-725 {
  min-height: 725px;
}

.m-h-726 {
  min-height: 726px;
}

.m-h-727 {
  min-height: 727px;
}

.m-h-728 {
  min-height: 728px;
}

.m-h-729 {
  min-height: 729px;
}

.m-h-730 {
  min-height: 730px;
}

.m-h-731 {
  min-height: 731px;
}

.m-h-732 {
  min-height: 732px;
}

.m-h-733 {
  min-height: 733px;
}

.m-h-734 {
  min-height: 734px;
}

.m-h-735 {
  min-height: 735px;
}

.m-h-736 {
  min-height: 736px;
}

.m-h-737 {
  min-height: 737px;
}

.m-h-738 {
  min-height: 738px;
}

.m-h-739 {
  min-height: 739px;
}

.m-h-740 {
  min-height: 740px;
}

.m-h-741 {
  min-height: 741px;
}

.m-h-742 {
  min-height: 742px;
}

.m-h-743 {
  min-height: 743px;
}

.m-h-744 {
  min-height: 744px;
}

.m-h-745 {
  min-height: 745px;
}

.m-h-746 {
  min-height: 746px;
}

.m-h-747 {
  min-height: 747px;
}

.m-h-748 {
  min-height: 748px;
}

.m-h-749 {
  min-height: 749px;
}

.m-h-750 {
  min-height: 750px;
}

.m-h-751 {
  min-height: 751px;
}

.m-h-752 {
  min-height: 752px;
}

.m-h-753 {
  min-height: 753px;
}

.m-h-754 {
  min-height: 754px;
}

.m-h-755 {
  min-height: 755px;
}

.m-h-756 {
  min-height: 756px;
}

.m-h-757 {
  min-height: 757px;
}

.m-h-758 {
  min-height: 758px;
}

.m-h-759 {
  min-height: 759px;
}

.m-h-760 {
  min-height: 760px;
}

.m-h-761 {
  min-height: 761px;
}

.m-h-762 {
  min-height: 762px;
}

.m-h-763 {
  min-height: 763px;
}

.m-h-764 {
  min-height: 764px;
}

.m-h-765 {
  min-height: 765px;
}

.m-h-766 {
  min-height: 766px;
}

.m-h-767 {
  min-height: 767px;
}

.m-h-768 {
  min-height: 768px;
}

.m-h-769 {
  min-height: 769px;
}

.m-h-770 {
  min-height: 770px;
}

.m-h-771 {
  min-height: 771px;
}

.m-h-772 {
  min-height: 772px;
}

.m-h-773 {
  min-height: 773px;
}

.m-h-774 {
  min-height: 774px;
}

.m-h-775 {
  min-height: 775px;
}

.m-h-776 {
  min-height: 776px;
}

.m-h-777 {
  min-height: 777px;
}

.m-h-778 {
  min-height: 778px;
}

.m-h-779 {
  min-height: 779px;
}

.m-h-780 {
  min-height: 780px;
}

.m-h-781 {
  min-height: 781px;
}

.m-h-782 {
  min-height: 782px;
}

.m-h-783 {
  min-height: 783px;
}

.m-h-784 {
  min-height: 784px;
}

.m-h-785 {
  min-height: 785px;
}

.m-h-786 {
  min-height: 786px;
}

.m-h-787 {
  min-height: 787px;
}

.m-h-788 {
  min-height: 788px;
}

.m-h-789 {
  min-height: 789px;
}

.m-h-790 {
  min-height: 790px;
}

.m-h-791 {
  min-height: 791px;
}

.m-h-792 {
  min-height: 792px;
}

.m-h-793 {
  min-height: 793px;
}

.m-h-794 {
  min-height: 794px;
}

.m-h-795 {
  min-height: 795px;
}

.m-h-796 {
  min-height: 796px;
}

.m-h-797 {
  min-height: 797px;
}

.m-h-798 {
  min-height: 798px;
}

.m-h-799 {
  min-height: 799px;
}

.m-h-800 {
  min-height: 800px;
}

.m-h-801 {
  min-height: 801px;
}

.m-h-802 {
  min-height: 802px;
}

.m-h-803 {
  min-height: 803px;
}

.m-h-804 {
  min-height: 804px;
}

.m-h-805 {
  min-height: 805px;
}

.m-h-806 {
  min-height: 806px;
}

.m-h-807 {
  min-height: 807px;
}

.m-h-808 {
  min-height: 808px;
}

.m-h-809 {
  min-height: 809px;
}

.m-h-810 {
  min-height: 810px;
}

.m-h-811 {
  min-height: 811px;
}

.m-h-812 {
  min-height: 812px;
}

.m-h-813 {
  min-height: 813px;
}

.m-h-814 {
  min-height: 814px;
}

.m-h-815 {
  min-height: 815px;
}

.m-h-816 {
  min-height: 816px;
}

.m-h-817 {
  min-height: 817px;
}

.m-h-818 {
  min-height: 818px;
}

.m-h-819 {
  min-height: 819px;
}

.m-h-820 {
  min-height: 820px;
}

.m-h-821 {
  min-height: 821px;
}

.m-h-822 {
  min-height: 822px;
}

.m-h-823 {
  min-height: 823px;
}

.m-h-824 {
  min-height: 824px;
}

.m-h-825 {
  min-height: 825px;
}

.m-h-826 {
  min-height: 826px;
}

.m-h-827 {
  min-height: 827px;
}

.m-h-828 {
  min-height: 828px;
}

.m-h-829 {
  min-height: 829px;
}

.m-h-830 {
  min-height: 830px;
}

.m-h-831 {
  min-height: 831px;
}

.m-h-832 {
  min-height: 832px;
}

.m-h-833 {
  min-height: 833px;
}

.m-h-834 {
  min-height: 834px;
}

.m-h-835 {
  min-height: 835px;
}

.m-h-836 {
  min-height: 836px;
}

.m-h-837 {
  min-height: 837px;
}

.m-h-838 {
  min-height: 838px;
}

.m-h-839 {
  min-height: 839px;
}

.m-h-840 {
  min-height: 840px;
}

.m-h-841 {
  min-height: 841px;
}

.m-h-842 {
  min-height: 842px;
}

.m-h-843 {
  min-height: 843px;
}

.m-h-844 {
  min-height: 844px;
}

.m-h-845 {
  min-height: 845px;
}

.m-h-846 {
  min-height: 846px;
}

.m-h-847 {
  min-height: 847px;
}

.m-h-848 {
  min-height: 848px;
}

.m-h-849 {
  min-height: 849px;
}

.m-h-850 {
  min-height: 850px;
}

.m-h-851 {
  min-height: 851px;
}

.m-h-852 {
  min-height: 852px;
}

.m-h-853 {
  min-height: 853px;
}

.m-h-854 {
  min-height: 854px;
}

.m-h-855 {
  min-height: 855px;
}

.m-h-856 {
  min-height: 856px;
}

.m-h-857 {
  min-height: 857px;
}

.m-h-858 {
  min-height: 858px;
}

.m-h-859 {
  min-height: 859px;
}

.m-h-860 {
  min-height: 860px;
}

.m-h-861 {
  min-height: 861px;
}

.m-h-862 {
  min-height: 862px;
}

.m-h-863 {
  min-height: 863px;
}

.m-h-864 {
  min-height: 864px;
}

.m-h-865 {
  min-height: 865px;
}

.m-h-866 {
  min-height: 866px;
}

.m-h-867 {
  min-height: 867px;
}

.m-h-868 {
  min-height: 868px;
}

.m-h-869 {
  min-height: 869px;
}

.m-h-870 {
  min-height: 870px;
}

.m-h-871 {
  min-height: 871px;
}

.m-h-872 {
  min-height: 872px;
}

.m-h-873 {
  min-height: 873px;
}

.m-h-874 {
  min-height: 874px;
}

.m-h-875 {
  min-height: 875px;
}

.m-h-876 {
  min-height: 876px;
}

.m-h-877 {
  min-height: 877px;
}

.m-h-878 {
  min-height: 878px;
}

.m-h-879 {
  min-height: 879px;
}

.m-h-880 {
  min-height: 880px;
}

.m-h-881 {
  min-height: 881px;
}

.m-h-882 {
  min-height: 882px;
}

.m-h-883 {
  min-height: 883px;
}

.m-h-884 {
  min-height: 884px;
}

.m-h-885 {
  min-height: 885px;
}

.m-h-886 {
  min-height: 886px;
}

.m-h-887 {
  min-height: 887px;
}

.m-h-888 {
  min-height: 888px;
}

.m-h-889 {
  min-height: 889px;
}

.m-h-890 {
  min-height: 890px;
}

.m-h-891 {
  min-height: 891px;
}

.m-h-892 {
  min-height: 892px;
}

.m-h-893 {
  min-height: 893px;
}

.m-h-894 {
  min-height: 894px;
}

.m-h-895 {
  min-height: 895px;
}

.m-h-896 {
  min-height: 896px;
}

.m-h-897 {
  min-height: 897px;
}

.m-h-898 {
  min-height: 898px;
}

.m-h-899 {
  min-height: 899px;
}

.m-h-900 {
  min-height: 900px;
}

.m-h-901 {
  min-height: 901px;
}

.m-h-902 {
  min-height: 902px;
}

.m-h-903 {
  min-height: 903px;
}

.m-h-904 {
  min-height: 904px;
}

.m-h-905 {
  min-height: 905px;
}

.m-h-906 {
  min-height: 906px;
}

.m-h-907 {
  min-height: 907px;
}

.m-h-908 {
  min-height: 908px;
}

.m-h-909 {
  min-height: 909px;
}

.m-h-910 {
  min-height: 910px;
}

.m-h-911 {
  min-height: 911px;
}

.m-h-912 {
  min-height: 912px;
}

.m-h-913 {
  min-height: 913px;
}

.m-h-914 {
  min-height: 914px;
}

.m-h-915 {
  min-height: 915px;
}

.m-h-916 {
  min-height: 916px;
}

.m-h-917 {
  min-height: 917px;
}

.m-h-918 {
  min-height: 918px;
}

.m-h-919 {
  min-height: 919px;
}

.m-h-920 {
  min-height: 920px;
}

.m-h-921 {
  min-height: 921px;
}

.m-h-922 {
  min-height: 922px;
}

.m-h-923 {
  min-height: 923px;
}

.m-h-924 {
  min-height: 924px;
}

.m-h-925 {
  min-height: 925px;
}

.m-h-926 {
  min-height: 926px;
}

.m-h-927 {
  min-height: 927px;
}

.m-h-928 {
  min-height: 928px;
}

.m-h-929 {
  min-height: 929px;
}

.m-h-930 {
  min-height: 930px;
}

.m-h-931 {
  min-height: 931px;
}

.m-h-932 {
  min-height: 932px;
}

.m-h-933 {
  min-height: 933px;
}

.m-h-934 {
  min-height: 934px;
}

.m-h-935 {
  min-height: 935px;
}

.m-h-936 {
  min-height: 936px;
}

.m-h-937 {
  min-height: 937px;
}

.m-h-938 {
  min-height: 938px;
}

.m-h-939 {
  min-height: 939px;
}

.m-h-940 {
  min-height: 940px;
}

.m-h-941 {
  min-height: 941px;
}

.m-h-942 {
  min-height: 942px;
}

.m-h-943 {
  min-height: 943px;
}

.m-h-944 {
  min-height: 944px;
}

.m-h-945 {
  min-height: 945px;
}

.m-h-946 {
  min-height: 946px;
}

.m-h-947 {
  min-height: 947px;
}

.m-h-948 {
  min-height: 948px;
}

.m-h-949 {
  min-height: 949px;
}

.m-h-950 {
  min-height: 950px;
}

.m-h-951 {
  min-height: 951px;
}

.m-h-952 {
  min-height: 952px;
}

.m-h-953 {
  min-height: 953px;
}

.m-h-954 {
  min-height: 954px;
}

.m-h-955 {
  min-height: 955px;
}

.m-h-956 {
  min-height: 956px;
}

.m-h-957 {
  min-height: 957px;
}

.m-h-958 {
  min-height: 958px;
}

.m-h-959 {
  min-height: 959px;
}

.m-h-960 {
  min-height: 960px;
}

.m-h-961 {
  min-height: 961px;
}

.m-h-962 {
  min-height: 962px;
}

.m-h-963 {
  min-height: 963px;
}

.m-h-964 {
  min-height: 964px;
}

.m-h-965 {
  min-height: 965px;
}

.m-h-966 {
  min-height: 966px;
}

.m-h-967 {
  min-height: 967px;
}

.m-h-968 {
  min-height: 968px;
}

.m-h-969 {
  min-height: 969px;
}

.m-h-970 {
  min-height: 970px;
}

.m-h-971 {
  min-height: 971px;
}

.m-h-972 {
  min-height: 972px;
}

.m-h-973 {
  min-height: 973px;
}

.m-h-974 {
  min-height: 974px;
}

.m-h-975 {
  min-height: 975px;
}

.m-h-976 {
  min-height: 976px;
}

.m-h-977 {
  min-height: 977px;
}

.m-h-978 {
  min-height: 978px;
}

.m-h-979 {
  min-height: 979px;
}

.m-h-980 {
  min-height: 980px;
}

.m-h-981 {
  min-height: 981px;
}

.m-h-982 {
  min-height: 982px;
}

.m-h-983 {
  min-height: 983px;
}

.m-h-984 {
  min-height: 984px;
}

.m-h-985 {
  min-height: 985px;
}

.m-h-986 {
  min-height: 986px;
}

.m-h-987 {
  min-height: 987px;
}

.m-h-988 {
  min-height: 988px;
}

.m-h-989 {
  min-height: 989px;
}

.m-h-990 {
  min-height: 990px;
}

.m-h-991 {
  min-height: 991px;
}

.m-h-992 {
  min-height: 992px;
}

.m-h-993 {
  min-height: 993px;
}

.m-h-994 {
  min-height: 994px;
}

.m-h-995 {
  min-height: 995px;
}

.m-h-996 {
  min-height: 996px;
}

.m-h-997 {
  min-height: 997px;
}

.m-h-998 {
  min-height: 998px;
}

.m-h-999 {
  min-height: 999px;
}

.m-h-1000 {
  min-height: 1000px;
}

/*----Mixin------------------*/
/*----Extend------------------*/
.pr_doc_annotate .project_view .box_shodow {
  background: #fff;
  -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 10px;
}

/*----Login------------------*/
.pr_login_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pr_login_section .sec_left {
  padding: 3em;
}

.pr_login_section .sec_left .logo_main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.pr_login_section .sec_left .logo_main img {
  width: 200px;
}

.pr_login_section .sec_left .logo_main .login_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pr_login_section .sec_left .logo_main .login_box .login_base h6 {
  font-size: 1em;
  text-transform: uppercase;
  color: #6b6c6f;
}

.pr_login_section .sec_left .logo_main .login_box .login_base h4 {
  font-size: 2em;
  margin: 1em 0 1em 0;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .form_field {
  margin-bottom: 1.3em;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .form_field label {
  font-weight: 600;
  color: #626769;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .form_field .input_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 2px solid #dfe2e6;
  padding: 5px;
  border-radius: 30px;
  min-height: 35px;
  margin-top: .3em;
  /* background-color: #00b33c; */
}

.pr_login_section .sec_left .logo_main .login_box .login_base .form_field .input_box input {
  width: 100%;
  border: none;
  font-size: 1em;
  border-radius: 30px;
  color: #a9a7a7;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .form_field .input_box input:focus {
  outline: none;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .form_field .input_box .icn_img {
  width: 30px;
  margin: 0px 8px;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .form_field .input_box .img_eye {
  margin-right: 0.1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .action_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: .5em;
  margin-top: 2em;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .action_btn .btn_borderd {
  background: transparent;
  border: 2px solid #12c4fc !important;
  color: #12c4fc;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .action_btn .btn_base {
  min-width: 160px;
  min-height: 47px;
  text-align: center;
  border-radius: 30px;
  font-size: 1em;
  font-weight: 600;
  border: 2px solid transparent;
  cursor: pointer;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .action_btn .btn_primary {
  color: #fff;
  background: #12c4fc;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .hint_txt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_login_section .sec_left .logo_main .login_box .login_base .hint_txt .remember_txt {
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.65);
}

.pr_login_section .sec_left .logo_main .login_box .login_base .hint_txt .forget_txt {
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.65);
}

.pr_login_section .sec_left .logo_main .login_footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.pr_login_section .sec_left .logo_main .login_footer h6 {
  font-size: 0.8em;
  color: #6b6c6f;
}

.pr_login_section .sec_left .logo_main .login_footer .div_bar {
  color: #c3c3c3;
  font-size: 11px;
}

.pr_login_section .sec_right {
  background-image: url("../images/usebg.jpg");
  background-size: cover;
  height: 100vh;
}

.pr_login_section .sec_right .screen_overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 0px 3em;
  background: rgba(69, 176, 230, 0.67);
  padding: 0px 3em;
}

.pr_login_section .sec_right .screen_overlay h1 {
  font-size: 3.5em;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0.2em;
  text-align: center;
}

.pr_login_section .sec_right .screen_overlay h5 {
  font-size: 1.8em;
  font-weight: 400;
  color: #fff;
  text-align: center;
}

.pr_login_section .txt-upper {
  text-transform: uppercase;
}

.cursor_pointer{
  cursor: pointer;
}

/*----Header------------------*/
.pr_nav_header {
  background: #fff;
  border-bottom: 1px #f0f0f1 solid;
  position: relative;
  z-index: 9;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: fixed;
  margin-top: -1%;
}

.pr_nav_header .logo {
  margin-left: 25px;
  padding-top: 13px;
  width: 170px;
}

.pr_nav_header .pn_mainNav {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.pr_nav_header .pn_mainNav li {
  padding: 0 20px;
  height: 80px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_nav_header .pn_mainNav li a {
  text-decoration: none;
  font-size: 0.8em;
  position: relative;
  font-weight: 800;
  color: #cecece;
  padding-top: 13px;
  cursor: default;
}

.pr_nav_header .pn_mainNav .profile {
  border-left: 1px #d8d8d8 solid;
}

.pr_nav_header .pn_mainNav .profile .statusIndicator {
  width: 8px;
  height: 8px;
  background: #38a51c;
  position: absolute;
  border-radius: 50%;
  right: 2px;
  bottom: 0px;
  border: 1px #fff solid;
}

.pr_nav_header .pn_mainNav .profile_name {
  margin-left: 5px;
  color: #12c4fc;
}

.pr_nav_header .userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.pr_nav_header .userImg img {
  width: 100%;
  height: auto;
}

.pr_nav_header .dropdown {
  position: relative;
  display: inline-block;
}

.pr_nav_header .dropdown a {
  display: block;
}

.pr_nav_header .dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  -webkit-box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: .28571429rem;
  min-width: auto;
  padding: 10px;
  z-index: 1;
  right: 0;
}

.pr_nav_header .dropdown:hover .dropdown-content {
  display: block;
}

.pr_nav_header .hsub_menu li {
  height: auto;
  padding: 0;
  cursor: pointer;
  min-height: 25px;
}

.pr_nav_header .hsub_menu li .icon {
  font-size: 12px;
  margin-right: 3px;
}

.pr_nav_header .hsub_menu li span {
  font-size: 12px;
  white-space: nowrap;
}

/*----sidenav------------------*/
#pr_side_nav {
  border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
  position: fixed;
  padding-top: 0;
}

#pr_side_nav a {
  background: transparent !important;
}

#pr_side_nav a.active {
  background: transparent !important;
  padding: 0 !important;
  margin: 10px 0px !important;
  border-right: 2px solid #12c4fc !important;
  border-radius: unset !important;
}

#pr_side_nav a:hover {
  background: transparent !important;
}

/*----Dashboard------------------*/
/* #work_area {
  border-left: none !important;
}

#work_area .project_card {
  height: auto !important;
  background-color: #00b33c;
}

#work_area .project_card .content {
  height: auto !important;
}

#work_area .project_card .card_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#work_area .project_card .card_header h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: auto;
}

#work_area .project_card .card_menu .menu {
  top: 30%;
}

#work_area .project_card .label_right {
  text-align: right;
  margin: 5px;
}

#work_area .project_card .clr_gray {
  color: #C4C4C4;
}

#work_area .project_card .progress_dec {
  margin-top: 4rem !important;
}

#work_area .project_card .custom_progress {
  height: 10px !important;
}

#work_area .project_card .custom_progress .bar {
  height: 1em;
}

#work_area .project_card .custom_progress .bar .progress {
  top: 40% !important;
}

#work_area .project_card .card_extra {
  border-top: 1px solid transparent !important;
  margin-bottom: 10px;
}

#work_area .project_card .card_extra .button {
  background: transparent;
  border: 1px solid #12c4fc !important;
  border-radius: 30px;
  color: #12c4fc;
  text-transform: uppercase;
} */







#work_area {
  border-left: none !important;
}

.project_card {
  height: auto !important;
}

.project_card .content {
  height: auto !important;
}

.project_card .card_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.project_card .card_header h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 500;
}

.project_card .card_menu .menu {
  top: 30%;
}

.project_card .label_right {
  text-align: right;
  margin: 5px;
}

.project_card .clr_gray {
  color: #C4C4C4 !important;
  margin-top: 55px;
}

.project_card .progress_dec {
  margin-top: 4rem !important;
}

.project_card .custom_progress {
  height: 10px !important;
}

.project_card .custom_progress .bar {
  height: 1em !important;
}

.project_card .custom_progress .bar .progress {
  top: 40% !important;
}

.project_card .card_extra {
  border-top: 1px solid transparent !important;
  margin-bottom: 10px !important;
  display: flex !important;
  justify-content: center !important;
}

.project_card .card_extra .button {
  background: transparent !important;
  border: 1px solid #12c4fc !important;
  border-radius: 30px !important;
  color: #12c4fc;
  text-transform: uppercase !important;
}



/*----Project detail------------------*/
.pr_project_detail .custom_bredcrumb .breadcrumb {
  background: transparent;
}

.pr_project_detail .custom_bredcrumb .breadcrumb .section {
  color: #777;
  font-weight: 700;
}

.pr_project_detail .project_dec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pr_project_detail .project_dec h4 {
  margin: 10px; 
  color: #979797;
}

.pr_project_detail .project_dec h4:after {
  content: "|";
  color: #e2e2e2;
  padding: 0px 10px 0px 10px;
}

.pr_project_detail .project_dec h4:after:last-child:not(:only-child) {
  display: none;
}

.pr_project_detail .project_set {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: right;
}

.pr_project_detail .project_set .ctrl_icon {
  background: transparent;
  color: #9e9999;
  margin-left: 20px;
}

.pr_project_detail .project_set .ctrl_icon .menu {
  right: 0;
  left: auto !important;
}

.pr_project_detail .p_sampleview{
  min-width: 700px;
  max-width: 80%;
}

.pr_project_detail .p_sampleview .p_title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #dadada;
  padding-bottom: 15px;
}

.pr_project_detail .p_sampleview .p_title h3 {
  margin: 15px;
  text-transform: uppercase;
}

.pr_project_detail .p_sampleview .p_det_box {
  background: #fff;
  /* background-color: #06afe4; */
  padding: 20px 40px;
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-top: 30px;
}

.pr_project_detail .p_sampleview .p_det_box .ent_title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_project_detail .p_sampleview .p_det_box .ent_title .sec_tilte {
  margin: 20px 0px;
  text-transform: uppercase;
}

.pr_project_detail .p_sampleview .p_det_box .ent_title .sec_tilte:after {
  display: inline-block;
  content: "";
  border-top: 0.1rem solid #12c4fc;
  width: 4rem;
  margin: 0 1rem 0 0.5rem;
  -webkit-transform: translateY(-0.29rem);
  transform: translateY(-0.29rem);
}

.pr_project_detail .p_sampleview .p_det_box .ent_title .m_action_btn {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_project_detail .p_sampleview .p_det_box .ent_title .m_action_btn .action_btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #12c4fc;
  padding: 5px 10px;
  border-radius: 29px;
  color: #fff;
  width: 40px;
  height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
}

.pr_project_detail .p_sampleview .p_det_box .entity_sec .label {
  height: 30px;
  min-width: 90px;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0px 0px 5px 0px !important;
  margin-bottom: 10px;
  line-height: initial !important;
}

.pr_project_detail .p_sampleview .p_det_box .mimg_sec {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_project_detail .p_sampleview .p_det_box .mimg_sec .entity_img {
  width: 50%;
  height: 50%;
}

.pr_project_detail .p_sampleview .p_det_box .mimg_sec .label {
  height: 30px;
  min-width: 100%;
  border-radius: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 10px;
}

.project_view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.project_view .p_view {
  /* width: 50%; */
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 50px;
}

.project_view .p_view .p_title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #dadada;
  padding-bottom: 15px;
}

.project_view .p_view .p_title h3 {
  margin: 15px;
  text-transform: uppercase;
}

/* aa */
.table_view {
  margin-top: 30px;

  th {
    background: transparent;
  }

  .spaceUnder {
    background: #fff;

    td {
      background: #fff;
    }
  }
}

.project_view .p_view .table_view {
  margin-top: 30px;
}

.project_view .p_view .table_view th {
  background: transparent;
}

.project_view .p_view .table_view .spaceUnder {
  background: #fff;
}

.project_view .p_view .table_view .spaceUnder td {
  background: #fff;
}

.project_view .det_box {
  background: #fff;
  padding: 40px;
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-top: 30px;
  /* background-color: #06afe4; */
  min-width: 500px;
}

.project_view .det_box .label_set {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 40px;
}

.project_view .det_box .label_set a {
  background: #12c4fc;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 29px;
  margin-right: 10px;
  margin-bottom: 5px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.project_view .det_box .label_set a span {
  margin-left: 10px;
  font-size: 14px;
}

.project_view .det_box .DBStatus h5 {
  text-align: right;
  color: #C4C4C4;
}

.project_view .det_box .DBStatus .statusBar {
  height: 10px;
  margin-bottom: 5px;
}

.project_view .det_box .DBStatus .statusBar .bar {
  height: 1em;
}

.project_view .det_box .DBStatus .statusBar .statusCount {
  color: #C4C4C4;
  font-size: .8em;
}

.project_view .det_box .count_set .count_holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 25px 0px 5px;
}

.project_view .det_box .count_set .count_holder:after {
  display: none;
}

.project_view .det_box .count_set .count_holder .statistic {
  border: 1px solid #F0F0F1;
  margin: 0;
  padding: 5px;
}

.project_view .det_box .count_set .count_holder .statistic .value {
  font-size: 30px !important;
  font-weight: 300 !important;
  color: #474751 !important;
}

.project_view .det_box .count_set .count_holder .statistic .label {
  color: #979797;
  font-weight: 600 !important;
  margin-top: 5px;
}

/*----Hitsview------------------*/
.pr_hitsview {
  margin-left: 6%;
  margin-right: 3%;
}

.pr_hitsview .project_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_hitsview .project_sec .project_title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_hitsview .project_sec .project_title h3 {
  font-weight: 600;
}

.pr_hitsview .project_sec .project_title h3:after {
  content: "|";
  color: #e2e2e2;
  padding: 0px 10px 0px 10px;
}

.pr_hitsview .project_sec .project_title .btn_back {
  width: 25px;
  height: 25px;
  background: #12c4fc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.pr_hitsview .project_sec .project_title .btn_back img {
  width: 10px;
}

.pr_hitsview .project_sec .project_set {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_hitsview .project_sec .project_set .tools {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_hitsview .project_sec .project_set .tools li {
  padding-left: 20px;
  position: relative;
}

.pr_hitsview .project_sec .project_set .tools li .selectUI {
  height: 30px;
  border-radius: 5px;
  border: 1px #dfe2e5 solid;
  font-size: 12px;
  font-weight: 600;
}

.pr_hitsview .project_sec .project_set .tools li .filterIcon {
  border: 0;
  background: transparent;
  vertical-align: text-bottom;
  cursor: pointer;
}

.pr_hitsview .project_sec .project_set .tools li .filterIcon img {
  width: 80%;
}

.pr_hitsview .project_sec .project_set .tools li .filterIcon:hover .dropBox {
  display: block !important;
}

.pr_hitsview .project_sec .project_set .tools li .dropBox {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 6px -4px #000;
  box-shadow: 0px 0px 6px -4px #000;
  position: absolute;
  z-index: 999;
  right: 0;
  top: 30px;
  width: 300px;
  display: none;
}

.pr_hitsview .project_sec .project_set .tools li .dropBox h3 {
  text-transform: uppercase;
  font-size: 0.8em;
  color: #979797;
  padding-left: 5px;
}

.pr_hitsview .project_sec .project_set .tools li .dropBox .filterTools {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pr_hitsview .project_sec .project_set .tools li .dropBox .filterTools li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.pr_hitsview .project_sec .project_set .tools li .dropBox .filterTools li label {
  font-weight: bold;
  font-size: 0.7em;
  color: #5b5b5b;
  padding-bottom: 5px;
}

.pr_hitsview .project_sec .project_set .tools li .dropBox .filterTools li .react-datepicker-component {
  width: 100%;
}

.pr_hitsview .project_sec .project_set .tools li .dropBox .filterTools li .react-datepicker-input {
  height: 30px;
  background: transparent;
  border-radius: 30px;
  border: 1px solid #dfe2e5;
}

.pr_hitsview .project_sec .project_set .tools li .dropBox .filterTools li .react-datepicker-input .calendar {
  font-size: .9em;
  margin-bottom: 6px;
}

.pr_hitsview .project_sec .project_set .tools li .dropBox .filterTools .custom_boxset .select_left {
  height: 25px !important;
  border-radius: 30px !important;
  font-size: .7em !important;
}

.pr_hitsview .project_sec .project_set .tools li .dropBox:hover {
  display: block !important;
}

.pr_hitsview .project_sec .project_set .tools .searchBox {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: transparent !important;
}

.pr_hitsview .project_sec .project_set .tools .searchBox .searchIcon {
  position: absolute;
  right: 8px;
  top: 3px;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.pr_hitsview .project_sec .project_set .tools .searchBox .searchIcon img {
  width: 75%;
  display: inline-block;
}

.pr_hitsview .project_sec .project_set .tools .searchBox input[type=text] {
  font-size: 12px;
  font-weight: 600;
}

.pr_hitsview .imgCardView {
  padding-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pr_hitsview .imgCardView .card_imgsec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pr_hitsview .imgCardView .hits_filter {
  -webkit-box-shadow: -1px 1px 5px #ccc;
  box-shadow: -1px 1px 5px #ccc;
  margin-left: 10px;
  padding: 10px 15px;
  min-width: 25%;
  border-radius: 3px;
  position: absolute;
  right: 3%;
  background: #fff;
  top: 6%;
  z-index: 99;
}

.pr_hitsview .imgCardView .hits_filter .dropBox_set .sec_tilte {
  margin: 20px 0px;
  text-transform: uppercase;
  font-weight: 600;
}

.pr_hitsview .imgCardView .hits_filter .dropBox_set .sec_tilte:after {
  display: inline-block;
  content: "";
  border-top: 0.1rem solid #12c4fc;
  width: 4rem;
  margin: 0 1rem 0 0.5rem;
  -webkit-transform: translateY(-0.29rem);
  transform: translateY(-0.29rem);
}

.pr_hitsview .imgCardView .hits_filter .dropBox_set .filterTools li {
  margin-bottom: 10px;
}

.pr_hitsview .imgCardView .hits_filter .dropBox_set .filterTools li select {
  height: 36px;
  font-size: .9em;
}

.pr_hitsview .imgCardView .hits_filter .dropBox_set .filterTools li label {
  margin-bottom: 10px;
}

.pr_hitsview .imgCardView .hits_filter .dropBox_set .filterTools .custom_filterdate {
  background: transparent !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pr_hitsview .imgCardView .hits_filter .dropBox_set .filterTools .custom_filterdate .react-datepicker-component {
  width: 100%;
}

.pr_hitsview .imgCardView .hits_filter .dropBox_set .filterTools .custom_filterdate .react-datepicker-component .react-datepicker-input {
  width: 100%;
  background: transparent !important;
  border-radius: 30px !important;
}

.pr_hitsview .imgCardView .hits_filter .dropBox_set .filterTools .custom_boxset .selection {
  border-radius: 30px !important;
  font-weight: 300 !important;
  height: 36px !important;
}

.pr_hitsview .imgCardView .c4 li {
  width: 25%;
  cursor: pointer;
}

.pr_hitsview .imgCardView .c4 li .cardCover {
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
}

.pr_hitsview .imgCardView .c4 li .cardCover .imgCover {
  position: relative;
  overflow: hidden;
  height: 150px;
  text-align: center;
}

.pr_hitsview .imgCardView .c4 li .cardCover .imgCover img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
}

.pr_hitsview .imgCardView .c4 li .cardCover .imgCover span {
  position: absolute;
  z-index: 9;
  right: 15px;
  padding: 0 7px;
  font-weight: bold;
  font-size: 0.8em;
  line-height: 22px;
  border-radius: 3px;
}

.pr_hitsview .imgCardView .c4 li .cardCover .imgCover span.label {
  top: 15px;
  background: #12c4fc;
  color: #fff;
}

.pr_hitsview .imgCardView .c4 li .cardCover .imgCover span.label img {
  vertical-align: text-bottom;
  position: relative;
}

.pr_hitsview .imgCardView .c4 li .cardCover .imgCover span.status {
  bottom: 15px;
  background: #fff;
  color: #12c4fc;
  text-transform: capitalize;
}

.pr_hitsview .imgCardView .c4 li .cardCover .imgInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pr_hitsview .imgCardView .c4 li .cardCover .imgInfo span {
  width: 100%;
  padding: 10px;
  font-size: 0.8em;
}

.pr_hitsview .imgCardView .pagination {
  right: unset !important;
  bottom: 5% !important;
  left: 3%;
  position: unset !important;
}

/*--dataset --*/
.pr_project_create {
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.pr_project_create .project_title {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_project_create .project_title h3 {
  font-weight: 600;
}

.pr_project_create .project_title h3:after {
  content: "|";
  color: #e2e2e2;
  padding: 0px 10px 0px 10px;
}

.pr_project_create .project_title .demo_link {
  color: #45b0e6;
  margin-left: 5px;
}

.pr_project_create .project_block .sec_tilte {
  margin: 20px 0px;
  text-transform: uppercase;
}

.pr_project_create .project_block .sec_tilte:after {
  display: inline-block;
  content: "";
  border-top: 0.1rem solid #12c4fc;
  width: 4rem;
  margin: 0 1rem 0 0.5rem;
  -webkit-transform: translateY(-0.29rem);
  transform: translateY(-0.29rem);
}

.pr_project_create .project_block .dashboardSet {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
}

.pr_project_create .project_block .dashboardSet li {
  width: 460px;
}

.pr_project_create .project_block .dashboardSet .DBBox {
  margin: 10px;
  border-radius: 5px;
  position: relative;
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  min-height: 190px;
}

.pr_project_create .project_block .dashboardSet .data_set {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 215px !important;
  padding: 20px;
}

.pr_project_create .project_block .dashboardSet .data_set h3 {
  color: #979797;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.pr_project_create .project_block .dashboardSet .data_set p {
  color: #5b5b5b;
  margin-bottom: 15px;
  margin-top: 5px;
}

.pr_project_create .project_block .dashboardSet .data_set .hint_txt {
  color: #bdb4b4;
}

.pr_project_create .project_block .dashboardSet .data_set .action_btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #12c4fc;
  padding: 5px 10px;
  border-radius: 29px;
  color: #fff;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.pr_project_create .project_block .dashboardSet .data_set .action_btn span {
  margin-left: 5px;
}

/*--labelset --*/

.pr_labelset{
  margin-left: 50px;
}

.pr_labelset .project_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
}

.pr_labelset .project_sec .project_title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_labelset .project_sec .project_title h3 {
  font-weight: 600;
}

.pr_labelset .project_sec .project_title .edt_icn {
  padding: 0px 10px;
}

.pr_labelset .project_sec .project_title .highlite_txt {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #d8d8d8;
  padding: 5px 10px;
  border-radius: 13px;
  color: #fff;
  margin-left: 10px;
  text-transform: uppercase;
}

.pr_labelset .project_sec .project_title .highlite_txt h4 {
  color: #fff;
}

.pr_labelset .project_sec .project_title .highlite_txt h4:after {
  content: "|";
  color: #fff;
  padding: 0px 10px 0px 10px;
}

.pr_labelset .project_sec .project_title .btn_back {
  width: 25px;
  height: 25px;
  background: #12c4fc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.pr_labelset .project_sec .project_title .btn_back img {
  width: 10px;
}

.pr_labelset .project_sec .project_set {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_labelset .project_sec .project_set .project_dec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pr_labelset .project_sec .project_set .project_dec .action_btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #12c4fc;
  padding: 5px 10px;
  border-radius: 29px;
  color: #fff;
}

.pr_labelset .project_sec .project_set .project_dec .action_btn span {
  margin-left: 5px;
}

.pr_labelset .project_view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pr_labelset .advance .box_shodow {
  background: #fff;
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 10px;
}

.pr_labelset .advance .box_shodow .main_box {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  padding-bottom: 20px;
}

.pr_labelset .advance .box_shodow .main_box .box_parent {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.pr_labelset .advance .box_shodow .main_box .box_parent .input_b {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_labelset .advance .right_box .r_box {
  padding: 10px;
}

.pr_labelset .advance .right_box .r_box .sec_tilte {
  margin: 20px 0px;
  text-transform: uppercase;
}

.pr_labelset .advance .right_box .r_box .sec_tilte:after {
  display: inline-block;
  content: "";
  border-top: 1px solid #12c4fc;
  width: 3rem;
  margin: 0 1rem 0 0.5rem;
  -webkit-transform: translateY(-0.29rem);
  transform: translateY(-0.29rem);
}

.pr_labelset .advance .right_box .r_box .view_holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pr_labelset .advance .right_box .r_box .view_holder .custom_area {
  border-radius: 5px;
  width: 100%;
  border: 1px solid #dfe2e6;
  padding: 0px 8px;
}

.pr_labelset .advance .right_box .r_box .custom_textarea .field {
  width: 100%;
}

.pr_labelset .advance .right_box .r_box .custom_textarea .field #instruction {
  width: 100% !important;
  padding: 5px;
  min-height: 150px;
  border-color: #dfe2e6;
}

.pr_labelset .advance .right_box .r_box .r_submit {
  min-width: 120px;
  height: 40px;
  background: #45b0e6;
  border: none;
  border-radius: 30px;
  color: #fff;
}

.pr_labelset .advance .right_box .r_box .clas_remove {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/*----insights------------------*/
.pr_insiteview .project_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_insiteview .project_sec .project_title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_insiteview .project_sec .project_title h3 {
  font-weight: 600;
}

.pr_insiteview .project_sec .project_title h3:after {
  content: "|";
  color: #e2e2e2;
  padding: 0px 10px 0px 10px;
}

.pr_insiteview .project_sec .project_title .btn_back {
  width: 25px;
  height: 25px;
  background: #12c4fc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 5px;
}

.pr_insiteview .project_sec .project_title .btn_back img {
  width: 10px;
}

.pr_insiteview .project_sec .project_set {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_insiteview .project_sec .project_set .site_date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.site_date .label{
  font-weight: 700;
}

.pr_insiteview .project_sec .project_set .site_date i.calendar.icon {
  margin-top: -7px;
}

.pr_insiteview .project_sec .project_set .site_date .react-datepicker-input {
  border-radius: 20px !important;
  margin-right: 10px !important;
}

.pr_insiteview .project_sec .project_set .site_date .react-datepicker-input input {
  border-radius: 20px !important;
}

.pr_insiteview .project_sec .project_set .action_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #12c4fc;
  padding: 5px 10px;
  border-radius: 29px;
  color: #fff;
  min-width: 120px;
}

.pr_insiteview .project_sec .project_set .action_btn span {
  margin-left: 5px;
}

.pr_insiteview .p_title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #dadada;
  padding-bottom: 15px;
}

.pr_insiteview .p_title h3 {
  margin-left: 15px;
  text-transform: uppercase;
}

.pr_insiteview .card_shade {
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: 1px 2px 3px #ccc;
  box-shadow: 1px 2px 3px #ccc;
  border-radius: 3px;
}

.pr_insiteview .card_shade .marginTop .recharts-wrapper {
  width: 100% !important;
}

/*----Document Annotation------------------*/
.pr_doc_annotate .project_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
}

.pr_doc_annotate .project_sec .project_title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_doc_annotate .project_sec .project_title h3 {
  font-weight: 600;
}

.pr_doc_annotate .project_sec .project_title h3:after {
  content: "|";
  color: #e2e2e2;
  padding: 0px 10px 0px 10px;
}

.pr_doc_annotate .project_sec .project_title .btn_back {
  width: 25px;
  height: 25px;
  background: #12c4fc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 5px;
}

.pr_doc_annotate .project_sec .project_title .btn_back img {
  width: 10px;
}

.pr_doc_annotate .project_sec .tools {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
}

.pr_doc_annotate .project_sec .tools li {
  padding-left: 20px;
  position: relative;
}

.pr_doc_annotate .project_sec .tools li .selectUI {
  height: 30px;
  border-radius: 5px;
  border: 1px #dfe2e5 solid;
}

.pr_doc_annotate .project_sec .tools li .filterIcon {
  border: 0;
  background: transparent;
  vertical-align: text-bottom;
  cursor: pointer;
}

.pr_doc_annotate .project_sec .tools li .filterIcon img {
  width: 80%;
}

.pr_doc_annotate .project_sec .tools li .filterIcon:hover .dropBox {
  display: block !important;
}

.pr_doc_annotate .project_sec .tools li .dropBox {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 6px -4px #000;
  box-shadow: 0px 0px 6px -4px #000;
  position: absolute;
  z-index: 999;
  right: 0;
  top: 30px;
  width: 300px;
  display: none;
}

.pr_doc_annotate .project_sec .tools li .dropBox h3 {
  text-transform: uppercase;
  font-size: 0.8em;
  color: #979797;
  padding-left: 5px;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li label {
  font-weight: bold;
  font-size: 0.7em;
  color: #5b5b5b;
  padding-bottom: 5px;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .react-datepicker-component {
  width: 100%;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .react-datepicker-input {
  min-width: 100px;
  height: 25px;
  background: transparent;
  border-radius: 30px;
  border: 1px solid #dfe2e5;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .react-datepicker-input .calendar {
  font-size: .9em;
  margin-bottom: 6px;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 5px;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_select .dropdown {
  min-height: 25px !important;
  border-radius: 30px;
  font-size: .7em !important;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_select .dropdown .icon {
  top: 1.3em !important;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_select .item {
  font-size: .7rem;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_datepik {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_datepik .react-datepicker-input {
  height: 28px;
}

.pr_doc_annotate .project_sec .tools li .dropBox:hover {
  display: block !important;
}

.pr_doc_annotate .project_sec .project_set {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_doc_annotate .project_sec .project_set .icn_btn {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_doc_annotate .project_sec .project_set .icn_btn .action_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #12c4fc;
  padding: 5px 10px;
  border-radius: 29px;
  color: #fff;
  min-width: auto;
}

.pr_doc_annotate .project_sec .project_set .icn_btn .action_btn span {
  margin-left: 5px;
}

.pr_doc_annotate .project_sec .project_set .main_popover .button {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  color: #fff !important;
  background: #12c4fc !important;
  margin-right: 0 !important;
}

.pr_doc_annotate .project_sec .project_set .main_popover .button .icon {
  color: #fff !important;
}

.pr_doc_annotate .project_view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pr_doc_annotate .project_view .box_scrol {
  height: 720px;
  overflow-y: auto;
}

.pr_doc_annotate .project_view .right_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_doc_annotate .project_view .right_box .sec_top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .sec_tilte {
  margin: 20px 0px;
  text-transform: uppercase;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .sec_tilte:after {
  display: inline-block;
  content: "";
  border-top: 1px solid #12c4fc;
  width: 3rem;
  margin: 0 1rem 0 0.5rem;
  -webkit-transform: translateY(-0.29rem);
  transform: translateY(-0.29rem);
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .crt_set {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .crt_set .ctrl_btn {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .crt_set .ctrl_btn img {
  width: 20px;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .crt_set .btn_default {
  background: #DADADA;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .crt_set .btn_active {
  background: #12c4fc;
  cursor: pointer;
}

.pr_doc_annotate .project_view .right_box .sec_top .status_area {
  border: 1px solid #DFE2E6;
  padding: 10px;
  height: 350px;
  overflow-y: auto;
  border-radius: 5px;
  width: 100%;
}

.pr_doc_annotate .project_view .right_box .sec_top .status_area h3 {
  color: #12C4FC;
  text-transform: uppercase;
}

.pr_doc_annotate .project_view .right_box .sec_top .status_area .status_btn {
  background: #DADADA;
  padding: 5px 15px;
  border-radius: 5px;
  color: #6B6C6F;
  font-size: .8em;
  border: none;
  min-width: 100px;
}

.pr_doc_annotate .project_view .right_box .sec_top .status_area p {
  font-size: .8em;
}

.pr_doc_annotate .project_view .right_box .sec_top .action_btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.pr_doc_annotate .project_view .right_box .sec_top .action_btns .status_link {
  background: #12c4fc;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  margin: 5px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pr_doc_annotate .project_view .right_box .sec_top .action_btns .status_link .icon {
  color: #fff !important;
}

.pr_doc_annotate .project_view .right_box .sec_bottom h3 {
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
}

.pr_doc_annotate .project_view .right_box .sec_bottom .eva_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_doc_annotate .project_view .right_box .sec_bottom .eva_btn .btn_base {
  font-weight: 600;
  border-radius: 30px;
  padding: 5px 15px;
  text-align: center;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}

.pr_doc_annotate .project_view .right_box .sec_bottom .eva_btn .btn_default {
  background: #DADADA;
}

.pr_doc_annotate .project_view .right_box .sec_bottom .eva_btn .btn_active {
  background: #45B0E6;
}

.overlay_doms{
  opacity:0.8;
  background-color:rgba(189, 182, 182, 0.8);
  position:fixed;
  width:70%;
  height:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.overlay_doms .cssload-wraper{
  display:block; 
}

.pr_uploadsec {
  width: 100%;
  border: 1px solid #ececec;
  padding: 10px;
  border-radius: 3px;
  -webkit-box-shadow: 1px 2px 3px #ccc;
  box-shadow: 1px 2px 3px #ccc;
}

.pr_uploadsec h2 {
  color: #979797;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.pr_uploadsec .upload_holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_uploadsec .upload_holder .raw_data {
  width: 49%;
  border: 1.5px dotted #efefef;
  padding: 10px;
  min-height: 190px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_uploadsec .upload_holder .upload_pre {
  width: 49%;
  border: 1.5px dotted #efefef;
  padding: 10px;
  min-height: 190px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_uploadsec .upload_holder .upload_pre p {
  font-size: 14px;
  margin: 10px 0px;
}

.pr_uploadsec .upload_holder .pr_upload {
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  border-radius: 40px;
  background: #12c4fc;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  border-color: transparent;
}

.upload_infoCompare h4 {
  margin-right: 210px;
  font-size: 13px;
  margin-top: -80px;
}

.upload_infoCompare h5 {
  padding-top: 10px;
  margin-right: 180px;
  font-size: 12px;
  color: #a9a8a8;
}

.pr_selectfile {
  -webkit-box-shadow: 1px 2px 3px #ccc;
  box-shadow: 1px 2px 3px #ccc;
  padding: 10px;
  text-align: left;
  width: 100%;
}

.pr_selectfile .select_holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pr_selectfile .select_holder .b_l {
  padding: 10px;
  width: 100%;
  min-height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pr_selectfile .select_holder .b_l p {
  font-size: 14px;
  margin: 10px 0px;
  width: 100%;
}

.pr_selectfile .select_holder .b_l .accordion {
  font-size: 14px;
  border: 1px solid #eceaea;
  width: 100%;
  padding: 5px;
  margin: 10px 0px;
  border-radius: 5px;
}

.pr_selectfile .select_holder .b_l .accordion .title {
  height: auto !important;
  color: #12c4fc;
  padding: 0;
}

.pr_selectfile .select_holder .b_l .accordion .content {
  background: transparent;
  width: 100% !important;
}

.pr_selectfile .select_holder .b_r {
  padding: 10px;
  width: 100%;
  min-height: 200px;
  border-left: 1px solid #f3f3f3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/*-----upload info txt------------*/
.upload_info h4 {
  margin-right: 10px;
  font-size: 13px;
  margin-bottom: 5px;
}

.upload_info p {
  font-size: 12px;
}

/*-----Custom progress bar------------*/
.upload_progress {
  height: 10px;
  border-radius: 15px;
  margin-top: 10px;
}

.upload_progress .bar {
  height: 10px !important;
  background-color: #12c4fc !important;
}

.upload_progress .bar .progress {
  font-size: 10px !important;
}

/*----success page------------*/
.suceess_pageview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.suceess_pageview .suceess_holder {
  border: 1px solid #ececec;
  width: 50%;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 1px #ececec;
  box-shadow: 0px 0px 1px #ececec;
  border-radius: 3px;
}

.suceess_pageview .suceess_holder h2 {
  color: #159588;
  font-size: 24px;
}

.suceess_pageview .suceess_holder .success_view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 15px 0px;
}

.suceess_pageview .suceess_holder .success_view .viewbox {
  border: 1px solid #159588;
  padding: 8px;
  margin: 5px;
  -webkit-box-shadow: 0px 0px 1px #ececec;
  box-shadow: 0px 0px 1px #ececec;
  border-radius: 3px;
  min-width: 175px;
  min-height: 130px;
}

.suceess_pageview .suceess_holder .success_view .viewbox h3 {
  color: #159588;
  font-weight: 100;
  font-size: 30px;
}

.suceess_pageview .suceess_holder .success_view .viewbox h4 {
  color: #bdb7b7;
  margin: 10px;
}

.suceess_pageview .suceess_holder .success_submit {
  background: #159588;
  color: #fff;
  height: 40px;
  border-radius: 24px;
  font-weight: normal;
}

.success_msg .suceess_holder {
  border: 1px solid #ececec;
  width: 50%;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 1px #ececec;
  box-shadow: 0px 0px 1px #ececec;
  border-radius: 3px;
  text-align: center;
}

.success_msg .suceess_holder .success_title {
  color: #159588;
  font-size: 24px;
  margin: 15px 0px;
}

.success_msg .suceess_holder .success_submit {
  background: #159588;
  color: #fff;
  height: 40px;
  border-radius: 24px;
  font-weight: normal;
  margin-bottom: 15px;
}

/*--csucess msg------------*/
.csuceess_holder {
  /* border: 2px solid #159588; */
  width: 50%;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 1px #ececec;
  box-shadow: 0px 0px 1px #ececec;
  border-radius: 3px;
  text-align: center;
}

.csuceess_holder h2 {
  color: #159588;
  font-size: 24px;
}

.csuceess_holder .success_view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 15px 0px;
}

.csuceess_holder .success_view .viewbox {
  border: 1px solid #ececec;
  padding: 8px;
  margin: 5px;
  -webkit-box-shadow: 0px 0px 1px #ececec;
  box-shadow: 0px 0px 1px #ececec;
  border-radius: 3px;
  min-width: 175px;
  min-height: 130px;
}

.csuceess_holder .success_view .viewbox h3 {
  color: #159588;
  font-weight: 100;
  font-size: 30px;
}

.csuceess_holder .success_view .viewbox h4 {
  color: #bdb7b7;
  margin: 10px;
}

.csuceess_holder .success_submit {
  background: #159588;
  color: #fff;
  height: 40px;
  border-radius: 24px;
  font-weight: normal;
}

/*----insights------------------*/
.pr_insiteview .project_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_insiteview .project_sec .project_title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_insiteview .project_sec .project_title h3 {
  font-weight: 600;
}

.pr_insiteview .project_sec .project_title h3:after {
  content: "|";
  color: #e2e2e2;
  padding: 0px 10px 0px 10px;
}

.pr_insiteview .project_sec .project_title .btn_back {
  width: 25px;
  height: 25px;
  background: #12c4fc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 5px;
}

.pr_insiteview .project_sec .project_title .btn_back img {
  width: 10px;
}

.pr_insiteview .project_sec .project_title .title_ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}

.pr_insiteview .project_sec .project_set {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_insiteview .project_sec .project_set .hits_dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pr_insiteview .project_sec .project_set .hits_dropdown .selection {
  border-radius: 21px;
}

.pr_insiteview .project_sec .project_set .hits_dropdown .label {
  font-weight: 700;
}

.pr_insiteview .project_sec .project_set .site_date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pr_insiteview .project_sec .project_set .site_date .react-datepicker-input {
  border-radius: 20px !important;
  margin-right: 10px !important;
  background: transparent;
}

.pr_insiteview .project_sec .project_set .site_date .react-datepicker-input input {
  border-radius: 20px !important;
}

.pr_insiteview .project_sec .project_set .action_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #12c4fc;
  padding: 5px 10px;
  border-radius: 29px;
  color: #fff;
  min-width: 120px;
}

.pr_insiteview .project_sec .project_set .action_btn span {
  margin-left: 5px;
}

.pr_insiteview .p_title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #dadada;
  padding-bottom: 15px;
}

.pr_insiteview .p_title h3 {
  margin-left: 15px;
  text-transform: uppercase;
}

.pr_insiteview .card_shade {
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: 1px 2px 3px #ccc;
  box-shadow: 1px 2px 3px #ccc;
  border-radius: 3px;
}

.pr_insiteview .card_shade .marginTop .recharts-wrapper {
  width: 100% !important;
}

/*----Image Annotation------------------*/
.pr_img_annotate {
  height: -webkit-fill-available;
}

.pr_img_annotate .label_sec .label_holder .l_entity .option_scroll .disable_text_highlighting .label_right {
  position: absolute;
  right: 10px;
}

.pr_img_annotate {
  background: #e0e0e0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pr_img_annotate .work_sec .w_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background: #aba8a8;
  padding: 5px;
  height: 45px;
  position: relative;
}

.pr_img_annotate .work_sec .w_header .l_white {
  color: #fff !important;
}

.pr_img_annotate .work_sec .w_img-sec {
  min-height: 400px;
}

.pr_img_annotate .work_sec .w_img-sec .w_holder {
  padding: 5px;
  width: 100%;
  overflow: auto;
  max-height: 65vh;
}

.pr_img_annotate .work_sec .w_img-sec .w_holder .pan-zoom-element1 {
  width: 100%;
  overflow: auto;
  height: 100vh;      
  flex-wrap: wrap;
  flex-direction: column-reverse;
  align-content: flex-end;
justify-content: space-around;}
.pr_img_annotate .work_sec .w_img-sec .w_holder .pan-zoom-element {
  width: 100%;
  overflow: auto;
  height: 100vh;      
}
.normalImg {
  height: 410px;
  width: 410px;
}
.myZoomyimg {
  position: absolute;
  bottom: 10px;
  right: 420px;
}

.pr_img_annotate .label_sec {
  background: #d4d4d4;
}

.pr_img_annotate .label_sec .label_holder .l_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: flex !important;
  height: 45px;
  background: #aba8a8;
  padding: 5px;
}

.pr_img_annotate .label_sec .label_holder .l_header .l_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_img_annotate .label_sec .label_holder .l_header .l_box .button {
  text-transform: uppercase;
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 10px;
}

.pr_img_annotate .label_sec .label_holder .l_header .l_box .button .icon {
  margin-right: 0 !important;
}

.pr_img_annotate .label_sec .label_holder .l_header .l_box .button.blue {
  background: #12c4fc !important;
}

.pr_img_annotate .label_sec .label_holder .l_entity {
  padding: 0px 5px;
}

.pr_img_annotate .label_sec .label_holder .l_entity .option_scroll {
  max-height: 320px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background: #ccc;
}

.pr_img_annotate .label_sec .label_holder .l_entity .option_scroll .disable_text_highlighting {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.pr_img_annotate .label_sec .label_holder .l_entity .option_scroll .disable_text_highlighting .label_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 5px;
  height: 20px;
  min-width: 50px;
  width: auto !important;
}

.pr_img_annotate .label_sec .label_holder .l_entity .option_scroll .disable_text_highlighting .label_right>div {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  max-width: 80%;
}

.pr_img_annotate .label_sec .label_holder .l_entity .option_scroll .disable_text_highlighting .label_right>div #Tool {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.pr_img_annotate .label_sec .label_holder .l_entity .option_scroll .disable_text_highlighting .label_right .label {
  height: 20px;
  border-radius: 10px;
  min-width: 50px;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pr_img_annotate .label_sec .label_holder .l_icon_section {
  padding: 0px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 5px;
}

.pr_img_annotate .label_sec .label_holder .l_icon_section .e_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 10px 0px;
}

.pr_img_annotate .label_sec .label_holder .l_icon_section .button {
  background: #12c4fc;
  border-radius: 15px;
}

/*----Image Classify------------*/
.img_classify {
  background: #e0e0e0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: -webkit-fill-available;
}

.img_classify .work_sec {
  padding: 0 5px 0 0;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.img_classify .work_sec .pan-zoom-element {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100% !important;
}


.img_classify .work_sec .pan-zoom-element1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100% !important;
}

.img_classify .work_sec .f_labels {
  margin: 5px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.img_classify .work_sec .f_labels .label {
  min-width: 80px;
  margin: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 15px;
}

.img_classify .work_sec .note_txt .button {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.img_classify .work_sec .note_txt input {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.img_classify .r_box {
  background: #d4d4d4;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.img_classify .r_box .l_sidebox {
  padding: 0px 5px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-height: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}

.img_classify .r_box .l_sidebox .t_label {
  text-align: left;
  font-size: 17px !important;
  font-weight: normal;
  background: transparent;
}

.img_classify .r_box .l_sidebox .label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 12px;
  border-radius: 15px;
}

.img_classify .r_box .l_sidebox .search_input .input {
  width: 100%;
}

.img_classify .r_box .clasify_btn .button {
  background: #12c4fc !important;
  color: #fff;
  border-radius: 15px;
}

/*----Document Annotation------------------*/
.pr_doc_annotate {
  margin-left: 6%;
  margin-right: 3%;
}

.pr_doc_annotate .project_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
}

.pr_doc_annotate .project_sec .project_title {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_doc_annotate .project_sec .project_title h4 {
  font-weight: 600;
}

.pr_doc_annotate .project_sec .project_title h4:after {
  content: "|";
  color: #e2e2e2;
  padding: 0px 10px 0px 10px;
}

.pr_doc_annotate .project_sec .project_title .btn_back {
  width: 25px;
  height: 25px;
  background: #12c4fc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 5px;
}

.pr_doc_annotate .project_sec .project_title .btn_back img {
  width: 10px;
}

.pr_doc_annotate .project_sec .tools {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
}

.pr_doc_annotate .project_sec .tools li {
  padding-left: 5px;
  position: relative;
}

.pr_doc_annotate .project_sec .tools li .selectUI {
  height: 30px;
  border-radius: 5px;
  border: 1px #dfe2e5 solid;
}

.pr_doc_annotate .project_sec .tools li .filterIcon {
  border: 0;
  background: transparent;
  vertical-align: text-bottom;
  cursor: pointer;
}

.pr_doc_annotate .project_sec .tools li .filterIcon img {
  width: 80%;
}

.pr_doc_annotate .project_sec .tools li .filterIcon:hover .dropBox {
  display: block !important;
}

.pr_doc_annotate .project_sec .tools li .downloadIcon {
  border: 0;
  background: transparent;
  vertical-align: text-bottom;
  cursor: pointer;
}

.pr_doc_annotate .project_sec .tools li .downloadIcon .anticon {
  font-size: 24px;
}

.pr_doc_annotate .project_sec .tools li .dropBox {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 6px -4px #000;
  box-shadow: 0px 0px 6px -4px #000;
  position: absolute;
  z-index: 999;
  right: 0;
  top: 30px;
  width: 300px;
  display: none;
}

.pr_doc_annotate .project_sec .tools li .dropBox h3 {
  text-transform: uppercase;
  font-size: 0.8em;
  color: #979797;
  padding-left: 5px;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li label {
  font-weight: bold;
  font-size: 0.7em;
  color: #5b5b5b;
  padding-bottom: 5px;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .react-datepicker-component {
  width: 100%;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .react-datepicker-input {
  min-width: 100px;
  height: 25px;
  background: transparent;
  border-radius: 30px;
  border: 1px solid #dfe2e5;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .react-datepicker-input .calendar {
  font-size: .9em;
  margin-bottom: 6px;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 5px;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_select .dropdown {
  min-height: 25px !important;
  border-radius: 30px;
  font-size: .7em !important;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_select .dropdown .icon {
  top: 1.3em !important;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_select .item {
  font-size: .7rem;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_datepik {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pr_doc_annotate .project_sec .tools li .dropBox .filterTools li .doc_datepik .react-datepicker-input {
  height: 28px;
}

.pr_doc_annotate .project_sec .tools li .dropBox:hover {
  display: block !important;
}

.pr_doc_annotate .project_sec .project_set {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_doc_annotate .project_sec .project_set .icn_btn {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_doc_annotate .project_sec .project_set .icn_btn .action_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #12c4fc;
  padding: 5px 10px;
  border-radius: 29px;
  color: #fff;
  min-width: auto;
}

.pr_doc_annotate .project_sec .project_set .icn_btn .action_btn span {
  margin-left: 5px;
}

.pr_doc_annotate .project_sec .project_set .main_popover .button {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  color: #fff !important;
  background: #12c4fc !important;
  margin-right: 0 !important;
}

.pr_doc_annotate .project_sec .project_set .main_popover .button .icon {
  color: #fff !important;
  font-size: 1.1em;
}

.pr_doc_annotate .project_view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pr_doc_annotate .project_view .box_scrol {
  height: 720px;
  overflow-y: auto;
}

.pr_doc_annotate .project_view .right_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_doc_annotate .project_view .right_box .sec_top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .sec_tilte {
  margin: 20px 0px;
  text-transform: uppercase;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .sec_tilte:after {
  display: inline-block;
  content: "";
  border-top: 0.1rem solid #12c4fc;
  width: 2rem;
  margin: 0 1rem 0 0.5rem;
  -webkit-transform: translateY(-0.29rem);
  transform: translateY(-0.29rem);
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .crt_set {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .crt_set .ctrl_btn {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .crt_set .ctrl_btn img {
  width: 15px;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .crt_set .btn_default {
  background: #DADADA;
}

.pr_doc_annotate .project_view .right_box .sec_top .r_box .crt_set .btn_active {
  background: #12c4fc;
}

.pr_doc_annotate .project_view .right_box .sec_top .status_area {
  border: 1px solid #DFE2E6;
  padding: 10px;
  height: auto;
  overflow-y: auto;
  border-radius: 5px;
  width: 100%;
}

.pr_doc_annotate .project_view .right_box .sec_top .status_area h5 {
  color: #12C4FC;
  text-transform: uppercase;
}

.pr_doc_annotate .project_view .right_box .sec_top .status_area .status_btn {
  background: #DADADA;
  padding: 5px 15px;
  border-radius: 15px;
  color: #6B6C6F;
  font-size: .8em;
  border: none;
  min-width: 100px;
}

.pr_doc_annotate .project_view .right_box .sec_top .status_area p {
  font-size: .85em;
  margin: 5px 3px 5px 0px;
}

.pr_doc_annotate .project_view .right_box .sec_top .action_btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.pr_doc_annotate .project_view .right_box .sec_top .action_btns .status_link {
  background: #12c4fc;
  border-radius: 5px;
  color: #fff;
  padding: 5px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  margin: 5px 0px;
}

.pr_doc_annotate .project_view .right_box .sec_bottom h3 {
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
}

.pr_doc_annotate .project_view .right_box .sec_bottom .eva_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pr_doc_annotate .project_view .right_box .sec_bottom .eva_btn .btn_base {
  font-weight: 600;
  border-radius: 30px;
  padding: 5px 15px;
  text-align: center;
  min-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}

.pr_doc_annotate .project_view .right_box .sec_bottom .eva_btn .btn_default {
  background: #DADADA;
}

.pr_doc_annotate .project_view .right_box .sec_bottom .eva_btn .btn_active {
  background: #45B0E6;
}

.pr_doc_annotate .project_view .right_box .sec_bottom .eva_btn .btn_incorrect {
  background: #e0595d;
}

.pr_doc_annotate .project_view .right_box .sec_bottom .eva_btn .btn_correct {
  background: #12c4fc;
}

.pr_doc_annotate .msg_popover {
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background: #fff;
  padding: 10px;
}

.pr_doc_annotate .full_worksec {
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background: #fff;
  padding: 10px;
  min-height: 300px;
  overflow-y: auto;
}

.pr_doc_annotate .ctrl_btns {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_doc_annotate .ctrl_btns .action_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #12c4fc;
  padding: 5px 10px;
  border-radius: 29px;
  color: #fff;
  min-width: auto;
}

.pr_doc_annotate .ctrl_btns .action_btn span {
  margin-left: 5px;
}

/*----custom tree select------------------*/
.custom_taginput .react-tagsinput {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding-top: 0;
}

.custom_taginput .react-tagsinput .react-tagsinput-input {
  margin: 0;
  padding: 0;
  height: 26px;
}

.custom_taginput .react-tagsinput .react-tagsinput-tag {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f3f3f3;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: default;
  float: left;
  margin-right: 4px;
  max-width: 99%;
  position: relative;
  overflow: hidden;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0 5px 0 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  height: 20px;
  line-height: 20px;
}

.custom_taginput .react-tagsinput .react-tagsinput-tag .react-tagsinput-remove {
  color: rgba(0, 0, 0, 0.43);
  font-size: 12px;
}

.pr_profile .profile_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0px 30px 0px 0px;
}

.pr_profile .profile_sec .upload_btn {
  min-width: 70px;
  min-height: 35px;
  border-radius: 20px;
  border: none;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #12c4fc;
  color: #fff;
}

.pr_profile .profile_sec .upload_btn .icon {
  margin: 0;
  width: 20px;
  height: 20px;
}

.pr_profile .label_set {
  margin-bottom: 10px;
}

.pr_profile .label_set label {
  font-size: 16px;
}

.pr_profile .info_sec {
  border-left: 1px solid #ececec !important;
  padding: 0px 0px 0px 20px !important;
}

/*----Change Password------------------*/
.pr_changepassword{
  margin-left: 100px;
  margin-top: 50px;
}

.pr_changepassword .title_section {
  margin-bottom: 15px !important;
}

.pr_changepassword .hold-sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_changepassword .hold-sec .password_input {
  width: calc(100% / 3);
  padding: 0px 10px;
}

.pr_changepassword .hold-sec .password_input input[type=password] {
  border-radius: 20px;
}

.pr_changepassword .password_submit {
  border-radius: 20px;
  background: #12c4fc;
  color: #fff;
}

.pr_changepassword .password_submit:hover {
  background: #12c4fc;
  color: #fff;
}

.pr_changepassword .message {
  position: unset !important;
  max-width: 70% !important;
  text-align: center;
  margin: 1% auto;
}

/*----Medical Image Annotation------------------*/
.pr_medical_annotate {
  background: #464646;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.pr_medical_annotate .m_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 5px 20px;
  height: 50px;
  background: #2E2E2E;
}

.pr_medical_annotate .m_header .m_control .range_label {
  color: #898989;
  border-right: 1px solid rgba(249, 242, 242, 0.2);
  padding-right: 5px;
}

.pr_medical_annotate .m_header .m_action_btn {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_medical_annotate .m_header .m_action_btn .maction_btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #12c4fc;
  padding: 5px 10px;
  border-radius: 29px;
  color: #fff;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pr_medical_annotate .m_header .m_action_btn .m_imgaction_btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #12c4fc;
  padding: 5px 10px;
  border-radius: 29px;
  color: #fff;
  width: 30px;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pr_medical_annotate .medical_tool .item:hover {
  background: #898989 !important;
}

.pr_medical_annotate .medical_tool .item:hover .icon {
  background: #898989 !important;
  color: #fff !important;
  width: 100%;
}

.pr_medical_annotate .medical_tool .item .icon {
  width: 100% !important;
}

.pr_medical_annotate .m_holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pr_medical_annotate .m_holder .m_left {
  width: 3%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pr_medical_annotate .m_holder .m_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
}

.pr_medical_annotate .m_holder .seg_new {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.pr_medical_annotate .m_holder .seg_new .pan-zoom-element {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*--------hits- screen - ----*/
.pr_hviews .r_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 0px 15px;
  background: #2e2e2e;
}

.pr_hviews .r_box .sec_tilte {
  margin: 15px 0px;
  text-transform: uppercase;
  color: #fff;
}

.pr_hviews .r_box .sec_tilte:after {
  display: inline-block;
  content: "";
  border-top: 1px solid #12c4fc;
  width: 2rem;
  margin: 0 1rem 0 0.5rem;
  -webkit-transform: translateY(-0.29rem);
  transform: translateY(-0.29rem);
}

.pr_hviews .r_box .crt_set {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pr_hviews .r_box .crt_set .ctrl_btn {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pr_hviews .r_box .crt_set .ctrl_btn img {
  width: 20px;
}

.pr_hviews .r_box .crt_set .btn_default {
  background: #DADADA;
}

.pr_hviews .r_box .crt_set .btn_active {
  background: #12c4fc;
}

.pr_hviews .r_box .m_titlebtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pr_hviews .r_box .m_titlebtn .m_btn {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 20px !important;
}

.pr_hviews .m_actionbtn {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pr_hviews .m_actionbtn .m_base {
  height: 30px;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 3px;
  width: 100%;
}

.pr_hviews .m_actionbtn .m_base .icon {
  margin-right: 5px !important;
}

.pr_hviews .status_area {
  border: 1px solid #DFE2E6;
  padding: 10px;
  height: auto;
  overflow-y: auto;
  border-radius: 5px;
  width: 100%;
}

.pr_hviews .status_area h3 {
  color: #12C4FC;
  text-transform: uppercase;
}

.pr_hviews .status_area .status_btn {
  background: #DADADA;
  padding: 5px 15px;
  border-radius: 5px;
  color: #6B6C6F;
  font-size: .8em;
  border: none;
  min-width: 100px;
}

.pr_hviews .status_area h4 {
  color: #fff;
}

.pr_hviews .status_area h5 {
  color: #fff;
}

.pr_hviews .status_area p {
  font-size: .8em;
  color: #fff;
}

.pr_hviews .action_btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.pr_hviews .action_btns .status_link {
  background: #12c4fc;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  margin: 5px 0px;
}

.pr_hviews .sec_bottom h3 {
  text-align: center;
  font-weight: 400;
  margin-bottom: 10px;
  color: #fff;
}

.pr_hviews .sec_bottom .eva_btn {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pr_hviews .sec_bottom .eva_btn .btn_base {
  font-weight: 400;
  border-radius: 30px;
  padding: 5px 15px;
  text-align: center;
  min-height: 32px;
  color: #fff;
  font-size: 12px;
  border: none;
}

.pr_hviews .sec_bottom .eva_btn .btn_default {
  background: #DADADA;
  border: none;
}

.pr_hviews .sec_bottom .eva_btn .btn_active {
  background: #45B0E6;
  border: none;
}

.pr_hviews .sec_bottom .eva_btn .btn_incorrect {
  background: #e0595d;
}

.pr_hviews .sec_bottom .eva_btn .btn_correct {
  background: #12c4fc;
}

/*--------hits- screen filter - ----*/
.hits_filter {
  -webkit-box-shadow: -1px 1px 5px #ccc;
  box-shadow: -1px 1px 5px #ccc;
  margin-left: 10px;
  padding: 10px 15px;
  min-width: 20%;
  border-radius: 3px;
  position: absolute;
  right: 3%;
  background: #fff;
  top: 7%;
  z-index: 99;
}

.hits_filter .dropBox_set .sec_tilte {
  margin: 20px 0px;
  text-transform: uppercase;
  font-weight: 600;
}

.hits_filter .dropBox_set .sec_tilte:after {
  display: inline-block;
  content: "";
  border-top: 0.1rem solid #12c4fc;
  width: 4rem;
  margin: 0 1rem 0 0.5rem;
  -webkit-transform: translateY(-0.29rem);
  transform: translateY(-0.29rem);
}

.hits_filter .dropBox_set .hold_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 10px;
}

.hits_filter .dropBox_set .hold_sec .selection {
  width: 100%;
}

.hits_filter .dropBox_set .input-uniform {
  width: 100%;
}

.hits_filter .dropBox_set .input-uniform .input {
  width: 100%;
}

.hits_filter .dropBox_set .input-uniform .input .react-datepicker-component {
  width: 100% !important;
}

.hits_filter .dropBox_set .input-uniform .input .react-datepicker-component .react-datepicker-input {
  border-radius: 20px !important;
  width: 100%;
}

/*----Table workspace------------------*/
.table_workspace .ant-table-thead tr th {
  background: #12c4fc !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.table_workspace .ant-table-tbody tr td {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.65);
}

.table_workspace .ant-table-pagination {
  float: none !important;
  text-align: center;
}

.ant-select-dropdown {
  width: auto !important;
}

.vAlignFix {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

/*----Search section------------------*/
.archPlaceFix {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.archPlaceFix .search_h {
  -webkit-box-flex: initial !important;
  -ms-flex-positive: initial !important;
  flex-grow: initial !important;
}

.search_h {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.search_h .search_icn {
  font-size: 24px;
  margin-left: 5px;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #12c4fc;
  margin-bottom: 8px;
  cursor: pointer;
}

.search_h .search_wrap {
  /*width:600px;*/
  position: relative;
  /* background-color: #00b33c; */
}

.search_h .search_wrap .search_sec {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.search_h .search_wrap .search_sec .form_search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.search_h .search_wrap .search_sec .form_search .searchTerm {
  width: 100%;
  border: 1px solid #dfe1e5;
  border-right: none;
  border-radius: 0;
  outline: none;
  color: #ababab;
  height: 40px;
  padding: 5px;
  font-weight: 400;
}

.search_h .search_wrap .search_sec .form_search .searchButton {
  width: 40px;
  height: 40px;
  border: 1px solid #dfe1e5;
  background: transparent;
  text-align: center;
  color: #dfe1e5;
  border-radius: 0px 3px 3px 0px;
  cursor: pointer;
  font-size: 20px;
  border-left: none;
}

.search_h .search_wrap .search_sec .search_h .search_wrap .search_sec .search_select .search_txt.ant-select-enabled .ant-select-selection {
  border-right: 0;
}

.search_h .search_wrap .search_sec .search_select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.search_h .search_wrap .search_sec .search_select .search_txt .ant-select-selection {
  height: 40px;
  width: 160px;
  font-size: 13px;
  border: 1px solid #dfe1e5;
}

.search_h .search_wrap .search_sec .search_select .search_txt .ant-select-selection .ant-select-selection__rendered {
  height: 40px;
  line-height: 37px;
}

.search_h .search_wrap .search_sec .search_select .search_type {
  min-width: 240px;
}

.search_h .search_wrap .search_sec .search_select .search_type .ant-select-selection {
  height: 40px;
  /*width: 400px;*/
  font-size: 13px;
  border: 1px solid #dfe1e5;
  border-left: none;
}

.search_h .search_wrap .search_sec .search_select .search_type .ant-select-selection .ant-select-selection__rendered {
  height: 40px;
  line-height: 37px;
}

.search_h .search_wrap .search_sec .search_select .searchTerm {
  border-left: none;
}

.search_h .search_wrap .search_sec .search_select .ant-select-focused .ant-select-selection {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.search_h .search_wrap .search_drop {
  position: absolute;
  right: 0;
  z-index: 99999;
  max-width: 354px;
  background: #ffff;
  padding: 0px 5px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #dfe1e5;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3), 0 1px 3px 1px rgba(65, 69, 73, 0.15);
  box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3), 0 1px 3px 1px rgba(65, 69, 73, 0.15);
}

.search_h .search_wrap .search_drop .list_holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 0px;
  font-size: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.search_h .search_wrap .search_drop .list_holder .s_word {
  width: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
}

.search_h .search_wrap .search_drop .list_holder .w_word {
  background: #12c4fc;
}

.search_h .search_wrap .search_drop .list_holder .p_word {
  background: #a7a7a7;
}

.search_h .search_wrap .search_drop .list_holder:hover {
  background: #ececec;
  cursor: pointer;
}

.search_h .search_wrap .search_drop .blank_text {
  font-size: 14px;
  color: #ccc;
  margin: 10px !important;
}

.search_h .search_wrap .drop-hide {
  display: none;
}

.sarc_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sarc_btn .search_delete .anticon {
  color: #e0595d;
  font-size: 18px;
}

.table_v .table_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.table_v .table_link .s_word {
  width: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
}

.table_v .table_link .w_word {
  background: #12c4fc;
}

.table_v .table_link .p_word {
  background: #a7a7a7;
}

/*----custom title------------------*/
.tilte_display1 {
  text-align: left;
  text-transform: uppercase;
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 18px;
}

.tilte_display {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 18px;
}

.flx_rowset {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.txt-captalize {
  text-transform: capitalize;
}

table {
  width: 100%;
}

.project_view .p_view:last-child {
  padding-right: 0;
}

.label_set a:last-child {
  margin-right: 0;
}

.project_dec h4:last-child::after {
  display: none;
}

.filterTools>li input[type="text"]+img {
  position: absolute;
  right: 16px;
  top: 25px;
  width: 14px;
  cursor: pointer;
}

.imgInfo>span:nth-child(3) {
  text-align: right;
  width: 160px;
  color: #61656e;
}

.imgInfo>span:nth-child(2) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #5b5b5b;
  font-weight: bold;
}

.imgInfo>span:nth-child(1) {
  width: 47px !important;
  padding-right: 0 !important;
  text-align: center;
  cursor: pointer;
}

.filterIcon:hover+.dropBox,
.dropBox:hover {
  display: block !important;
}

.dropBox:before {
  content: "";
  position: absolute;
  right: 10px;
  top: -12px;
  border-width: 8px;
  border-color: transparent transparent white transparent;
  border-style: solid;
}

.filterTools>li select,
.filterTools>li input {
  width: 100%;
  border-radius: 30px;
  border: 1px #dfe2e5 solid;
  height: 25px;
  padding: 0 5px;
  font-size: 0.7em;
}

.filterTools>li input[type="text"] {
  padding-right: 25px;
  width: 100px;
}

.filterTools>li input[type="text"]+img {
  position: absolute;
  right: 16px;
  top: 25px;
  width: 14px;
  cursor: pointer;
}

.searchBox>input[type="text"] {
  width: 100%;
  border: 1px #dfe2e5 solid !important;
  height: 30px;
  border-radius: 30px;
  padding: 0 35px 0 8px;
  color: #979797;
  font-size: 0.7em;
  min-width: 200px;
  background: transparent !important;
}

.alin-c {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.imgCardView .dashboardSet {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-content: baseline;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.filterTools .react-datepicker.floating {
  right: 0;
}

.filterTools .react-datepicker-container .react-datepicker-top:before,
.filterTools .react-datepicker-container .react-datepicker-top:after {
  content: none !important;
}

.project_title h3:last-child::after {
  display: none;
}

.fw-5 {
  font-weight: 500;
}

.flex-colum {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flx-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.m-b-0 {
  margin-bottom: 0;
}

#main_menu .active svg path {
  fill: #12c4fc !important;
}

.m-r-10 {
  margin-right: 10px;
}

.m-h-720 {
  min-height: 720px;
}

.btn_clear {
  color: #ff7979;
}

.m-l-10 {
  margin-left: 10px;
}

.m-h-720 {
  min-height: 720px;
}

.m-l-30 {
  margin-left: 30px;
}

.custom_input {
  height: 45px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #dfe2e6;
  padding: 0px 8px;
}

.view_holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-40 {
  margin-top: 40px;
}

.m_action_btn a[disabled] {
  opacity: 0.4 !important;
  cursor: not-allowed;
}

.project_title h3:last-child::after,
.highlite_txt h4:last-child::after {
  display: none;
}

.fw-5 {
  font-weight: 500;
}

.flex-colum {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flx-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

#main_menu .active svg path {
  fill: #12c4fc !important;
}

.btn_clear {
  color: #ff7979;
}

.custom_input {
  height: 45px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #dfe2e6;
  padding: 0px 8px;
}

.view_holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.txt-center {
  text-align: center;
}

.label_basic {
  margin: 10px 0px !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

pre {
  padding: 10px !important;
}

.checkbox_m {
  margin-top: 10px !important;
}

.m_action_btn a[disabled],
.label_set a[disabled] {
  opacity: 0.4 !important;
  cursor: not-allowed;
}

.custom_filterdate .react-datepicker-component .react-datepicker-input.is-open input {
  border-radius: 30px !important;
}

.card_shadow {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 0 0 1px #d4d4d5, 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 0 0 1px #d4d4d5, 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.card_shadow>.list {
  width: 100%;
  margin: 0;
}

.custom_item_contribute {
  height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f4f2f2;
  padding: 10px !important;
  border-radius: 3px;
  margin-bottom: 5px;
}

.custom_item_contribute .content {
  padding: 0;
  background: transparent;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: 10px;
}

.contrib_title {
  padding: 0 15px;
  text-align: left;
  font-size: 22px;
}

.dropdown_mat>.item {
  color: #979797 !important;
}

.dropdown_mat>.item i.blue.icon {
  color: #000 !important;
}

/*----custom range slider-----------------*/
.range_slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
  margin-top: 10px;
}

.range_slider:hover {
  opacity: 1;
}

.range_slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #12C4FC;
  cursor: pointer;
}

.range_slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #12C4FC;
  cursor: pointer;
}

.m_control .range_label:last-child {
  border-right: none !important;
  padding-right: 0 !important;
}

.dropdown_mat i.blue {
  color: black !important;
}

.dropdown_mat.ui.dropdown .menu>.item {
  color: #797979 !important;
}

.m_action_btn a[disabled] {
  opacity: 0.4 !important;
  cursor: not-allowed;
}

/*----Title Style---------*/
.sec_tilte {
  margin: 20px 0px;
  text-transform: uppercase;
}

.sec_tilte:after {
  display: inline-block;
  content: "";
  border-top: 0.1rem solid #12c4fc;
  width: 4rem;
  margin: 0 1rem 0 0.5rem;
  -webkit-transform: translateY(-0.29rem);
  transform: translateY(-0.29rem);
}

/*----custom upload---------*/
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper .btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload_filebtn .ant-btn {
  min-height: 40px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.msg_txt p {
  font-size: 12px;
  margin-bottom: 10px;
}

.base_btnsubmit {
  background: #159588 !important;
  color: #fff !important;
  height: 40px !important;
  border-radius: 24px !important;
  font-weight: normal !important;
  min-width: 120px;
  margin-bottom: 15px !important;
}

.default_btn {
  font-weight: normal !important;
  border-radius: 20px !important;
  height: 35px !important;
}

.custom_taginput input,
.custom_taginput input::-webkit-input-placeholder {
  font-size: 12px !important;
}

.p-r-l-10 {
  padding: 0px 10px;
}

.expand_sec .anticon-caret-right,
.collapse_sec .anticon-caret-down {
  display: none;
}

.custom_tickbox {
  font-weight: 500;
  min-width: 130px;
  margin: 5px 10px 5px 0px;
  margin-left: 0 !important;
}

.menu_show {
  margin-left: 5% !important;
}

.menu_hide {
  margin-left: 0 !important;
}

.custom_filterdate react-datepicker-input {
  min-width: 100%;
}

.custom_filterdate react-datepicker-input input {
  width: 100%;
}

#doc_model {
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%);
  z-index: 99;
}

.clear_icon:hover .clear_holder svg path {
  fill: #fff !important;
}

.clear_icon:hover .clear_holder {
  color: #fff;
  background: #898989 !important;
}

.model_submit {
  text-align: center;
}

.model_submit .btn_cancel {
  border-radius: 20px;
  background: #9c9c9c !important;
  color: #fff !important;
  border: none;
}

.model_submit .btn_cancel:hover {
  color: #fff;
  background: #9c9c9c;
}

.model_submit .btn_apply {
  border-radius: 20px;
  background: #45b0e6 !important;
  color: #fff !important;
  border: none;
}

.model_submit .btn_apply:hover {
  color: #fff;
  background: #45b0e6 !important;
}

.project_nameinfo .proj_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 25px 0 5px;
}

.project_nameinfo .proj_sec p {
  font-size: 12px;
}

.box_full .content {
  width: 100% !important;
}

.h_filtericon {
  border: 0;
  background: transparent;
  cursor: pointer;
}

.ant_box .ant-checkbox-wrapper {
  margin-left: 0 !important;
}

.b_title,
.d_title {
  border-bottom: 1px solid #f9f9f9;
}

pre {
  padding: 5px !important;
  margin-bottom: 10px !important;
}

.margin-l-5 {
  margin-left: 5px !important;
}

.border-n {
  border: none;
}

.color-lite {
  color: #a7a4a4;
}

.txt-upper {
  text-transform: uppercase;
}

.clr_icon {
  color: rgba(0, 0, 0, 0.65);
}

.table_btn .btn_base {
  height: 37px;
  border-radius: 20px;
  min-width: 110px;
  color: #fff;
  border: 1px solid transparent;
  background: #12c4fc;
  font-size: 12px !important;
}

.table_btn .btn_base:hover {
  color: #fff;
  background: #12c4fc;
}

.table_workspace .number_format {
  text-align: center;
  width: 10%;
}

.table_workspace .w_name {
  text-align: left;
  width: 55%;
  /* background-color: yellow; */
}

.table_workspace .ant-checkbox-disabled {
  display: none;
}

.space_wrap {
  white-space: pre-wrap;
}

.m_height {
  min-height: 350px;
}

.m_height .model_select {
  min-height: 30px;
  border-radius: 20px !important;
}

.m_height .model_select .ant-select-selection--multiple {
  min-height: 30px;
  border-radius: 20px !important;
}

.m_height .model_select .ant-select-selection--multiple .ant-select-selection__rendered {
  height: 40px;
  overflow-y: auto;
}

.m_height .model_select .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__placeholder {
  padding-left: 5px;
}

.m_height .model_select .ant-select-selection--multiple .ant-select-selection__rendered ul li {
  height: 30px;
  line-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 20px;
}

.m_height .model_select .ant-select-selection--multiple .ant-select-selection__rendered ul li .ant-select-selection__choice__content {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 10px;
}

.m_height .model_select .ant-select-selection--multiple .ant-select-selection__rendered ul li .ant-select-selection__choice__content .ant-avatar {
  display: none;
}

.m_height p {
  font-size: 14px;
}

.m_height .m_checkbox {
  font-size: 14px;
  font-weight: 400;
}

.b-r-20 {
  border-radius: 20px !important;
}

.m_d_select {
  height: 135px;
  max-width: 488px !important;
  position: relative !important;
}

.m_d_select .ant-select-dropdown-menu-item {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
}

.m_d_select .ant-select-dropdown-menu-item .ant-avatar-circle {
  width: 25px;
  height: 25px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}

.m_d_select .ant-select-dropdown-menu-item .ant-avatar {
  height: 20px;
  margin-left: 10px;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 3px;
  border-radius: 20px;
  color: #fff;
}

.m_d_select .ant-select-dropdown-menu-item .ant-avatar .ant-avatar-string {
  left: 0 !important;
  line-height: 2 !important;
  font-size: 10px;
  display: block;
  width: 100%;
}

.m_d_select .ant-select-dropdown-menu {
  max-height: 135px;
}

.m_model .modal-dialog {
  top: 0;
}

.m_model .modal-dialog #submitgreen_btn {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.m_model .legend_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.m_model .legend_icon .h_legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.m_model .legend_icon .h_legend .legand_box {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 3px;
  border-radius: 20px;
  color: #fff;
  font-size: 10px;
  line-height: 2;
}

.m_model .legend_icon .h_legend .admin {
  background-color: #00b33c;
}

.m_model .legend_icon .h_legend .user {
  background-color: #8d8e8b;
}

.m_model .legend_icon .h_legend .guest {
  background-color: #C9E343;
}

.m_model .legend_icon .h_legend .qa {
  background-color: #43BCE3;
}

.f-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.breadcrum-sec {
  margin: 20px 10px;
}

.breadcrum-sec .sec_holder {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.breadcrum-sec .sec_holder .d_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.breadcrum-sec .sec_holder .tilte_display {
  text-transform: capitalize;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
}

.breadcrum-sec .sec_holder .pro_width {
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui .large .label {
  cursor: pointer;
}

.m_list .ant-select-dropdown-menu-item:hover:after {
  color: transparent !important;
}

.medical_view .tools .downloadIcon {
  border: 0;
  background: transparent;
  vertical-align: text-bottom;
  cursor: pointer;
  margin-right: 5px;
}

.medical_view .tools .downloadIcon .anticon {
  font-size: 28px;
}

.s_model .ant-modal-header {
  border: none;
}

.s_model .ant-modal-header .ant-modal-title {
  font-size: 20px;
}

.s_model .ant-modal-footer {
  border: none;
  padding: 0px 10px 20px 10px;
}

.s_model .m_summary li {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 5px 0px;
}

.s_model .m_summary li label {
  min-width: 200px;
  margin-bottom: 0;
}

.t_titleback .btn_back {
  width: 25px;
  height: 25px;
  background: #12c4fc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 5px;
}

.t_titleback .btn_back img {
  width: 10px;
}

.btn_green {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  background: #12c4fc !important;
  padding: 5px 10px !important;
  border-radius: 29px !important;
  color: #fff !important;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn_green img {
  margin-right: 5px;
}

.btn_green #icon_l {
  margin-right: 5px !important;
}

.btn_danger {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  background: #e0595d !important;
  padding: 5px 10px !important;
  border-radius: 29px !important;
  color: #fff !important;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn_danger img {
  margin-right: 5px;
}

.btn_danger #icon_l {
  margin-right: 5px !important;
}

.div_shodow {
  background: #fff;
  -webkit-box-shadow: 1px 1px 2px #ccc;
  box-shadow: 1px 1px 2px #ccc;
  border-radius: 3px;
  padding: 10px;
}

.doc_section .b_section {
  min-height: auto !important;
}

.help_icn .icon {
  font-size: 11px !important;
}

.l_blabel {
  padding: 5px 10px;
  height: 25px;
  margin: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  line-height: normal;
}

.l_blabel.compact.floating.inline.labeled.scrolling.upward.dropdown.icon {
  position: static;
}

.l_blabel i.dropdown.icon {
  width: auto !important;
  right: 10px;
  top: 0px !important;
  left: auto !important;
  font-size: 1.5em !important;
  bottom: auto !important;
}

.workspace_select .ant-select-selection {
  height: 37px;
}

.workspace_select .ant-select-selection__placeholder {
  line-height: 35px;
  height: 35px;
}

.workspace_select .ant-select-selection-selected-value {
  line-height: 35px;
  height: 35px;
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.45 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  pointer-events: none !important;
}

.box_area {
  width: 100%;
}

.info_width {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.guidelines {
  overflow-y: auto;
  height: 40px;
  border: 1px solid #cecece;
  border-radius: 5px;
  width: 300px;
  padding: 3px;
}

.t_loader {
  position: absolute !important;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.02);
  height: 100% !important;
  max-height: 100% !important;
}

.recharts-wrapper text.bar_text {
  font-size: 12px;
  font-family: tahoma;
}

#eva_holder .label_box {
  min-height: 70px;
  border: 1px solid #f0f0f1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  margin: 0px 10px 0px 0px !important;
}

#eva_holder .label_box .value {
  font-size: 30px !important;
  font-weight: 300 !important;
  color: #474751 !important;
}

#eva_holder .label_box .label {
  color: #979797 !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  cursor: pointer;
}

.hview_label{
  /* background-color: #00b33c; */
  margin-top: 70px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* align-items: center; */
  /* justify-content: center; */
}

.hview_label .h_icons {
  display: none;
}

.hview_label #d_icon {
  margin-right: 0 !important;
}

.hview_label .button {
  margin-right: 2px;
}

.table_workspace .ant-table-column-sorter .anticon-caret-down,
.table_workspace .ant-table-column-sorter .anticon-caret-up {
  color: #fff;
}

.table_workspace .ant-table-thead tr th>span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.table_workspace .ant-table-thead tr .ant-table-selection-column>span {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.f_between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.f_close {
  position: absolute;
  right: 5%;
  top: 4%;
  font-size: 16px;
  color: #aba8a8;
  cursor: pointer;
}

.f_datesec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.f_datesec .ant-calendar-picker {
  width: 100% !important;
}

.f_datesec .ant-calendar-picker .ant-calendar-picker-input {
  height: 38px;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 14px;
}

.f_datesec .ant-calendar-picker:nth-child(1) {
  margin-right: 5px;
}

.f_datesec .ant-calendar-picker:hover .ant-calendar-picker-input {
  border-color: rgba(34, 36, 38, 0.35) !important;
}

.c_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.c_flex .c_holder {
  max-width: 80%;
  overflow: auto;
}

.c_flex .c_holder .recharts-wrapper {
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
}

.c_flex .c_holder .recharts-wrapper .recharts-legend-wrapper {
  bottom: 0;
}

.c_flex .c_holder .recharts-wrapper .recharts-legend-wrapper .recharts-default-legend {
  text-align: left !important;
  margin-bottom: 5px;
}

.c_flex .c_holder .recharts-wrapper .recharts-legend-wrapper .recharts-default-legend .recharts-legend-item-text {
  font-size: 12px;
}

.c_flex .c_holder .recharts-wrapper .recharts-tooltip-wrapper .recharts-tooltip-label {
  color: #000;
  font-weight: 500;
}

.c_flex .c_holder .recharts-wrapper .recharts-tooltip-wrapper .recharts-tooltip-item-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 600px;
}

.c_flex .c_holder .recharts-wrapper .recharts-tooltip-wrapper .recharts-tooltip-item-list .recharts-tooltip-item {
  margin-right: 5px;
}

#doc_model .annotation {
  max-height: 100px;
  overflow-y: auto;
}

.file_wrap {
  /*width: 300px;*/
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-spin-nested-loading {
  width: 100%;
  height: 100%;
}

.card_disabled {
  cursor: not-allowed;
  opacity: 1.45 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  pointer-events: none !important;
}

.ant-notification-notice .ant-notification-notice-with-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ant-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-icon {
  font-size: 22px;
}

.ant-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-message {
  display: none;
}

.ant-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 30px;
  color: rgba(0, 0, 0, 0.65) !important;
}

.m_d_select {
  display: none;
}

.c-modal .m_d_select {
  display: block;
}

.filter_fix .hits_filter {
  position: fixed;
  right: 0;
  top: 16%;
}

.margin-t-b-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.helpIcon {
  margin-right: 0 !important;
  font-size: 14px !important;
  color: #fff;
}

#m-r-5 {
  margin-right: 5px !important;
}

.m_input .ant-select-search__field {
  border-width: 0 !important;
}

.m_input .ant-select-search__field:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.z-dropdown {
  z-index: 10005 !important;
}

.doc_successbtn {
  background: #12c4fc !important;
  border-radius: 15px !important;
  color: #fff !important;
}

.doc_dangerbtn {
  background: #e0595d !important;
  border-radius: 15px !important;
  color: #fff !important;
}

.custom_confirm {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  background: #fff;
  position: relative;
  border-radius: 3px;
}

.custom_confirm .custom_close {
  position: absolute;
  right: 2%;
  top: 3%;
  color: #c5c1c1;
  font-size: 12px;
}

.custom_confirm p {
  font-size: 12px;
}

.custom_confirm .btn_end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.custom_confirm .btn_end .btn_ok {
  min-width: 50px;
  background: #12c4fc;
  text-transform: uppercase;
  border: 1px solid #06afe4;
  color: #fff;
  padding: 3px;
  border-radius: 3px;
  font-size: 12px;
}

.custom_confirm .btn_end .btn_cancel {
  min-width: 70px;
  background: #fff;
  text-transform: uppercase;
  border: 1px solid #ccc;
  color: #adaaaa;
  padding: 3px;
  border-radius: 3px;
  font-size: 12px;
}

.bg_white {
  background: #fff;
}

.project_new .base_root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 30px;
}

.project_new .custom_textarea .field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.project_new .custom_textarea .field #instruction {
  border-color: rgba(34, 36, 38, 0.15);
  padding: 5px;
  border-radius: 3px;
  height: 20px;
}

.project_new .div-classify {
  padding: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.project_new .div-classify .field {
  margin-bottom: 0;
}

.project_new .c_collapse {
  text-transform: uppercase;
  font-size: 13px;
  background-color: #fbfbfb;
}

.project_new .c_collapse .ant-collapse-header {
  color: #949292 !important;
}

.project_new .r_submit {
  min-width: 120px;
  height: 40px;
  background: #45b0e6;
  border: none;
  border-radius: 30px;
  color: #fff;
}

.project_new .m-h-content {
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
}

.project_new .remove_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.project_new .remove_btn .btn_clear {
  background: #ff7979;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  text-transform: capitalize;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 25px;
  color: #fff;
}

.project_new #form_balance {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.project_new .c_label {
  font-size: 13.2px;
  font-weight: 800;
  color: #000;
}

.search_sec input::-webkit-input-placeholder {
  color: #ddddde !important;
}

.r {
  color: #e0595d !important;
}

.r .p_word {
  background: #e0595d !important;
}

.r .w_word {
  background: #e0595d !important;
}

.share_img {
  width: 15px;
  margin-top: 5px;
}

.ui.mini.label .txtLenghtTrim {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  line-height: normal;
}

#targetdiv .ui.large.label span {
  cursor: pointer;
}

#targetdiv .ui.large.label span+p {
  cursor: context-menu;
}

#targetdiv .ui.large.label span+p i.delete.icon {
  cursor: pointer;
}

.forCursorPointer div .ui.large.label {
  cursor: pointer;
}

.pr_project_detail .p_sampleview .p_det_box .entity_sec .well div .ui.mini.label {
  background-color: #12c4fc !important;
}

.words {
  height: 50vh;
  overflow-y: auto;
  background: transparent;
  padding: 8px;
  -webkit-box-shadow: inset 0 0 3px -1px #868686;
  box-shadow: inset 0 0 3px -1px #868686;
  margin: 5px;
  border-radius: 10px;
}

.cover {
  margin: 5px 10px 20px;
  border-radius: 10px;
}

.words .ui.large.label {
  font-weight: normal;
  font-size: .95em;
  line-height: normal;
  margin: 2px;
  overflow-y: scroll;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-height: 300px;
}

.words .ui.large.label span+p {
  display: inline-block;
  font-size: 1em;
  line-height: normal;
  margin-left: 15px;
}

.words .ui.large.label span+p i.delete.icon {
  margin: 0;
  height: auto;
  margin-left: 5px;
}

.words button.ui.small.button {
  display: block;
  margin: 20px 0 0;
  background: #12c4fc;
  color: #fff;
  border-radius: 30px;
}

.words button.ui.small.button i.teal.copy.icon:before {
  color: #fff;
}

.shortcutInfo b {
  text-transform: uppercase;
}

.shortcutInfo {
  font-size: .95em;
}

.entitiesBtns div .ui.large.label span {
  font-weight: normal;
  display: inline-block;
  margin: 0 15px 0 0;
  text-transform: capitalize;
}

.entitiesBtns {
  margin-top: 10px;
}

.checkOption>.field {
  display: inline-block;
  margin-right: 25px !important;
}

.tagSet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tagSet .ant-tag {
  margin-bottom: 5px;
}

.tagSet .tagSetEdit {
  width: 70px;
  text-align: center;
  font-size: 2em;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 auto !important;
}

.tagSet>div:first-child {
  border: 1px #d9d9d9 solid;
  padding: 5px 5px 2px 5px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 2px;
}

.ant-modal-wrap td span input+i {
  line-height: 29px;
  margin: 0px 0 0 5px;
  font-size: 1.25em;
  cursor: pointer;
  color: #df6f6f;
}

.slabel_wrap {
  max-width: 100px !important;
}

.b_labeloption>.text:first-child {
  max-width: 50px;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sarc_btn .btn_base {
  height: 40px;
  min-width: 120px;
  border: 1px solid transparent;
}

.sarc_btn .btn_base .anticon-inbox {
  font-size: 14px;
}

#pos_wrap {
  max-height: 12em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: auto;
}

#pos_wrap .label {
  padding: 0 5px 0px !important;
  border-radius: 20px;
}

.wcolum {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.crtlBtns .button {
  min-width: 100px;
}

.tbx-wrap {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  max-height: 470px;
  overflow-y: auto;
  min-height: 100px;
}

.txt-labels .t_label {
  text-align: left;
  font-size: 17px !important;
  font-weight: 400;
  background: transparent;
}

.txt-labels .bundle-set .t_flabel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 15px;
}

.txt-labels .txt-classify .button {
  background: #12c4fc !important;
  color: #fff;
  border-radius: 15px;
}

.l_blabel .ui.checkbox input.hidden+label {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video_anno_sec .well {
  padding: 0;
  margin: 5px 5px !important;
  border: 1px solid #e3e3e300;
}

.video_anno_sec .well input.v_input {
  width: 60px;
  border: 1px solid #ccc;
  margin: 0 5px;
}

.video_anno_sec .ui[class*="top left attached"].label {
  width: 100%;
  border-radius: 0;
}

.video_anno_sec .ui.top.attached.label+[class*="right floated"]+*,
.video_anno_sec .ui.top.attached.label:first-child+ :not(.attached) {
  margin-top: 2rem !important;
  width: calc(100% - 10px);
}

.flex_point_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 5px;
}

.timeline_con {
  font-size: 12px;
  padding: 7px 7px 0px 7px;
  text-align: left;
}

.time_tag_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 10px 10px 0 10px;
  margin-bottom: 0;
}

p.timeline_con~div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#p1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px;
}

#p3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#p4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

#p4 button.ui.mini.icon.button {
  padding: 5px;
}

.pr_doc_annotate .flex_box_design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pr_doc_annotate .flex_box_design .box_shodow {
  -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 10px;
}

.pr_doc_annotate .m-5 {
  margin: 5px;
}

.pr_doc_annotate .tag_btn_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pr_doc_annotate .tag_btn_sec .ui.large.label {
  width: 100%;
  margin: 5px 0 !important;
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  padding: 8px !important;
  font-size: 12px;
}

.pr_doc_annotate .btn_show_classify .button {
  background: #12c4fc !important;
  color: #fff;
  border-radius: 100px;
  width: 32px !important;
  height: 28px;
  padding: 0px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pr_doc_annotate .ui.labeled.icon.button,
.pr_doc_annotate .ui.labeled.icon.buttons .button {
  padding-left: 0 !important;
}

.pr_doc_annotate .ui.labeled.icon.button>.icon,
.pr_doc_annotate .ui.labeled.icon.buttons>.button>.icon {
  width: 100%;
  background: transparent;
}

.pr_doc_annotate #s2 {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.pr_doc_annotate .ui[class*="right labeled"].icon.button {
  padding: 0 !important;
}

.export_data_text {
  text-align: left;
  padding-bottom: 20px;
}

.video_anno_sec .range_sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  margin-left: 5px;
  margin-top: 10px;
}

.video_anno_sec .range_sec .range_slider {
  margin: 20px 0;
}

.btn_top_card button {
  padding: .78571429em;
  border-radius: 15px !important;
}

.input_ui_sec>.ui.mini.input {
  width: 100%;
}

.pr_doc_annotate .w-80 {
  width: 80% !important;
}

.CommentSec {
  width: 100%;
  margin-top: 15px;
}

.CommentSec .btn_correct {
  font-weight: 600;
  border-radius: 30px;
  padding: 5px 15px;
  text-align: center;
  min-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  min-width: 100px;
  font-size: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.l_blabel .dropdown .text {
  padding-right: 10px;
}

.attriBlockSection {
  width: 100%;
  padding: 0 40px;
  margin-bottom: 20px;
}

.attriBlockSection.noPadding {
  padding: 20px 0;
}

.attriBlockSection .addNewBlock {
  position: relative;
  background: transparent;
  border: 1.5px #38cdfc solid;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.attriBlockSection .addNewBlock:before,
.attriBlockSection .addNewBlock:after {
  content: "";
  background: #38cdfc;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.attriBlockSection .addNewBlock:before {
  width: 2px;
  height: 12px;
}

.attriBlockSection .addNewBlock:after {
  height: 2px;
  width: 12px;
}

.attriBlockSection .attriBlockSectionInner {
  border: 1px #e1e1e1 solid;
  padding: 5px 10px;
  border-radius: 5px;
}

.attriBlockSection .headerBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 5px 0;
}

.attriBlockSection .headerBlock li h3 {
  font-size: .98em;
  font-weight: bold;
}

.attriBlockSection .attriBlock {
  width: 100%;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.attriBlockSection .attriBlock li {
  width: 48%;
  padding: 8px 0;
}

.attriBlockSection .attriBlock li .attriBlockCover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.attriBlockSection .attriBlock li .attriBlockCover li {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.attriBlockSection .attriBlock li .attriBlockCover li.inputHolder {
  padding: 0;
  position: relative;
}

.attriBlockSection .attriBlock li .attriBlockCover li.inputHolder input[type="text"] {
  width: 100%;
  border: 1px #dfdfdf solid;
  height: 38px;
  border-radius: 4px;
  padding-right: 40px;
  padding-left: 5px;
}

.attriBlockSection .attriBlock li .attriBlockCover li.inputHolder .showData {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attriBlockSection .attriBlock li .attriBlockCover li.inputHolder:hover .actionBtn {
  display: block;
}

.attriBlockSection .attriBlock li .attriBlockCover li.inputHolder .actionBtn {
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  border-color: #F44336 !important;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: none;
}

.attriBlockSection .attriBlock li .attriBlockCover li.inputHolder .actionBtn:before,
.attriBlockSection .attriBlock li .attriBlockCover li.inputHolder .actionBtn:after {
  background: #F44336 !important;
}

.attriBlockSection .attriBlock li .attriBlockCover li.checkBoxHolder {
  max-width: 30px;
  width: 30px;
  padding: 0 10px 0 0;
  position: relative;
}

.attriBlockSection .attriBlock li .attriBlockCover li.checkBoxHolder input[type="checkbox"] {
  position: absolute;
  width: 17px;
  height: 17px;
  z-index: 1;
  opacity: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.attriBlockSection .attriBlock li .attriBlockCover li.checkBoxHolder input[type="checkbox"]:checked+span:after {
  display: block;
}

.attriBlockSection .attriBlock li .attriBlockCover li.checkBoxHolder span {
  position: absolute;
  width: 17px;
  height: 17px;
  border: 1px #d4d4d5 solid;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 3px;
}

.attriBlockSection .attriBlock li .attriBlockCover li.checkBoxHolder span:after {
  content: "";
  width: 10px;
  height: 6px;
  border: 2px #0d0d0d solid;
  position: absolute;
  top: 3px;
  left: 1.5px;
  border-width: 0 0 2px 2px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: none;
}

.flexLayout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flexLayout li {
  max-width: 48%;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.rotateImg {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 1.5em;
  margin: 0;
  padding: 0;
}

.rotateImg .icon.refresh:before {
  font-size: 16px;
}

.hitLabel {
  margin: 20px 5px 10px;
}

.hitLabelTag>div {
  margin-bottom: 5px;
}

.hitLabelTag {
  background: #fff;
  padding: 5px;
  margin-top: 5px;
  height: 140px;
  overflow: auto;
}

.colorPickerSize {
  font-size: .8em !important;
  position: relative;
  top: -1px;
  z-index: 0;
}

.colorPicker {
  position: relative;
}

.colorPicker>span {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
}

.colorPicker>span>div:first-child {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  left: 0;
}

.colorPickerCustom {
  POSITION: RELATIVE;
}

.colorPickerCustom span {
  position: relative;
  z-index: 9;
  left: -2px;
  TOP: 0;
}

.colorPickerCustom:after {
  content: "";
  background-image: url(../images/color-palette.svg);
  position: absolute;
  width: 20px;
  height: 20px;
  padding: 0 !important;
  top: 0;
  left: 0;
  background-position: center;
  background-size: contain;
}

.colorPickerCustom>span>div:first-child,
.colorPickerCustom>span>div:first-child>div {
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.hitTag {
  border-radius: 20px !important;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  width: auto !important;
}

.hitTagspan {
  margin-right: 14px !important;
  max-width: 160px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: text-bottom;
}

.hitTagspan+i.icon,
.hitTagspan+i.icon+i.icon {
  vertical-align: text-top;
  margin-top: -2px;
}

.textClassify {
  padding: 10px 10px 10px 0px !important;
  width: 75% !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.textClassify>div {
  width: 100% !important;
}

.lblwrap {
  max-width: 50px;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

#svgContainer g+g .circle_grab {
  r: 3;
}

#overlay {
  right: 95px;
  top: 95px !important;
  border-radius: 0 !important;
  position: fixed !important;
  left: auto !important;
  background-color: #fff;
}

.zoomIn {
  width: 30px;
  height: 30px !important;
  background-color: transparent !important;
  min-width: auto !important;
  color: #fff;
  border: 0 !important;
  text-align: center;
  line-height: 30px !important;
  margin: 0 3px;
}

.zoomIn:before {
  content: "\F00E";
  font-family: Icons;
  font-size: 1.50em;
}

.zoomIn:after {
  visibility: hidden;
  z-index: 1;
}

.zoomIn span {
  visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
}

.ant-switch-checked.zoomIn {
  background-color: #21c1f4 !important;
}

.selectAll label:after {
  content: "\f192" !important;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-family: Icons;
  font-size: 1.25em;
}

.selectLocked label:after {
  content: "\f2a1" !important;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-family: Icons;
  font-size: 1.25em;
}

.drag label:after {
  content: "\f0b2" !important;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-family: Icons;
  font-size: 1.25em;
}

.annotCheck {
  position: -webkit-sticky;
  position: sticky;
  width: 30px;
  height: 30px;
  display: block;
  right: 80px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  overflow: hidden;
  margin: 0 3px;
}

.annotCheck label {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 30px;
  margin: 0;
  border-radius: 50%;
  left: 0;
  top: 0;
  color: #fff;
}

.annotCheck label:after {
  content: "\f09c";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-family: Icons;
  font-size: 1.25em;
}

.annotCheck input {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
}

.annotCheck input[type="checkbox"]:checked+label {
  background-color: #21c1f4;
}

.annotCheck input[type="checkbox"]:checked+label:after {
  display: block;
}

.video-react-big-play-button {
  left: calc(50% - 1.5em) !important;
  top: calc(50% - 0.5em) !important;
}

.m-l-35 {
  margin-left: 35px;
}

img.interpolationImg {
  width: 18px !important;
  padding: 15px 0;
  opacity: 0.5;
}

.pr_medical_annotate .medical_tool .item:hover img {
  background: #898989 !important;
  opacity: 1;
}

.videoClassifytag .video-react {
  width: 100% !important;
}

.info-txt {
  font-size: 10px;
  width: 100%;
  margin: 0;
  text-transform: capitalize;
}

.info-txt .higlite-txt {
  color: #29abe2;
}

.flx-colum {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.list_holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 15px;
}

.list_holder textarea {
  color: var(--grey6) !important;
  font-weight: normal;
  font-size: 14px !important;
}

.custom_list {
  margin-bottom: 0 !important;
}

.login_error_con {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 400px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.login_error_con p {
  margin-bottom: 10px;
}

#btn_set {
  padding: .78571429em 1.5em !important;
}

.model_popup_con {
  padding: 20px;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
}

.model_popup_con .input_popup {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #cccccc;
  border-left: none;
  -o-border-image: initial;
  border-image: initial;
  padding: 5px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 0;
  margin-top: 15px;
}

.model_popup_con .ant-input:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(16, 142, 233, 0);
  box-shadow: 0 0 0 2px rgba(16, 142, 233, 0);
}

.p-0 {
  padding: 0 !important;
}

.btn_addlabel {
  padding: 0 !important;
  background: #a1818100 !important;
  color: black !important;
  font-size: 18px !important;
}

.menu_strip_con {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.taggercontainer {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.field {
  margin-bottom: 15px !important;
}

.field label {
  min-width: 10%;
}

.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
  padding: 16px 8px !important;
}

.ant-table-wrapper .ui.celled.table tr td,
.ant-table-wrapper .ui.celled.table tr th {
  border-left: 0px solid rgba(34, 36, 38, 0.1) !important;
}

.ant-table-wrapper .ui.table {
  border: 0;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: 0px solid #e9e9e9;
}

.width-100 {
  width: 100%;
}

.float-left {
  float: left;
}

.pad-6-em {
  padding: 0.6em;
}

.customTextarea {
  margin: 0px;
  width: calc(100% - 50px);
  height: 25px;
  background: white;
  border: 1px solid #cccccc80;
  border-radius: 4px;
  padding-left: 5px;
  margin-bottom: 5px;
}

.customTextarea~.table_actionbtn .clr_icon {
  background: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  margin-left: 5px;
  border: 0px solid #cccccc00;
}

.customTextarea:disabled {
  border: 0;
  margin-bottom: 0px;
  padding-left: 0;
  border: 1px solid #cccccc00;
}

.registerBase {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.registerBase .form_field {
  margin: 5px;
  width: calc(50% - 10px);
}

.registerBase .ant-select-selection {
  border: 0px solid #d9d9d9;
  outline: none;
}

.registerBase .ant-select-open .ant-select-selection,
.registerBase .ant-select-focused .ant-select-selection {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(16, 142, 233, 0);
  box-shadow: 0 0 0 2px rgba(16, 142, 233, 0);
}

.registerBase .ant-select-selection:active,
.registerBase .ant-select-selection:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(16, 142, 233, 0);
  box-shadow: 0 0 0 2px rgba(16, 142, 233, 0);
}

.ant-select-dropdown {
  width: unset !important;
  min-width: 300px !important;
}

.confirmationBtn {
  padding: 0 !important;
  margin-left: 20px;
  border-radius: 100px !important;
  height: 20px !important;
  width: 20px;
}

.field_flex_Warp {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ant-select-dropdown.m_list {
  top: calc(90vh) !important;
  width: calc(100vw - (32% - 0px)) !important;
  left: 129px !important;
  height: 100px !important;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px 0;
}

.setCustomHeight {
  height: 65vh !important;
}

pre#annotationDoc.m-b-0 {
  margin-bottom: 0 !important;
}

.moderatedCon .field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.moderatedCon .field textarea {
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  border-color: rgba(134, 134, 134, 0.28);
}

.m-l-10 {
  margin-left: 10px !important;
}

.posTagingcon {
  padding: 0 5px 0 0;
}

.addBgGrey {
  background-color: #f5f5f5;
  border-radius: 4px !important;
  margin: 0;
}

.customLeaderBoardTable {
  background: #fff;
  padding: 20px 10px;
  -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-top: 33px !important;
  overflow-y: auto;
}

.customLeaderBoardTable td,
.customLeaderBoardTable th {
  padding: 15px !important;
  white-space: nowrap;
  border-bottom: 1px solid #ddd !important;
}

.customLeaderBoardTable th {
  font-size: 14px;
}

.p_sampleview .w-80.work_sec {
  width: 100% !important;
}

.bdrUnset {
  border-left: 1px solid #dfe1e5 !important;
}

.flexCenter {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.project_view {
  margin-bottom: 30px;
  /* background-color: #06afe4; */
  display: flex;
  /* flex-wrap: wrap; */
}

.custom_bredcrumb_keyboard {
  padding: 0;
  background-color: transparent;
  font-size: 18px !important;
}

.custom_bredcrumb_keyboard .ui.breadcrumb .section {
  color: rgba(0, 0, 0, 0.65) !important;
}

.custom_bredcrumb_keyboard .active.section {
  font-weight: unset !important;
}

.fullWidth_keyboard {
  width: 100% !important;
}

.keyboard_Subtext {
  font-size: 14px;
  opacity: .7;
}

.keyboard_text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-left: 15px !important;
}

.keyboard_text h3 {
  margin-left: 0px !important;
}

.keyboard_con .input_set {
  width: 100%;
}

.keyboard_con .ui.form {
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.keyboard_con .field {
  width: calc(33.3% - 20px);
  margin: 15px 10px !important;
}

.keyboard_con .keyboard_custom_field {
  width: calc(33.3% - 20px);
}

.keyboard_con .keyboard_custom_field .field {
  width: 100% !important;
}

.wordBreakFix .table td,
.wordBreakFix .table th {
  word-break: break-word;
  white-space: nowrap;
}

.ui.table tfoot th.noBg,
.noBg {
  background: transparent;
}

.textClasify .ui.label .delete {
  display: unset !important;
}

.register_action {
  width: 100%;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.view_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.full_view .FullScreen {
  background-color: #fff;
}

.full_view .FullScreen .pr_img_annotate {
  height: 100vh;
}

.full_view .FullScreen .pr_img_annotate .label_sec .label_holder .l_entity .option_scroll {
  max-height: 50vh;
}

.full_view .FullScreen .pr_img_annotate .work_sec .w_img-sec {
  height: 84vh;
}

.full_view .FullScreen .pr_img_annotate .work_sec .w_img-sec .w_holder {
  max-height: 98%;
}

.ui.small.celled.striped.very.compact.table .ant-table-tbody td,
.ui.small.celled.striped.very.compact.table .ant-table-thead th {
  word-break: break-word;
  white-space: nowrap;
}

.content_section.ant-table-wrapper.table_workspace .ui.basic.vertical.segment {
  overflow-x: auto;
}

.ui.small.celled.striped.very.compact.table .ant-table-thead th>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shortcutInfo+.words.addBgGrey {
  margin-top: 5px;
}

.align_autoLabel {
  margin-top: 25px;
  margin-right: 20px;
}

.history_title .deleteWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.history_title .deleteWrapper .historySection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.history_title .deleteWrapper .historySection .site_date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.history_title .deleteWrapper .historySection .site_date .react-datepicker-input {
  border-radius: 20px !important;
  margin-right: 10px !important;
  background: transparent;
}

.history_title .deleteWrapper .search_h {
  margin-top: 22px;
}

.history_title .form_search .searchTerm {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  height: 36px !important;
  padding-left: 10px !important;
}

.history_title .searchButton {
  height: 36px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.history_title .react-datepicker-component .react-datepicker-input.is-open input {
  border-radius: 20px !important;
}

.menu_strip_con .rotateImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.commentSecBtn button {
  border: 0px solid #f3f3f3;
  background: transparent;
}

.commentSecBtn button:hover {
  border: 0px solid #f3f3f3 !important;
}

.custom_btn_complete {
  background: #dadada !important;
  padding: 5px 15px;
  border-radius: 15px;
  color: #6b6c6f !important;
  font-size: .8em;
  border: none;
  min-width: 100px;
}

.polygon_lable_name .ui.mini.label,
.polygon_lable_name .ui.mini.labels .label {
  font-size: .64285714rem;
  max-width: 90px;
  min-width: 60px;
  margin: 0 !important;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.polygon_lable_name~.label_right {
  min-width: unset !important;
}

.mycl {
  position: absolute;
  margin: 50%;
  padding-right: 50%;
}

.clearsearch {
  background-color: red;
  border: none;
  color: white;
  position: absolute;
  border-radius: 15px;
  padding: 12px 16px;
  font-size: 14px;
  cursor: pointer;
}

input {
  color: black !important;
}

/*# sourceMappingURL=custom.css.map */


/* New UIs Data */

.table_view {
  margin-top: 30px;
}

.table_view th {
  background: transparent;
}

.spaceUnder {
  background: #fff;
}

.spaceUnder td {
  background: #fff;
}


.table_workspace .ant-table-thead tr th {
  background: #12c4fc !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.table_workspace .ant-table-tbody tr td {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.65);
}

.table_workspace .ant-table-pagination {
  float: none !important;
  text-align: center;
}


.table_v .table_link {
  display: flex;
  align-items: center;
}

.table_v .table_link .s_word {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
}

.table_v .table_link .w_word {
  background: #12c4fc;
}

.table_v .table_link .p_word {
  background: #a7a7a7;
}

.table_workspace .number_format {
  text-align: center;
  width: 10%;
}

.table_workspace .w_name {
  text-align: left;
  width: 55%;
  cursor: pointer;
  /* background-color: lightgreen; */
}

.table_workspace .ant-checkbox-disabled {
  display: none;
}

.table_workspace .ant-table-column-sorter .anticon-caret-down,
.table_workspace .ant-table-column-sorter .anticon-caret-up {
  color: #fff;
}

.table_workspace .ant-table-thead tr th>span {
  display: flex;
  align-items: center;
}

.table_workspace .ant-table-thead tr .ant-table-selection-column>span {
  justify-content: center;
}

.content_section.ant-table-wrapper.table_workspace .ui.basic.vertical.segment {
  overflow-x: auto;
}




/* .colorPickerCustomSeg{
  position: relative;
} */


.colorPickerCustomSeg{
    position: relative;
    /* z-index: 9; */
    /* left: -2px;
    TOP: 0; */
    content: "";
    /* background-image: url(../images/color-palette.svg); */
    /* position: absolute; */
    width: 24px;
    height: 24px;
    cursor: pointer;
    /* padding: 0 !important; */
    /* top: 0;
    left: 0; */
    /* background-position: center;
    background-size: contain; */
}


.searchBox>input[type="text"] {
  width: 100%;
  border: 1px #dfe2e5 solid !important;
  height: 30px;
  border-radius: 30px;
  padding: 0 35px 0 8px;
  color: #979797;
  font-size: 0.7em;
  min-width: 200px;
  background: transparent !important;
}

.searchBox>input[type="empty-text"] {
  width: 100%;
  border: 1px #fc0307 solid !important;
  height: 30px;
  border-radius: 30px;
  padding: 0 35px 0 8px;
  color: #979797;
  font-size: 0.7em;
  min-width: 200px;
  background: transparent !important;
}

/* Custom Upload */

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  .btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
  }
}
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.upload_filebtn .ant-btn{
  min-height:40px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.upload_filebtn1 .ant-btn{
  min-height: 40px;
  margin-bottom: 10px;
  border-radius: 20px;
  position: absolute;
  top: 180px;
  right: 291px;
}

.upload_filebtndefault .ant-btn {
  min-height:40px;
  margin-bottom: 100px;
  border-radius: 20px;
  margin-right: 200px;
}


.h3tag {
  margin-top: 0px;
  margin-bottom: 20px;
}

.back_button{
  cursor: pointer;
}


.wrap {
  width: 500px;
  margin: auto;
}

.timeline {
  display : flex;
  padding-left: 50px;
  position: relative;
  &:before, &:after {
    display : flex;
    content: '';
    border-left: 1px solid red;
    position: absolute;
    left: -1px;
    top: 5px;
    bottom: 5px;
  }
}

.timeline p {
  padding: 0;
  position: relative;
  &:before, &:after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: red;
    border: 1px solid red;
    top: 5px;
    left: -56px;
    border-radius: 100%;
    transition: all .2s ease-in-out;
  }
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  background-color: DodgerBlue;
}

.flex-container > p {
  background-color: #f1f1f1;
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.top-nav {
  width: 70px;
  height: 13px;
  border-bottom: 3px solid #d1bcbc;
  position: relative;
  top: 50px;
  left: 20px;
  font-weight: 700;
  font-size: 17px;
  margin-left: 5px;
}

.container {
  display: flex;
  position: relative;
  top: 50px;
  cursor: move;
  height: 1px;
  width: 200px;
}

.styled nav ul {
  list-style: none;
  padding-left: 0;
}

.styled .left-nav ul li {
  text-decoration: none;
  padding: 13px;
  border-bottom: 2px solid #d1bcbc;
  font-size: 15px;
  width: 90px;
}

.styled .right-nav ul li {
  margin: 15px 3px;
  text-decoration: none;
  padding: 13px;
  position: relative;
  top: -20px;
  width: 90px;
}

.dropdown-container {
  position: relative;
  height: 50px;
  overflow-y: auto;
}

.horizontal-slider {
  height: 20px;
  position: fixed;
  height: 30px;
  width: 600px;
  bottom: 5px;
}

.example-thumb {
  position: fixed;
  transform: translateX(-50%);
}

.example-track {
  border: 2px solid;
  cursor: pointer;
  height: 30px;
  position: fixed;
  background-color: #cdafaf;
}

.fixed-dropdown {
  position: fixed;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* Cards  */


/* .ui.one.cards {
  margin-left: 0em;
  margin-right: 0em;
}

.ui.one.cards > .card {
  width: 100%;
}

.ui.two.cards {
  margin-left: -1em;
  margin-right: -1em;
}

.ui.two.cards > .card {
  width: calc( 50%  -  2em );
  margin-left: 1em;
  margin-right: 1em;
}

.ui.three.cards {
  margin-left: -1em;
  margin-right: -1em;
}

.ui.three.cards > .card {
  width: calc( 33.33333333%  -  2em );
  margin-left: 1em;
  margin-right: 1em;
}

.ui.four.cards {
  margin-left: -0.75em;
  margin-right: -0.75em;
}

.ui.four.cards > .card {
  width: calc( 25%  -  1.5em );
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.ui.five.cards {
  margin-left: -0.75em;
  margin-right: -0.75em;
}

.ui.five.cards > .card {
  width: calc( 20%  -  1.5em );
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.ui.six.cards {
  margin-left: -0.75em;
  margin-right: -0.75em;
}

.ui.six.cards > .card {
  width: calc( 16.66666667%  -  1.5em );
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.ui.seven.cards {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.ui.seven.cards > .card {
  width: calc( 14.28571429%  -  1em );
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.ui.eight.cards {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.ui.eight.cards > .card {
  width: calc( 12.5%  -  1em );
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-size: 11px;
}

.ui.nine.cards {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.ui.nine.cards > .card {
  width: calc( 11.11111111%  -  1em );
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-size: 10px;
}

.ui.ten.cards {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.ui.ten.cards > .card {
  width: calc( 10%  -  1em );
  margin-left: 0.5em;
  margin-right: 0.5em;
} */