
.leftBg {
  color: #3b81ff;
  margin-top: 150px;
  margin-left: 30px;
}

.rightBg {
  margin-left: 150px;
  text-align: center;
  margin-top: 150px;
  width: 30%;
  height: 300px;
}

.brand {
  color: #3b81ff;
}

.loginPage {
  margin-bottom: 100px;
  padding: 6%
}

.card {
  margin-top: 50px;
  word-wrap: break-word;
}

.page {
  height: 500px;
}

.title {
  height: 4em;
}

.firstCol {
  width: 10em;
}

.ui.table {
  width: 70em;
}

