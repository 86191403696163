@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: none;
}
body {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 1em !important;
}
/* The container */
.label_container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 1em;
  padding-top: 0.3em;
  font-weight: 600;
  color: #626769;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.label_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  /* // background-color: #eee; */
  border: 2px solid #ccc;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.label_container:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.label_container input:checked ~ .checkmark {
  background-color: #2196f3;
  border: 2px solid #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.label_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label_container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.login_section {
  display: flex !important;
}
.sec_left {
  width: 50% !important;
  padding: 3em !important;
}
.sec_right {
  background-size: cover !important;
  width: 50% !important;
  height: 100vh !important;
}
.screen_overlay {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #45b0e69e;
  padding: 0px 3em;
}
.screen_overlay h1 {
  font-size: 3.5em;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0.2em;
  text-align: center;
}
.screen_overlay h5 {
  font-size: 1.8em;
  font-weight: 400;
  color: #fff;
  text-align: center;
}
.logo_main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.login_box {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  justify-content: center !important;
}

/* .login_base {
  width: 55% !important;
} */

.login_base h6 {
  font-size: 1.2em !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  color: #6b6c6f !important;
}
.login_base h4 {
  font-size: 2em;
  font-weight: 600;
  margin: 1em 0 1em 0;
}
.login_base .form_field label {
  font-weight: 600;
  color: #626769;
}
.login_base .input_box {
  display: flex;
  border: 2px solid #dfe2e6;
  padding: 5px;
  border-radius: 30px;
  min-height: 35px;
  margin-top: 1em;
  /* background-color: #2196f3; */
}
.login_base .input_box input {
  width: 100%;
  border: none;
  font-size: 5em;
  border-radius: 30px;
  color: #a9a7a7;
  /* background-color: yellow; */
}
.login_base .input_box .icn_img {
  width: 30px;
  margin: 0px 8px;
}
.login_base .input_box .img_eye {
  margin-right: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_base .form_field {
  margin-bottom: 1.6em;
}
.action_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6em;
}
.action_btn .btn_borderd {
  background: transparent;
  border: 2px solid #45b0e6 !important;
  color: #45b0e6;
}
.action_btn .btn_base {
  min-width: 160px;
  min-height: 47px;
  text-align: center;
  border-radius: 30px;
  font-size: 1em;
  font-weight: 600;
  border: 2px solid transparent;
  cursor: pointer;
}
.action_btn .btn_primary {
  color: #fff;
  background: #45b0e6;
}
.hint_txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hint_txt .forget_txt {
  font-size: 1em;
  font-weight: 600;
  text-decoration: none;
  color: #c4c4c4;
}
.login_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.login_footer h6 {
  font-size: 0.8em;
  color: #6b6c6f;
}
.txt-upper {
  text-transform:uppercase;
}

/* sass */


.leftBg {
  color: #3b81ff;
  margin-top: 150px;
  margin-left: 30px;
}

.rightBg {
  margin-left: 150px;
  text-align: center;
  margin-top: 150px;
  width: 30%;
  height: 300px;
}

.brand {
  color: #3b81ff;
}

.loginPage {
  margin-bottom: 10px;
}

.errormsg
{
  color:red;
  padding-top: 5px;
  padding-left: 5px;
  font-size: 13px;
}