.react-datepicker-component .react-datepicker-input input::-webkit-input-placeholder {
    color: #9098a7;
    /* font-weight: 600; */
    font-size: .9em;
    font-weight: 400;
    font-family: "Helvetica Neue For Number", 'Helvetica Neue', sans-serif;
  }
  
  .react-datepicker-component .react-datepicker-input input:-moz-placeholder {
    color: #9098a7;
    font-size: .9em;
    font-weight: 400;
    font-family: "Helvetica Neue For Number", 'Helvetica Neue', sans-serif;
    /* font-weight: 600; */
  }
  
  .react-datepicker-component .react-datepicker-input input::-moz-placeholder {
    color: #9098a7;
    font-size: .9em;
    font-weight: 400;
    font-family: "Helvetica Neue For Number", 'Helvetica Neue', sans-serif;
    /* font-weight: 600; */
  }
  
  .react-datepicker-component .react-datepicker-input input:-ms-input-placeholder {
    color: #9098a7;
    font-size: .9em;
    font-weight: 400;
    font-family: "Helvetica Neue For Number", 'Helvetica Neue', sans-serif;
    /* font-weight: 600; */
  }
  .react-datepicker-component .react-datepicker-input input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 60px;
    font-size: .9em;
    color: #595959 !important;
  }