.error {
	color: #ff0000;
}

.editable-cell {
	position: relative;
  }
  
  .editable-cell-value-wrap {
	padding: 5px 12px;
	cursor: pointer;
  }
  
  .editable-row .editable-cell-value-wrap {
	border: 2px solid #d9d9d9;
	border-radius: 4px;
	padding: 4px 11px;
  }

  .csuceess_holder .successSubmit {
	background: #159588;
	color: #fff;
	height: 40px;
	border-radius: 24px;
	font-weight: normal;
  }