.wordPre {
    white-space: pre;
}

.baseMargin {
    margin: 5% 25% 5%;
    text-align: center;
}

.margint {
    margin-top: 2%;
}

.targetStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.targetStyle1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.lHeight {
    height: 20px;
}

.formStyle {
    border: 1px solid #ccc;
    padding: 1em;
    background-color: white;
}

.flexB {
    display: flex;
    justify-content: space-between;
}

.flexC {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fSize {
    font-size: 18px;
    font-weight: 500;
}

.fWrap {
    font-size: 18px;
    font-weight: 500;
}

.fCenter {
    display: flex;
    align-items: center;
}

.tCapse {
    text-transform: capitalize;
}

.hitConv {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 5px 0px;
}

.tagedE {
    display: inline;
    font-size: 1rem;
}

.lineHeight {
    line-height: 1.0rem;
}

.mDiv {
    padding: 20px;
    min-height: max-content;
}

.dLabel {
    border: none;
    padding: 5px;
    margin-bottom: 15px;
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.sLabel {
    border: none;
    padding: 5px;
    margin-bottom: 5px;
    width: 100%;
    font-size: 12px;
    color: red;
}

.arsStyle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 10em;
    overflow: auto;
}

.icnStyle {
    float: none;
    color: #fff;
    text-shadow: none;
}

.clasifyStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    flex-wrap: wrap;
}

.flexEven {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.divBase {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0.5rem;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sPadding {
    padding: 0.5em 0;
}

.customLab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.labelTagg {
    font-weight: bold;
    color: #12c4fc;
}

.pSpace {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: white;
    width: 100%;
}

.rSpacebetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fStart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.dFlex {
    display: flex;
}

.posTag {
    width: 50%;
    border: 1px solid black;
    padding: 0.5rem;
    font-size: 14px;
    opacity: 0.7;
    line-height: 1.2em;
}

.helpStyle {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #12c4fc;
    border-radius: 20px;
    margin-right: 5px;
}

.helpStylePos {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #12c4fc;
    border-radius: 20px;
    margin-right: 5px;
    margin-left: 540px;
}

.mRight {
    margin-right: 5px !important;
}

.svg {
    fill-color: #5b5b5b;
}

.modalHeight {
    height: max-content;
}

.icnWidth {
    width: 30px;
}
