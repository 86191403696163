.commentCard{
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.details{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cmt_mentions{
    border-radius: 18px;
}

.highlight_text{
    color:  #29abe2;
}