.title {
	height: 4em;
}

.firstCol {
	width: 10em;
}

.ui.table {
	width: 50em;
}
.tagLine {
	word-spacing: 15px;
}

.content
{
  height:800px;
  overflow:auto;
  background:#F4F2F2;
}	

.controlBar {
  display: block;
  visibility: visible;    
}

.tag_Label {
	padding: 1em;
}

.clickableLabel {
	cursor: pointer;
}

.dataArea {
    min-height: 3em;
    background: lightgray;
    color: black;
    font-size: 1.5rem;
    padding: 5%;
    margin-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    word-spacing: 1.2rem;
    font-weight: 500;
}


.posResultArea {
    min-height: 3em;
    background: lightslategrey;
    color: white;
    margin-top: 2%;
    font-size: 0.2rem;
    font-weight: 300;
    word-spacing: 0.2rem;
    padding: 5%;	
    margin-left: 10%;
    margin-right: 10%;	
}
.resultArea {
    min-height: 3em;
    background: lightslategrey;
    color: white;
    margin-top: 2%;
    font-size: 1.5rem;
    font-weight: 300;
    word-spacing: 1.2rem;
    padding: 5%;	
    margin-left: 10%;
    margin-right: 10%;
}

.s_det_box {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.s_det_box .label_set {
    display: flex;
    align-items: center;
}
.s_det_box .label_set .completed {
    background: #12c4fc;
    padding: 5px 10px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 29px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    min-width: 170px;
    justify-content: center;
    min-height: 40px;
}
.s_det_box .label_set .reopen {
    background: #d95c5c;
    padding: 5px 10px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 29px;
    display: flex;
    align-items: center;
    min-width: 170px;
    justify-content: center;
    min-height: 40px;
}
.s_det_box .label_set .icn_state {
    font-size: 20px;
}

.loader-left {
    left: 0px;
}

.div-container {
    display: flex;
    flex-direction: column;
    padding-left: 3%;
    padding-right: 3%;
}

.flex-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.prgres-size {
    font-size: 0.75rem;
    height: 1em;
}