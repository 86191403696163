.tagLine {
    word-spacing: 15px;
}

.fullScreenClass {
    overflow: scroll;
}

.textStyle {
    width: 100%;
    word-spacing: 1.1rem;
    word-wrap: break-word;
    white-space: pre-wrap;
    padding: 2rem;
    text-align: left;
    font-size: 2rem;
    color: black;
    font-weight: 600;
}

.tagArea {
    background: #E0E0E0;
    padding: 1em 1em 1em 1em;
    line-height: 4em;
    position: relative;
    margin-top: 10px;
}

.classifyArea {
    background: #E0E0E0;
    padding: 1.0em;
    position: relative;
}

.copyButton {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.tag_Label {
    padding: 1em;
}

.clickableLabel {
    cursor: pointer;
}

.customTool .item {
    min-width: auto !important;
    cursor: pointer;
    color: #a0a0a0 !important;
    padding: 5px !important;
}

.customTool .item i {
    line-height: 1em;
}

.customTool .item i.icon:before {
    font-size: .7em !important;
}

.customTool .item:hover,
.customTool .close:hover {
    background: #00b5ad;
    color: #fff !important;
}

.customTool .item:hover,
.customTool .close:hover,
.customTool .item.active {
    background: #00b5ad;
    color: #fff;
    text-shadow: none !important;
}