.tagLine {
	word-spacing: 15px;
}

.fullScreenClass {
    overflow: scroll;
}
.textStyle {
	width: 100%;
    word-spacing: 1.1rem;
    word-wrap: break-word;
    white-space: pre-wrap;
    padding: 2rem;
    text-align: left;
    font-size: 2rem;
    color: black;
    font-weight: 600;
}
.tagArea {
	background: #E0E0E0;
	padding: 1em 1em 1em 1em;
	line-height: 4em;
	position: relative;
    margin-top: '10px';
}
.classifyArea {
    background: #E0E0E0;
    padding: 1.0em;
    position: relative;
}

.copyButton {
    position: absolute;
    bottom: 0px;
    right: 0px;
 }

.tag_Label {
	padding: 1em;
}

.clickableLabel {
	cursor: pointer;
}
/*-----------tile view - css ----------------*/


.m_list .ant-select-dropdown-menu-item {
    display: inline-block;
}

.m_list .ant-select-dropdown-menu-item .ant-badge-count {
    border-radius: 10px !important;
    background: #b1a8a8 !important;
    margin-left: 5px;
}

.m_list .ant-select-dropdown-menu-item .option_info {
    margin-left: 5px;
}

#custom_labelselect .ant-select-selection {
    border-radius: 20px;
}

#custom_labelselect .clr_btn {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    height: 28px;
    margin-left: 5px;
    border-radius: 50%;
    color: #a7a2a2;
}

#custom_labelselect .ant-badge {
    width: 20px;
    height: 20px;
    font-size: 12px;
}

.ant-scroll-number-only>p {
    height: 20px;
    font-size: 12px !important;
    line-height: unset !important;
}
.option_label{    
    margin-left: 5px;
    font-size: 14px;
}
.m_list.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:after, .m_list.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover:after {
    font-size: 10px !important;
    width: 20px;
    height: 20px;
    background: #12c4fc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    color: #ffffff;
}
.m_list.ant-select-selection--multiple .ant-select-selection__rendered>ul>li{
    height: 25px;
    display: flex;
    align-items: center;
    border-radius: 15px;
}
.select_width .ant-select-selection__choice .ant-badge-count{
    background: #b1a8a8;
    margin-left: 3px;
}
.select_width .ant-select-selection__choice .option_info{margin-left:5px;}


.ant-select-selection__choice .select_holder{
    background: transparent;
}

.m_list .ant-select-dropdown-menu{
    max-height:150px !important;
    display:flex ;
    flex-wrap: wrap;
}
.m_list{
    box-shadow:none !important;
    border:1px solid #e6e6e6 !important;
    z-index:9;
}
.m_list .ant-select-dropdown-menu-item{
    background: #e0dbdb;
    border-radius: 20px;
    margin-top: 3px;
    padding: 3px 8px;
}

.legendTag {
    width: 20px;
    height: 20px;
    background: #fff;
    display: inline-block;
    margin-right: 5px;
    border-radius: 50%;
    position: relative;
    text-align: center;
    font-weight:bold; 
}

.legendTag:after {line-height: 20px;font-size: .9em;}

.legendTag.M:after {
    content: "M";
    color: #12c4fc;
}
.legendTag.H:after {
    content: "H";
    color: #9E9E9E;
}

.bottomBarLayout {
    display: flex;
    align-items: center;
    padding: 0 5px;
    .legendInfo{width: 10%;}
    .addNoteBox {
        border-left: 0px solid #bdbdbd;
        width: 90%;
        .ui.input {width: 100% !important;}
    }
}

.legendInfo>li {
    padding: 2px 0;
    font-size: .85em;
}

.legendInfo .legendTag:after {
    vertical-align: text-bottom;
}

.legendInfo {
    padding: 5px 10px 5px 0;
    border-right: 1px #bdbdbd solid;
}

.addNoteBox {
    border-left: 1px #bdbdbd solid;
    padding: 5px 0 5px 10px;
}

.fullscreenInOverview {
    position: absolute;
    top: 15%;
    right: 17%;
}

.FullScreen{
    height: auto !important;
    width: auto ;
    background-color: #FFFFFF;
}

.videoclassification_fullscreen .FullScreen{
    height: auto !important;
    width: 100% !important;
}

.imageClassification_fullscreen .FullScreen{
    height: auto !important;
    width: 100% !important;
}

.f_labels{
    display: flex;
    align-items: center;
    margin: 10px;
}
.f_labels .ui{
    display: flex;
    justify-content: space-between;
}
.pr_doc_annotate .project_view .box_scrol {
    height: 720px;
    overflow-y: inherit !important;
}
.fullscreen_tagging{
    position:absolute !important;
    bottom:5vh !important;
    right:0 !important;
  }

.right_box_entity{
    position: absolute!important;
    bottom: 25vh!important;
    right: 0!important;
    background: #fff;
    box-shadow: 0 2px 20px rgb(0 0 0 / 7%);
    border-radius: 7px;
    padding: 6px;
    margin-left: 20px;
    width: 20%;
  }